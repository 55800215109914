import { Button, FormImage, FormLabel, Input, Loader } from '@getro/rombo';
import { Field, Form, Formik } from 'formik';
import { ExternalLink } from 'lucide-react';
import React from 'react';
import { Box, Text, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { openChat } from 'helpers/supportChat';
import { PublishChanges } from 'components/atoms/publishChanges';
import toast from 'react-hot-toast';

export const JobBoardSettings = ({ domain, update, isLoading, isUpdating, faviconUrl }) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Formik
        initialValues={{ favicon: faviconUrl ?? '' }}
        onSubmit={(values) => {
          update({
            faviconUrl: values.favicon,
          }).then(() => {
            toast(<PublishChanges domain={domain} />, {
              icon: 'success',
              id: 'job-board-settings-updated',
            });
          });
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
              <Flex sx={{ gap: '8px', flexDirection: 'column' }}>
                <Box>
                  <FormLabel>Job board URL</FormLabel>
                  <Text fontSize="1" lineHeight="20px">
                    To change the job board URL, please{' '}
                    <Box
                      onClick={() => openChat()}
                      as="span"
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      contact us.
                    </Box>
                  </Text>
                </Box>
                <Flex sx={{ alignItems: 'center', gap: '8px' }}>
                  <Input disabled value={domain} />
                  <Box height="16px" as="a" href={`https://${domain}`} target="_blank">
                    <Box as={ExternalLink} size="16px" />
                  </Box>
                </Flex>
              </Flex>
              <Field
                width="32px"
                height="32px"
                label="Favicon"
                filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
                name="favicon"
                component={FormImage}
                field={{ name: 'favicon', value: values.favicon }}
                helpText="The icon used in your visitors’ browser tabs. Upload a 32x32px .ico, .png, or .gif file."
                form={{
                  setFieldValue,
                  name: 'favicon',
                }}
              />
              <Flex sx={{ gap: '16px', mt: '16px', flexDirection: 'column' }}>
                <Box>
                  <Button loading={isUpdating} type="submit" size="small">
                    Save
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};

JobBoardSettings.propTypes = {
  faviconUrl: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
};
