import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { trackEvent } from 'helpers/analytics';
import { activeNetworkSelector } from 'redux/selectors';

export const useCompanyTableFunctions = ({ onSort }) => {
  const activeNetwork = useSelector(activeNetworkSelector);

  const handleSort = useCallback(
    (args) => {
      onSort(args);
      const [{ desc, column }] = args;

      trackEvent('table:sort', {
        networkId: activeNetwork.id,
        page: 'companies',
        type: desc ? 'desc' : 'asc',
        columnName: column.title,
      });
    },
    [onSort, activeNetwork],
  );

  const onColumnAction = useCallback(
    (operation, { payload: column }) => {
      trackEvent('table:change_columns', {
        networkId: activeNetwork.id,
        page: 'companies',
        operation,
        columnName: column.title,
      });
    },
    [activeNetwork],
  );

  return {
    handleSort,
    onColumnAction,
  };
};
