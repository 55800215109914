import { Briefcase, Layout, Server, TrendingUp, Users } from 'lucide-react';
import ListDetail from 'pages/listDetail';
import { Contacts } from '../pages/contacts';
import { ContactsAddSingleContact } from '../pages/contactsAddSingleContact';
import { ContactsEditSingleContact } from '../pages/contactsEditSingleContact';
import { ContactsAddSingleContactError } from '../pages/contactsAddSingleContactError';
import { ContactsImport } from '../pages/contactsImport';
import { ContactsImportSuccess } from '../pages/contactsImportSuccess';
import { ContactsImportCsv } from '../pages/contactsImportCsv';
import { ContactsUpgrade } from '../pages/contactsUpgrade';
import { ContactsLinkedInSync } from '../pages/contactsLinkedInSync';
import { Hires } from '../pages/hires';
import Analytics from '../pages/Analytics';
import CompanyProfile from '../pages/CompanyProfile';
import Employers from '../pages/Employers';
import Inbox from '../pages/Inbox';
import Job from '../pages/Job';
import MembersInvite from '../pages/MembersInvite';
import NetworkHome from '../pages/NetworkHome';
import memberRoutes from './membersRoutes';
import bulkMembersInviteRoutes from './bulkMembersInviteRoutes';
import addCompanyRoutes from './addCompanyRoutes';
import ManageSubscriptions from '../pages/manageSubscriptions';
import Share from '../pages/share';
import Jobs from '../pages/Jobs';
import RenewJob from '../pages/RenewJob';
import PostJob from '../pages/PostJob';
import CompanyAdmins from '../pages/CompanyAdmins';
import CompanyJobs from '../pages/CompanyJobs';
import Impact from '../pages/Impact';
import List from '../pages/list';
import { SettingsPage } from '../pages/Settings';
import Subscribing from '../pages/subscribing';
import JobBoardAppearance from '../pages/Settings/jobBoardAppearance';

export const networkRoutes = {
  addCompany: addCompanyRoutes,
  analytics: {
    path: '/analytics',
    component: Analytics,
    name: 'Analytics',
    icon: TrendingUp,
  },
  bulkMembersInvite: bulkMembersInviteRoutes,
  companyProfile: {
    path: '/companies/:companySlug',
    component: CompanyProfile,
    name: 'Company profile',
    icon: Layout,
    exact: true,
  },
  companyAdmins: {
    path: '/companies/:companySlug/admins',
    component: CompanyAdmins,
    name: 'Company admins',
    icon: Layout,
    exact: true,
  },
  companyJobs: {
    path: '/companies/:companySlug/jobs',
    component: CompanyJobs,
    name: 'Company jobs',
    icon: Layout,
    exact: true,
  },
  list: {
    path: '/lists',
    component: List,
    name: 'lists',
  },
  listDetail: {
    path: '/list/:listId',
    component: ListDetail,
    name: 'listsDetail',
  },
  contacts: {
    path: '/contacts',
    component: Contacts,
    name: 'contacts',
  },
  contactsAddSingleContact: {
    path: '/contact/add',
    component: ContactsAddSingleContact,
    name: 'Add single contact',
    exact: true,
  },
  contactsAddSingleContactError: {
    path: '/contact/add/error',
    component: ContactsAddSingleContactError,
    name: 'Add single contact',
    exact: true,
  },
  contactsEditSingleContact: {
    path: '/contact/:contactId/edit',
    component: ContactsEditSingleContact,
    name: 'Edit contact',
    exact: true,
  },
  contactsImport: {
    path: '/import-contacts',
    component: ContactsImport,
    name: 'Import contacts',
    exact: true,
  },
  contactsImportSuccess: {
    path: '/import-contacts/success',
    component: ContactsImportSuccess,
    name: 'Import contacts success',
    exact: true,
  },
  contactsImportCsv: {
    path: '/import-contacts/csv',
    component: ContactsImportCsv,
    name: 'Import contacts',
    exact: true,
  },
  contactsUpgrade: {
    path: '/upgrade',
    component: ContactsUpgrade,
    name: 'Upgrade contacts',
    exact: true,
  },
  contactsLinkedInSync: {
    path: '/linkedin/:action',
    component: ContactsLinkedInSync,
    name: 'LinkedIn sync',
    exact: true,
  },
  hires: {
    path: '/hires',
    component: Hires,
    name: 'Hires',
  },
  editJob: {
    path: '/companies/:companyId/:action/:jobId',
    component: Job,
    name: 'Edit job',
    exact: true,
  },
  employers: {
    path: '/companies',
    component: Employers,
    name: 'Companies',
    icon: Briefcase,
    exact: true,
  },
  jobs: {
    path: '/jobs',
    component: Jobs,
    name: 'Jobs',
    icon: Server,
    exact: true,
  },
  networkPostJob: {
    path: '/jobs/post-job',
    component: PostJob,
    name: 'Post a job',
    exact: true,
  },
  renewJob: {
    path: '/jobs/:jobId/renew',
    component: RenewJob,
    name: 'Renew job',
    exact: true,
  },
  networkEditJob: {
    path: '/jobs/:action/:jobId',
    component: PostJob,
    name: 'Edit job',
    exact: true,
  },
  inbox: {
    path: '/inbox',
    component: Inbox,
    name: 'Inbox',
    exact: true,
  },
  postJob: {
    path: '/companies/:companyId/post-job',
    component: Job,
    name: 'Post job',
    exact: true,
  },
  members: memberRoutes,
  membersInvite: {
    path: '/members/invite',
    component: MembersInvite,
    name: 'Invite members',
    icon: Users,
  },
  membersShare: {
    path: '/members/share/email',
    component: Share,
    name: 'Share you talent network',
  },
  networkHome: {
    path: '/',
    component: NetworkHome,
    name: 'NetworkHome',
    exact: true,
  },
  settings: {
    path: '/settings/',
    component: SettingsPage,
    name: 'Settings',
  },
  jobBoardLivePreview: {
    path: '/job-board/live-preview',
    component: JobBoardAppearance,
    name: 'Job board live preview',
    exact: true,
  },
  membersSubscribing: {
    path: '/members/alerts/subscribing',
    component: Subscribing,
    name: 'Subscribing to your talent network',
  },
  manageSubscriptions: {
    path: '/members/alerts',
    component: ManageSubscriptions,
    name: 'Manage lists',
  },
  home: {
    path: '/home',
    component: Impact,
    name: 'Home',
  },
};
