import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Link, Text } from 'rebass/styled-components';

import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import usePageHook from '../../../hooks/usePageHook';
import { activeNetworkSelector } from '../../../redux/selectors';
import { useGetGeneralSettingsQuery, useUpdateGeneralSettingsMutation } from '../../../services/network';
import PrivacyPolicy from '../components/PrivacyPolicy';

const pageTitle = 'Privacy policy on job board';

export const JobBoardPrivacyPolicy = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { data: generalSettings, error: generalSettingsError } = useGetGeneralSettingsQuery();
  const [updateGeneralSettings, { isLoading: isSavingGdprSettings, isError: gdprSettingsSaveError }] =
    useUpdateGeneralSettingsMutation();

  usePageHook(pageTitle);

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader
        title="Privacy policy on job board"
        description={
          <Text>
            GDPR requires you to have a data controller. Your GDPR data controller information will be included in the
            privacy policy of your network. Learn more.{' '}
            <Link
              href="https://help.getro.com/support/solutions/articles/65000174032-gdpr-and-getro"
              target="_blank"
              rel="noopener noreferrer"
              color="text.main"
            >
              Learn more
            </Link>
            .
          </Text>
        }
      />
      <Box pt="32px">
        <PrivacyPolicy
          activeNetwork={activeNetwork}
          generalSettings={generalSettings}
          fetchError={generalSettingsError}
          saveError={gdprSettingsSaveError}
          submitting={isSavingGdprSettings}
          onSubmit={updateGeneralSettings}
        />
      </Box>
    </Box>
  );
};
