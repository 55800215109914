import { Checkbox } from '@getro/rombo';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Card, Flex, Text } from 'rebass/styled-components';
import { activeNetworkSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { trackEvent } from 'helpers/analytics';
import { sortPositionsByCompany } from '../../../services/contacts/postitions';
import { dateToMonthAndYear } from '../../../services/dateFormatter';
import { ContactAvatar } from '../../molecules/contactAvatar';
import { ContactActionPanel } from '../contactActionPanel';
import { ScoreDetails } from './scoreDetails';
import { NoAtMatch } from './noAtMatch';
import InPortco from './inPortco';
import { ContactDeleted } from './contactDeleted';
import AddContactsToList from './addContactToList';
import { EnrichmentButton } from '../contactDetail/enrichmentButton';

export const ContactCard = ({
  queryParams,
  contact,
  isSelected,
  type,
  notSelectable,
  onChangeSelected,
  match,
  matchListInfo,
  showAddToLisButton,
  addedToSharedList,
  list,
  deleteContactFromList,
  page,
  onLinkedinClick,
  setShowAddNote,
  showDetail,
  ...rest
}) => {
  const positions = sortPositionsByCompany(contact.contactWorkExperiences);
  const pastPositions = [].concat(...positions);
  const activeNetwork = useSelector(activeNetworkSelector);

  const onChange = (event) => {
    if (onChangeSelected) {
      onChangeSelected(event);
    }
  };

  const noExperienceOrNotEnriched = contact.enrichmentStatus !== 'enriched' || pastPositions?.length === 0;
  const isNotEnriched =
    (noExperienceOrNotEnriched && type !== 'public') || (pastPositions?.length === 0 && type === 'public');

  return (
    <Card
      sx={{
        boxShadow: 'none',
        borderBottom: 1,
        borderBottomColor: 'neutral.100',
        '&&:hover': {
          '.contact-avatar-socials, #contact-more-dropdown-action': {
            visibility: 'visible',
            opacity: 1,
          },
        },
        padding: '16px 0 32px',
        mt: '16px',
      }}
      {...rest}
    >
      {contact.deleted && <ContactDeleted contact={contact} />}
      {match.deleted && (
        <NoAtMatch
          match={match}
          matchListId={matchListInfo.id}
          jobId={matchListInfo.job?.id}
          organizationId={matchListInfo.organization?.id}
        />
      )}
      {!match.deleted && !contact.deleted && (
        <Flex sx={{ position: 'relative' }} flexDirection={['column', 'row']}>
          {!notSelectable && (
            <Box
              sx={{ position: 'absolute', left: '-8px', top: '-8px', zIndex: 1 }}
              onClick={(event) => event.stopPropagation()}
            >
              <Checkbox name="cardSelect" checked={isSelected} onChange={onChange} />
            </Box>
          )}
          <Box
            data-testid={`contact-card-${contact.id}`}
            flexGrow="1"
            sx={{ cursor: 'pointer', position: 'relative' }}
            onClick={() => showDetail()}
          >
            <ContactAvatar
              type={type}
              showMaximize
              contact={contact}
              trackLinkedinClick={() =>
                trackEvent('contacts:view_linkedin_profile', {
                  collectionId: activeNetwork?.id,
                  contactId: contact.id,
                  page,
                })
              }
              onLinkedinClick={onLinkedinClick}
            />
            <Box
              display={isNotEnriched ? 'block' : 'grid'}
              mt="8px"
              sx={{
                gridTemplateColumns: ['1fr', '88px 1fr'],
                columnGap: '8px',
                rowGap: '8px',
              }}
            >
              {((contact.enrichmentStatus === 'enriched' && pastPositions.length > 0) || type === 'public') && (
                <>
                  <Text fontSize="14px" color="text.subtle">
                    {pastPositions.length === 0 ? 'No experience' : 'Experience'}
                  </Text>
                  <Box>
                    {pastPositions.slice(0, 3).map((position) => (
                      <Box key={`${position.id}-${position.jobTitle}`}>
                        <Text
                          as="span"
                          fontSize="14px"
                          lineHeight="20px"
                          key={`past-experience-${position.jobTitle}-${position.companyLinkedinUrl}`}
                        >
                          {position.jobTitle}
                          {position.companyName && (
                            <Text as="span" fontSize="14px">
                              {` at ${position.companyName}`}
                            </Text>
                          )}
                          {position.dateFrom && (
                            <Text
                              as="span"
                              color="text.subtle"
                              fontSize="14px"
                              lineHeight="20px"
                              sx={{ whiteSpace: 'nowrap' }}
                            >
                              <Box
                                bg="text.subtle"
                                width="2px"
                                height="2px"
                                mx="1"
                                mb="3px"
                                sx={{ display: 'inline-block', borderRadius: 'circle' }}
                              />
                              {`${dateToMonthAndYear(position.dateFrom)} - ${
                                position.dateTo ? dateToMonthAndYear(position.dateTo) : 'present'
                              }`}
                            </Text>
                          )}
                        </Text>
                      </Box>
                    ))}
                    {pastPositions.length > 3 && (
                      <Text pt="2px" fontSize="0" color="text.subtle">{`And ${pastPositions.length - 3} more`}</Text>
                    )}
                  </Box>
                  {contact.signupInfo && (
                    <>
                      <Text fontSize="14px" color="text.subtle">
                        Preferences
                      </Text>
                      <Box>
                        {contact.signupInfo.jobFunctions?.length ||
                        contact.signupInfo.seniorities?.length ||
                        contact.signupInfo.topics?.length ? (
                          <Box>
                            <Text as="span" fontSize="14px" lineHeight="20px">
                              {contact.signupInfo.jobFunctions?.join(', ')}
                              {contact.signupInfo.jobFunctions?.length && contact.signupInfo.seniorities?.length
                                ? ` · `
                                : ''}
                              {contact.signupInfo.seniorities?.join(', ')}
                              {contact.signupInfo.seniorities?.length && contact.signupInfo.topics?.length ? ` · ` : ''}
                              {contact.signupInfo.topics?.length ? (
                                <Text as="span" sx={{ textTransform: 'capitalize' }}>
                                  {contact.signupInfo.topics
                                    .reduce((carry, item) => {
                                      carry.push(item.join(', '));

                                      return carry;
                                    }, [])
                                    .join(' · ')}
                                </Text>
                              ) : null}
                            </Text>
                          </Box>
                        ) : null}
                        {contact.signupInfo.workEnvironments?.length ||
                        contact.signupInfo.locationPreferences?.length ? (
                          <Box>
                            <Text as="span" fontSize="14px" lineHeight="20px">
                              {contact.signupInfo.workEnvironments?.join(', ')}
                              {contact.signupInfo.workEnvironments?.length &&
                                contact.signupInfo.locationPreferences?.length &&
                                ` · `}
                              {contact.signupInfo.locationPreferences?.join('; ')}
                            </Text>
                          </Box>
                        ) : null}
                        {contact.signupInfo.usVisa && (
                          <Box>
                            <Text as="span" fontSize="14px" lineHeight="20px">
                              {contact.signupInfo.usVisa}
                            </Text>
                          </Box>
                        )}
                        {contact.signupInfo.jobSearchStatus ? (
                          <Box>
                            <Text
                              fontSize="14px"
                              fontWeight={contact.signupInfo.jobSearchStatus === 'Not looking' ? 400 : 500}
                              color={contact.signupInfo.jobSearchStatus === 'Not looking' ? 'text.subtle' : 'success'}
                            >
                              {contact.signupInfo.jobSearchStatus}
                            </Text>
                          </Box>
                        ) : null}
                        {contact.signupInfo.signupDate && (
                          <Box>
                            <Text as="span" fontSize="12px" color="text.subtle">
                              {`Signed up on ${moment(contact.signupInfo.signupDate).format('MMM DD, YYYY')}`}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </>
                  )}
                  {contact?.addedToSharedListBy && (
                    <>
                      <Text fontSize="14px" color="text.subtle">
                        Added by
                      </Text>
                      <Box>
                        <Text fontSize="14px">
                          {contact.addedToSharedListBy.status === 'added_from_smart_list' && 'Auto-update'}
                          {contact.addedToSharedListBy.status === 'added' && contact.addedToSharedListBy.user?.name}
                          {contact.addedToSharedListBy.createdAt && (
                            <Text as="span" color="text.subtle">{` on ${moment(
                              contact.addedToSharedListBy.createdAt,
                            ).format('MMM D, YYYY')}`}</Text>
                          )}
                        </Text>
                      </Box>
                    </>
                  )}
                  {contact?.excludedFromSharedListBy && (
                    <>
                      <Text fontSize="14px" color="text.subtle">
                        Excluded by
                      </Text>
                      <Box>
                        <Text fontSize="14px">
                          {contact.excludedFromSharedListBy.user?.name}
                          {contact.excludedFromSharedListBy.createdAt && (
                            <Text as="span" color="text.subtle">{` on ${moment(
                              contact.excludedFromSharedListBy.createdAt,
                            ).format('MMM D, YYYY')}`}</Text>
                          )}
                        </Text>
                      </Box>
                    </>
                  )}
                </>
              )}
              {(contact.enrichmentStatus !== 'enriched' || pastPositions?.length === 0) && type !== 'public' && (
                <EnrichmentButton contact={contact} networkId={activeNetwork.id} isList={Boolean(list?.id)} />
              )}
            </Box>
          </Box>
          {['default', 'public'].includes(type) && (
            <Box
              ml={[0, '16px']}
              pl={[0, '16px']}
              width={['auto', '280px']}
              flex="0 0 auto"
              sx={{ borderLeftWidth: [0, '1px'], borderLeftStyle: 'solid', borderLeftColor: 'neutral.100' }}
            >
              <ContactActionPanel
                type={type}
                page={page}
                contact={contact}
                matchId={match?.id}
                actions={type === 'public' ? ['show-importers', 'notes'] : ['add-importer', 'lists', 'tags', 'notes']}
                isPublic={type === 'public'}
                extendMethods={{
                  notes: {
                    onShowDetailView: () => showDetail(),
                    ...(type !== 'public'
                      ? {
                          onClickAddMore: () => {
                            setShowAddNote(true);
                            showDetail();
                          },
                        }
                      : undefined),
                  },
                }}
                queryParams={queryParams}
                showMoreOptions={false}
                list={list}
                deleteContactFromList={deleteContactFromList}
              />
            </Box>
          )}
          {type === 'matches' && (
            <Box
              ml="16px"
              pl="16px"
              width="280px"
              flex="0 0 auto"
              sx={{ borderLeft: 1, borderLeftColor: 'neutral.100' }}
            >
              <ScoreDetails scoreDetails={match.scoreDetails} />
              <Box mt="16px">
                <AddContactsToList
                  page={page}
                  addedToSharedList={addedToSharedList}
                  queryParams={queryParams}
                  contact={contact}
                  matchId={match.id}
                  list={list}
                />
              </Box>
              {contact.inPortco && <InPortco showTooltip={type === 'matches'} />}
            </Box>
          )}
        </Flex>
      )}
    </Card>
  );
};

ContactCard.propTypes = {
  contact: PropTypes.object.isRequired,
  queryParams: PropTypes.object,
  isSelected: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'read', 'matches', 'public']),
  notSelectable: PropTypes.bool,
  onChangeSelected: PropTypes.func,
  match: PropTypes.object,
  matchListInfo: PropTypes.object,
  jobId: PropTypes.number,
  organizationId: PropTypes.number,
  matchListId: PropTypes.number,
  showAddToLisButton: PropTypes.bool,
  addedToSharedList: PropTypes.bool,
  list: PropTypes.object,
  deleteContactFromList: PropTypes.bool,
  page: PropTypes.string.isRequired,
  onLinkedinClick: PropTypes.func,
  setShowAddNote: PropTypes.func,
  showDetail: PropTypes.func.isRequired,
};

ContactCard.defaultProps = {
  queryParams: {},
  isSelected: false,
  type: 'default',
  notSelectable: false,
  onChangeSelected: null,
  match: {},
  matchListInfo: {},
  jobId: null,
  organizationId: null,
  matchListId: null,
  showAddToLisButton: false,
  addedToSharedList: false,
  list: null,
  deleteContactFromList: false,
  onLinkedinClick: null,
  setShowAddNote: () => {},
};
