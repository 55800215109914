import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Button, Modal } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';

export const DeleteContactModal = ({ onCloseModal, onDeleteContact, deleting }) => (
  <Box data-testid="delete-contact-modal" onClick={(e) => e.stopPropagation()}>
    {createPortal(
      <Modal
        title="Delete contact?"
        onCancel={onCloseModal}
        actions={
          <Flex sx={{ gap: '8px', justifyContent: 'flex-end' }}>
            <Button variant="secondary" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button data-testid="delete-btn" variant="danger" onClick={onDeleteContact} loading={deleting}>
              Delete contact
            </Button>
          </Flex>
        }
      >
        <Box as="ul" pl="24px" pt="8px">
          <Box as="li">Remove this profile from your network.</Box>
          <Box as="li">
            Permanently delete all associated network data (lists, tags, notes, matches, and hiring reports).
          </Box>
          <Box as="li">This profiles will not be re-added in future CSV or LinkedIn imports.</Box>
          <Box as="li">
            You can re-add it later via single contact import or Chrome extension, associated network data will start
            fresh.
          </Box>
        </Box>
      </Modal>,
      document.getElementById('portals'),
    )}
  </Box>
);

DeleteContactModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onDeleteContact: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
};
