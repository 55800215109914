export const tabOrderOptions = [
  {
    label: 'Jobs, Companies, Talent network',
    value: 'jobs, companies, talent_network',
  },
  {
    label: 'Jobs, Talent network, Companies',
    value: 'jobs, talent_network, companies',
  },
  {
    label: 'Companies, Jobs, Talent network',
    value: 'companies, jobs, talent_network',
  },
  {
    label: 'Companies, Talent network, Jobs',
    value: 'companies, talent_network, jobs',
  },
  {
    label: 'Talent network, Jobs, Companies',
    value: 'talent_network, jobs, companies',
  },
  {
    label: 'Talent network, Companies, Jobs',
    value: 'talent_network, companies, jobs',
  },
];

export const tabOrderOptionsDefault = [
  {
    label: 'Jobs, Companies',
    value: 'jobs, companies',
  },
  {
    label: 'Companies, Jobs',
    value: 'companies, jobs',
  },
];

export const heroOptions = [
  {
    label: 'Hero',
    value: 'hero',
  },
  {
    label: 'Navigation bar',
    value: 'navigation_bar',
  },
  {
    label: "Don't show",
    value: 'off',
  },
];

export const companiesSort = [
  {
    label: 'Alphabetically',
    value: false,
  },
  {
    label: 'Number of jobs (descending)',
    value: true,
  },
];

export const heroTextColor = [
  {
    label: 'White',
    value: false,
  },
  {
    label: 'Black',
    value: true,
  },
];

export const heroBackgroundType = [
  {
    label: 'Solid color',
    value: 'solid',
  },
  {
    label: 'Image',
    value: 'image',
  },
];
