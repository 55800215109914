import { Monitor, Smartphone, Tablet } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useElementResize } from './useElementResize';

export const useSettingsPreview = (network) => {
  const [ref, { mode, dimension }] = useElementResize();
  const [view, setView] = useState(mode);
  const hasOrganizationsString = network.features.includes('organizations_string');

  useEffect(() => {
    setView(mode);
  }, [mode]);

  const hideNetworkInfo = useMemo(() => {
    if (!network) return true;

    const { features } = network;

    if (!features) return true;
    if (features.indexOf('hide_collection_info_in_hero') !== -1) return true;

    return (
      features.indexOf('hide_logo_in_hero') !== -1 &&
      features.indexOf('hide_numbers_in_hero') !== -1 &&
      features.indexOf('hide_name_in_hero') !== -1
    );
  }, [network]);

  const views = [
    {
      name: 'mobile',
      icon: Smartphone,
      onClick: () => {
        ref.current.style.width = `${400}px`;
        setView('mobile');
      },
    },
    {
      name: 'tablet',
      icon: Tablet,
      onClick: () => {
        ref.current.style.width = `${768}px`;
        setView('tablet');
      },
    },
    {
      name: 'desktop',
      icon: Monitor,
      onClick: () => {
        const { width: containerWidth } = ref.current.parentNode.getBoundingClientRect();
        ref.current.style.width = dimension.inlineWidth > containerWidth ? containerWidth : '100%';
        setView('desktop');
      },
    },
  ];

  return {
    hasOrganizationsString,
    hideNetworkInfo,
    setView,
    views,
    mode,
    view,
    ref,
    dimension,
  };
};
