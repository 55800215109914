import { createApi } from '@reduxjs/toolkit/query/react';

import { processHtml, removeWindowLoadEvent } from 'helpers/ad';
import { networkBaseQuery } from './queries';
import { networkV2Api } from './networkV2';

export const networkApi = createApi({
  reducerPath: 'networkApi',
  baseQuery: networkBaseQuery,
  tagTypes: ['network'],
  endpoints: (builder) => ({
    updateJobBoardSettings: builder.mutation({
      query: (values) => ({
        url: '/settings/job_board',
        method: 'put',
        data: values,
      }),
    }),
    getJobBoardSettings: builder.query({
      query: () => ({
        url: '/settings/job_board',
      }),
    }),
    getGeneralSettings: builder.query({
      query: () => ({
        url: '/settings/general',
      }),
      providesTags: ['network-settings-general'],
    }),
    updateGeneralSettings: builder.mutation({
      query: (values) => ({
        url: '/settings/general',
        method: 'put',
        data: { ...values, administrators: undefined, ambassadors: undefined },
      }),
      invalidatesTags: ['network-settings-general'],
    }),
    getTalentNetworkSettings: builder.query({
      query: () => ({
        url: '/settings/talent_network',
      }),
      providesTags: ['talent-network-settings', 'network-job-board-v2'],
    }),
    updateTalentNetwork: builder.mutation({
      query: (data) => ({
        url: `/settings/talent_network`,
        method: 'put',
        data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(
          networkApi.util.updateQueryData('getTalentNetworkSettings', undefined, (draft) => {
            Object.assign(draft, arg);
          }),
        );
        await queryFulfilled;
        dispatch(
          networkV2Api.util.updateQueryData('getJobBoardSettings', undefined, (draft) => {
            if (typeof arg.tnHidden === 'boolean') {
              Object.assign(draft, {
                tnHidden: arg.tnHidden,
              });
            }
          }),
        );
      },
    }),
    getAdvancedDesign: builder.query({
      query: () => ({
        url: '/advanced_designs',
      }),
      providesTags: ['advance-design'],
      transformResponse: (data, _, { network }) => {
        const result = { theme: data.theme, footer: data.footer, version: data.version };
        const advancedDesignValues = {
          tagline: network.tagline,
          subtitle: network.subtitle,
          organizationsCount: network.organizationsCount || network.companiesCount,
          jobsCount: network.jobsCount,
          heroColorMask: network.heroColorMask,
        };

        const processedHeader = processHtml(data.header, advancedDesignValues);
        const processedFooter = processHtml(data.footer, advancedDesignValues);

        result.header = processedHeader.html;
        result.footer = processedFooter.html;
        result.scripts = processedHeader.scripts.concat(processedFooter.scripts).map(removeWindowLoadEvent);
        result.linkedScripts = processedHeader.linkedScripts.concat(processedFooter.linkedScripts);
        result.styleSheet = `https://cdn-customers.getro.com/${network.label}/style.react.css??v=${data.version}`;
        return result;
      },
    }),
  }),
});

export const {
  useUpdateJobBoardSettingsMutation,
  useGetJobBoardSettingsQuery,
  useGetGeneralSettingsQuery,
  useUpdateGeneralSettingsMutation,
  useUpdateTalentNetworkMutation,
  useGetTalentNetworkSettingsQuery,
  useGetAdvancedDesignQuery,
} = networkApi;
