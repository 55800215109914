import { ContactsProgressCount } from 'components/molecules/contactsProgressCount';
import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass/styled-components';

export const UpdateBox = ({ contactsCount, contactsLimit, progressText }) => (
  <Flex width="100%">
    <Flex flexGrow={1} sx={{ '& > div': { width: '100%' } }}>
      <ContactsProgressCount contactsCount={contactsCount} limit={contactsLimit} progressText={progressText} />
    </Flex>
  </Flex>
);

UpdateBox.propTypes = {
  contactsCount: PropTypes.number.isRequired,
  contactsLimit: PropTypes.number.isRequired,
  progressText: PropTypes.string.isRequired,
};
