import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { MessageBlock } from '@getro/rombo';
import { PickerOverlay } from 'filestack-react';
import { ProfileDescription } from '../ProfileDescription';
import { activeNetworkSelector, userSelector } from '../../../redux/selectors';
import { loadUpdateUserProfile, loadUserProfile } from '../../../actions/profileActions';
import { key, makeSelectUserProfile, makeSelectUserFetchError } from '../../../redux/userProfile.selector';
import reducer from '../../../redux/reducers/userProfileReducer';
import saga from '../../../redux/sagas/userProfileSaga';
import { useInjectReducer } from '../../../hooks/useInjectReducer';
import { useInjectSaga } from '../../../hooks/useInjectSaga';
import { makeSelectIsLoading } from '../../../redux/loadingSelector';
import { trackException } from '../../../helpers/errorTracker';

const UserProfile = () => {
  const dispatch = useDispatch();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const fetchError = useSelector(makeSelectUserFetchError);
  const isUserProfileLoading = useSelector(makeSelectIsLoading('userProfile'));
  const activeNetwork = useSelector(activeNetworkSelector);
  const currentUser = useSelector(userSelector);
  const isUpdatingUserProfile = useSelector(makeSelectIsLoading('updateUserProfile'));
  const userProfile = useSelector(makeSelectUserProfile);
  const { id: activeNetworkId } = activeNetwork;
  const { id: memberId } = currentUser;

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const currentAxiosRequest = cancelToken.source();
    dispatch(loadUserProfile({ activeNetworkId, memberId, cancelToken: currentAxiosRequest.token }));
    // Cancel api calls on unmount
    return function cleanup() {
      currentAxiosRequest.cancel();
    };
  }, [dispatch, memberId, activeNetworkId]);

  return (
    <>
      {fetchError && (
        <MessageBlock
          title="There was an unexpected error"
          content="Please try again or contact us at help@getro.com."
          type="warning"
        />
      )}

      {!fetchError && (
        <ProfileDescription
          isLoading={isUserProfileLoading || !userProfile}
          handleException={trackException}
          isUpdating={isUpdatingUserProfile}
          errorMessage={fetchError}
          Picker={PickerOverlay}
          filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
          userProfile={userProfile}
          onUserUpdate={(updatedUser) => {
            dispatch(loadUpdateUserProfile({ updatedUser }));
          }}
        />
      )}
    </>
  );
};

export default UserProfile;
