import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';

export const SettingsLayoutHeader = ({ title, description, actions }) => (
  <Flex
    as="header"
    pb="16px"
    alignItems="center"
    sx={{ borderBottom: 1, borderBottomColor: 'border.subtle', gap: '16px' }}
  >
    <Flex
      alignItems={['flex-start', 'flex-start', 'center']}
      justifyContent="space-between"
      flexWrap="wrap"
      width="100%"
      flexDirection={['column', 'column', 'row']}
      sx={{ gap: '8px' }}
    >
      <Box>
        <Box as="h1" fontSize="18px" fontWeight="semibold" color="text.dark">
          {title}
        </Box>
        <Text mt="8px" fontSize="14px">
          {description}
        </Text>
      </Box>
      {!!actions.length && (
        <Flex alignItems="center" sx={{ gap: '8px' }}>
          {actions.map((action) => action)}
        </Flex>
      )}
    </Flex>
  </Flex>
);

SettingsLayoutHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  actions: PropTypes.array,
};

SettingsLayoutHeader.defaultProps = {
  description: '',
  actions: [],
};
