import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Checkbox } from '@getro/rombo';
import { TableHeaderSort } from './tableHeaderSort';
import { TableHeaderTitle } from './tableHeaderTitle';

const getPosition = (fixedColumn, index, columns) => {
  const isFirst = index === 0;
  const isLast = index === columns.length - 1;

  const sx = {
    top: 0,
    position: 'relative',
  };

  if (isFirst && fixedColumn === 'first') {
    sx.position = 'sticky';
    sx.left = 0;
  } else if (isLast && fixedColumn === 'last') {
    sx.position = 'sticky';
    sx.right = 0;
  }

  return sx;
};

export const TableHeader = ({
  columns,
  canSelect,
  onSelect,
  row,
  selectedAll,
  handleSort,
  sortedBy,
  hideColumn,
  fixedColumn,
}) => (
  <Box
    as="tr"
    sx={{
      borderBottom: '1px solid',
    }}
  >
    {columns.map((column, index) => (
      <Box
        key={column.accessor}
        as="th"
        sx={{
          ...getPosition(fixedColumn, index, columns),
          zIndex: 10,
          top: 0,
          left: 0,
          backgroundColor: 'white',
          width: column.width ?? '100%',
          minWidth: column.width ?? '100%',
          maxWidth: column.width ?? '100%',
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 1.68,
          letterSpacing: 1,
          color: 'text.subtle',
          textTransform: 'uppercase',
          padding: '8px',
          textAlign: 'left',
          borderColor: 'border.subtle',
          ':hover': {
            [`#${column.accessor}`]: {
              visibility: 'visible',
            },
          },
        }}
      >
        <Flex sx={{ justifyContent: 'space-between', width: '100%', alignItems: 'center', gap: '8px' }}>
          <Flex sx={{ gap: '8px' }}>
            {index === 0 && canSelect && (
              <Box sx={{ pt: '4px', minWidth: '16px' }}>
                <Checkbox
                  containerProps={{ width: '16px', minWidth: '16px' }}
                  sx={{ label: { p: '0px' } }}
                  name="job"
                  checked={selectedAll}
                  onChange={(e) => onSelect(row, e.target.checked, true)}
                />
              </Box>
            )}
            <Box pt="4px">
              <TableHeaderTitle Header={column.Header} tooltip={column.tooltip} />
            </Box>
          </Flex>
          <TableHeaderSort hideColumn={hideColumn} sortedBy={sortedBy} column={column} handleSort={handleSort} />
        </Flex>
      </Box>
    ))}
  </Box>
);

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  canSelect: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  selectedAll: PropTypes.bool.isRequired,
  handleSort: PropTypes.func.isRequired,
  sortedBy: PropTypes.array.isRequired,
  hideColumn: PropTypes.func.isRequired,
  fixedColumn: PropTypes.string.isRequired,
};
