import { useCallback, useMemo, useRef, useState } from 'react';
import useOnClickOutside from 'hooks/useClickOutside';

export const useMultiSelect = ({
  onOpen,
  onInitialOpenDropDown,
  showSearch,
  onSearch,
  debounceTime,
  useLocalSearch,
  options,
  clickOutsideDownEvent,
  onSelect,
}) => {
  const [search, setSearch] = useState('');
  const [showDropDown, setShowDropDown] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [typing, setTyping] = useState(false);

  const initialOpenedRef = useRef();
  const inputRef = useRef();
  const timerRef = useRef(null);

  const items = useMemo(
    () =>
      !useLocalSearch ? options : options.filter((item) => item.label.toLowerCase().includes(search.toLowerCase())),
    [options, search, useLocalSearch],
  );

  const handleClickOutside = useCallback(() => {
    setIsOpened(false);
    setShowInput(false);
    setShowDropDown(false);
    setSearch('');
  }, []);

  const ref = useOnClickOutside(handleClickOutside, { useEsc: true, downEvent: clickOutsideDownEvent });

  const openDropDown = () => {
    if (onOpen) {
      onOpen();
    }
    setShowDropDown(true);
    initialOpenedRef.current = true;
  };

  const onCloseDropDown = () => {
    if (showSearch) {
      setSearch('');
    }
    if (!showSearch || onInitialOpenDropDown) {
      setIsOpened(false);
    }
    setShowDropDown(false);
  };

  const handleSearch = (value, triggerSearch = true) => {
    clearTimeout(timerRef.current);
    setSearch(value);
    setTyping(true);
    timerRef.current = setTimeout(() => {
      setTyping(false);
      if (onSearch && triggerSearch) {
        onSearch(value);
      }
    }, debounceTime);
  };

  const onToggle = () => {
    if (!initialOpenedRef.current && onInitialOpenDropDown) {
      onInitialOpenDropDown();
      setShowDropDown(true);
    }

    if (!isOpened) {
      setIsOpened(true);
      setShowInput(true);
    } else {
      setIsOpened(false);
    }

    if (!showSearch) {
      openDropDown();
    }
  };

  const onShowSearch = () => {
    setIsOpened(true);
    setShowInput(true);

    if (onInitialOpenDropDown) {
      setShowDropDown(true);
      onInitialOpenDropDown();
    }

    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  const onItemSelect = (selectedItems) => {
    if (onInitialOpenDropDown) {
      setShowInput(false);
    }
    onSelect(selectedItems);
  };

  const clearFilter = () => {
    onSelect([]);
    setIsOpened(false);
  };

  const useInput = showSearch && showInput;

  return {
    search,
    showDropDown,
    isOpened,
    showInput,
    typing,
    items,
    ref,
    inputRef,
    setSearch,
    setShowDropDown,
    setShowInput,
    handleSearch,
    openDropDown,
    onCloseDropDown,
    onToggle,
    useInput,
    onShowSearch,
    onItemSelect,
    clearFilter,
  };
};
