import { createApi } from '@reduxjs/toolkit/query/react';
import pluralize from 'pluralize';

import { sortPositionsByCompany, toStringLastChange } from './contacts/postitions';
import { dateToMonthAndYear } from './dateFormatter';
import { networkBaseQuery } from './queries';

export const matchesApi = createApi({
  reducerPath: 'matchesApi',
  baseQuery: networkBaseQuery,
  tagTypes: ['matches', 'matches-detail'],
  endpoints: (builder) => ({
    getMatchList: builder.query({
      query: ({ params } = {}) => ({
        url: '/contacts/match_lists',
        params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        if (queryArgs.providesTags) {
          return endpointName;
        }

        return JSON.stringify(queryArgs.params);
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg?.providesTags) {
          currentCache.items.push(...newItems.items);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (data, items, args) => args?.providesTags || ['matches'],
      transformResponse(response) {
        response.hasMore = response.items.length < response.meta?.totalMatchLists;
        response.loaded = true;
        return response;
      },
    }),
    getMatchListById: builder.query({
      query: ({ matchListId }) => ({
        url: `/contacts/match_lists/${matchListId}`,
      }),
      providesTags: ['matches-detail'],
      transformResponse(response) {
        response.matchList.matches = response.matchList.matches.map((match, index) => ({
          ...match,
          position: index + 1,
        }));

        return response;
      },
    }),
    deleteMatchProject: builder.mutation({
      query: ({ matchListId }) => ({
        url: `/contacts/match_lists/${matchListId}`,
        method: 'delete',
      }),
      invalidatesTags: ['matches', 'matches-detail'],
    }),
    completeMatchProject: builder.mutation({
      query: ({ matchListId }) => ({
        url: `/contacts/match_lists/${matchListId}/complete`,
        method: 'patch',
      }),
      onQueryStarted({ matchListId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          matchesApi.util.updateQueryData('getMatchListById', { matchListId: `${matchListId}` }, ({ matchList }) => {
            Object.assign(matchList, { status: 'closed' });
          }),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    archiveMatchProject: builder.mutation({
      query: ({ matchListId }) => ({
        url: `/contacts/match_lists/${matchListId}/archive`,
        method: 'patch',
      }),
      onQueryStarted({ matchListId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          matchesApi.util.updateQueryData('getMatchListById', { matchListId: `${matchListId}` }, ({ matchList }) => {
            Object.assign(matchList, { status: 'archived' });
          }),
        );

        let archivePatch;

        const patchOpenMatch = dispatch(
          matchesApi.util.updateQueryData('getMatchList', { params: {} }, (draft) => {
            const matchToArchive = draft.items.find(({ id }) => id === matchListId);
            if (!matchToArchive) return;
            const items = draft.items.filter(({ id }) => id !== matchToArchive.id);
            Object.assign(draft, { items });
            archivePatch = dispatch(
              matchesApi.util.updateQueryData(
                'getMatchList',
                {
                  params: { 'statuses[]': 'archived' },
                },
                (archivedDraft) => {
                  matchToArchive.status = 'archived';
                  archivedDraft.items.push(matchToArchive);
                },
              ),
            );
          }),
        );

        queryFulfilled.catch(() => {
          patchResult.undo();
          patchOpenMatch.undo();
          if (archivePatch) archivePatch.undo();
        });
      },
    }),
    reactivateMatchProject: builder.mutation({
      query: ({ matchListId }) => ({
        url: `/contacts/match_lists/${matchListId}/reactivate`,
        method: 'patch',
      }),
      onQueryStarted({ matchListId }, { dispatch, queryFulfilled }) {
        let matchStatus;
        const patchResult = dispatch(
          matchesApi.util.updateQueryData('getMatchListById', { matchListId: `${matchListId}` }, ({ matchList }) => {
            matchStatus = matchList.status;
            Object.assign(matchList, { status: 'active' });
          }),
        );

        const params = new URLSearchParams(window.location.search);
        if (params.get('statuses[]')) {
          matchStatus = params.get('statuses[]');
        }

        let patchCurrentStatus;

        const patchPreviousStatus = dispatch(
          matchesApi.util.updateQueryData('getMatchList', { params: { 'statuses[]': matchStatus } }, (draft) => {
            const matchToReOpen = draft.items.find(({ id }) => id === matchListId);
            if (!matchToReOpen) return;
            const items = draft.items.filter(({ id }) => id !== matchListId);
            Object.assign(draft, { items });

            dispatch(
              matchesApi.util.updateQueryData(
                'getMatchList',
                {
                  params: {},
                },
                (closedDraft) => {
                  matchToReOpen.status = 'active';
                  closedDraft.items.push(matchToReOpen);
                },
              ),
            );
          }),
        );
        queryFulfilled.catch(() => {
          patchResult.undo();
          patchPreviousStatus.undo();
          if (patchCurrentStatus) patchCurrentStatus.undo();
        });
      },
    }),
    deleteMatch: builder.mutation({
      query: ({ matchId, data }) => ({ url: `/contacts/matches/${matchId}`, method: 'delete', data }),
      invalidatesTags: ['matches'],
      onQueryStarted({ matchId, matchListId, data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          matchesApi.util.updateQueryData('getMatchListById', { matchListId: `${matchListId}` }, ({ matchList }) => {
            const currentMatch = matchList.matches.find((item) => item.id === matchId);

            if (currentMatch) {
              currentMatch.deleted = true;
              currentMatch.deletedWithReason = !!data?.networkAdminRejectReasons.length;
              currentMatch.highlighted = false;
            }
          }),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    updateMatch: builder.mutation({
      query: ({ matchListId, data }) => ({ url: `/contacts/match_lists/${matchListId}`, method: 'put', data }),
      invalidatesTags: ['matches', 'matches-detail'],
    }),
    createMatchProject: builder.mutation({
      query: ({ data }) => ({ url: `/contacts/match_lists`, method: 'post', data }),
      invalidatesTags: ['matches', 'matches-detail'],
    }),
    activateMatchProject: builder.mutation({
      query: ({ matchingProjectId }) => ({
        url: `/contacts/match_lists/${matchingProjectId}/activate`,
        method: 'patch',
      }),
      invalidatesTags: ['matches', 'matches-detail'],
    }),
    getMatchStatus: builder.query({
      query: ({ matchListId }) => ({
        url: `/contacts/match_lists/${matchListId}/status`,
      }),
    }),
    updateHighlight: builder.mutation({
      query: ({ matchId }) => ({ url: `/contacts/matches/${matchId}/highlight`, method: 'put' }),
      onQueryStarted({ matchId, matchListId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          matchesApi.util.updateQueryData('getMatchListById', { matchListId: `${matchListId}` }, ({ matchList }) => {
            const currentMatch = matchList.matches.find((item) => item.id === matchId);

            if (currentMatch) {
              currentMatch.highlighted = !currentMatch.highlighted;
            }
          }),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useGetMatchListQuery,
  useGetMatchListByIdQuery,
  useDeleteMatchMutation,
  useUpdateHighlightMutation,
  useCreateMatchProjectMutation,
  useActivateMatchProjectMutation,
  useUpdateMatchMutation,
  useDeleteMatchProjectMutation,
  useCompleteMatchProjectMutation,
  useReactivateMatchProjectMutation,
  useArchiveMatchProjectMutation,
  useLazyGetMatchListQuery,
  useLazyGetMatchStatusQuery,
  useGetMatchStatusQuery,
} = matchesApi;

export const parsePastPosition = (position) => {
  let partial = `- ${position.jobTitle}`;

  partial += position.companyName ? ` at ${position.companyName}` : '';
  partial += position.dateFrom
    ? `${dateToMonthAndYear(position.dateFrom)} - ${position.dateTo ? dateToMonthAndYear(position.dateTo) : 'present'}`
    : '';

  return partial;
};

export const generatePlainListOfMatches = (matches = []) =>
  matches.reduce((carry, { contact }) => {
    let data = '';
    const lastChange = toStringLastChange(contact.primaryActivePosition);
    const postions = sortPositionsByCompany(contact.contactWorkExperiences);
    const pastPositions = [].concat(...postions);

    data += `${contact.fullName?.replace(/(\r\n|\n|\r)/gm, '').trim() || ''} · ${contact.linkedinUrl}\n`;

    data += contact.linkedinTagline ? `${contact.linkedinTagline}\n` : '';
    data +=
      !contact.linkedinTagline && contact.primaryActivePosition?.jobTitle
        ? `${contact.primaryActivePosition.jobTitle}\n`
        : '';

    data += contact.location?.description ? contact.location.description : '';
    data += contact.location && contact.yearsOfExperience && Math.floor(contact.yearsOfExperience) > 0 ? ' · ' : '';
    data +=
      contact.yearsOfExperience && Math.floor(contact.yearsOfExperience) > 0
        ? `${Math.floor(contact.yearsOfExperience)} ${pluralize(
            'year',
            Math.floor(contact.yearsOfExperience),
          )} of experience`
        : '';
    data +=
      contact.yearsOfExperience && Math.floor(contact.yearsOfExperience) > 0 && lastChange && Math.abs(lastChange) >= 2
        ? ' · '
        : '';
    data +=
      lastChange && Math.abs(lastChange) >= 2
        ? `Last change ${Math.abs(lastChange)} ${pluralize('year', Math.abs(lastChange))} ago`
        : '';
    data += `\n`;

    data += parsePastPosition(pastPositions[0]);
    data += `\n`;
    data += `\n`;

    return carry + data;
  }, '');
