/* eslint-disable function-paren-newline */
/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { Flex, Box, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { ListFilter } from 'lucide-react';
import { ScrollBox } from 'components/atoms/ScrollBox';
import { TableItem } from './components/tableItem';
import { TableHeader } from './components/tableHeader';
import { TableColumns } from './components/tableColumns';
import { TableSort } from './components/tableSort';
import { TableLoader } from './components/tableLoader';
import { TableWrapperWithScroll } from './components/tableWithScroll';
import { useSort } from './hooks/useSort';

export const DataTable = ({
  itemName,
  items,
  overscan = 10,
  initialItemCount = 10,
  hasMore = false,
  loadMore = () => {},
  isLoadingMore = false,
  columnDefinition,
  canSort = false,
  onRowProps,
  rowActions,
  initialSortBy,
  totalItems,
  emptyView,
  isInitialized = false,
  sx,
  tableSx,
  onSort,
  onSelect,
  canSelect = false,
  selectedAll = false,
  onColumnAction,
  persistColumnKey,
  showFilters,
  filtersTitle,
  FiltersComponent,
  filters,
  filterCount,
  leftActions,
  onSetFilters,
  onSaveFilters,
  fixedColumn = 'first',
}) => {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    persistColumnKey || 'datatable',
    columnDefinition.map((item) => ({
      ...item,
      show: true,
    })),
  );
  const showEmptyView = items.length === 0 && !isLoadingMore && isInitialized;
  const { selectedColumn, setSelectedColumn, handleSort } = useSort({
    columnDefinition: selectedColumns,
    initialSortBy,
    onSort,
  });

  return (
    <TableWrapperWithScroll fixedColumn={fixedColumn}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: ['space-between', 'space-between', 'flex-start'],
          mb: '18px',
          width: '100%',
          gap: ['0', '0', '16px'],
          pr: ['16px', '16px', '32px'],
        }}
      >
        <Box color="text.main" fontSize="12px" lineHeight="24px" ml={showFilters ? '16px' : '0'}>
          {`Showing all ${totalItems} ${pluralize(itemName, totalItems)}`}
        </Box>
        <Flex
          sx={{
            justifyContent: ['flex-end', 'flex-end', 'space-between'],
            alignItems: 'center',
            gap: '16px',
            flex: 1,
          }}
        >
          <Flex sx={{ alignItems: 'center', gap: [0, 0, '8px'] }}>
            <TableColumns
              selectedColumns={selectedColumns}
              setSelectedColumns={(item, action, column) => {
                setSelectedColumns(item);
                onColumnAction?.(action, { payload: column });
              }}
            />
            {canSort && (
              <TableSort
                selectedSort={{
                  value: initialSortBy,
                  label: initialSortBy.desc ? 'Descending' : 'Ascending',
                }}
                setSelectedSort={({ value }) => handleSort(value, selectedColumn.value)}
                columns={selectedColumns.filter((column) => !column.disableSortBy)}
                selectedColumn={selectedColumn}
                setSelectedColumn={(column) => {
                  setSelectedColumn(column);
                  handleSort(initialSortBy.desc ? 'desc' : 'asc', column.value);
                }}
              />
            )}
            {showFilters && (
              <Flex
                sx={{
                  flex: '0 0 auto',
                  alignItems: 'center',
                  p: '8px',
                  gap: '8px',
                  cursor: 'pointer',
                  bg: filtersVisible ? 'neutral.20' : 'transparent',
                }}
                onClick={() => setFiltersVisible(!filtersVisible)}
              >
                <Box as={ListFilter} width="16px" height="16px" aria-hidden="true" />
                <Text fontSize="14px" fontWeight="500" display={['none', 'none', 'Block']}>
                  Filter
                </Text>
                {filterCount > 0 && (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    px="4px"
                    width="16px"
                    height="16px"
                    sx={{ bg: 'purple.100', borderRadius: 'circle' }}
                  >
                    <Text fontSize="12px" fontWeight="600" color="purple.400">
                      {filterCount}
                    </Text>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
          {leftActions}
        </Flex>
      </Flex>
      <Flex sx={{ zIndex: 1, position: 'relative' }}>
        {filtersVisible && (
          <FiltersComponent
            filtersTitle={filtersTitle}
            filters={filters}
            filterCount={filterCount}
            setFilters={onSetFilters}
            setFiltersVisible={setFiltersVisible}
            saveFilters={onSaveFilters}
          />
        )}
        <ScrollBox
          overflowX="auto"
          as={TableVirtuoso}
          sx={{
            height: '600px!important',
            width: '100%',
            ...sx,
            ...(showEmptyView || !isInitialized
              ? {
                  height: '48px!important',
                  overflow: 'hidden',
                }
              : {}),
          }}
          data={!isInitialized ? [] : items}
          overscan={overscan}
          initialItemCount={initialItemCount}
          rangeChanged={({ endIndex }) => {
            if (endIndex === items.length - 1 && hasMore && !isLoadingMore) {
              loadMore();
            }
          }}
          components={{
            Table: (props) => (
              <Box
                as="table"
                {...props}
                sx={{
                  width: '100%',
                  height: 'calc(100vh - 240px)',
                  ...tableSx,
                }}
              />
            ),
          }}
          fixedHeaderContent={() => (
            <TableHeader
              fixedColumn={fixedColumn}
              selectedAll={selectedAll}
              canSelect={canSelect}
              onSelect={onSelect}
              checked={selectedAll}
              columns={selectedColumns.filter((item) => item.show)}
              handleSort={handleSort}
              sortedBy={initialSortBy}
              hideColumn={(column) => {
                onColumnAction('hide', { payload: column });
                setSelectedColumns((prev) =>
                  prev.map((item) => {
                    if (item.accessor === column.accessor) {
                      return { ...item, show: false };
                    }
                    return item;
                  }),
                );
              }}
            />
          )}
          itemContent={(_, item) => {
            const rowProps = onRowProps ? onRowProps(item) : {};
            if (!item) return null;

            const columns = selectedColumns.filter((column) => column.show);

            return columns.map(({ accessor, disablePadding }, index) => (
              <TableItem
                {...rowProps}
                onSelect={onSelect}
                canSelect={canSelect}
                isSticky={index === 0}
                isFirst={index === 0}
                isLast={index === columns.length - 1}
                key={accessor}
                row={item}
                item={item[accessor]}
                rowActions={rowActions}
                disablePadding={disablePadding}
                fixedColumn={fixedColumn}
              />
            ));
          }}
        />
      </Flex>
      {showEmptyView && (
        <Box>
          <Box style={{ padding: 0, border: 'none' }} colSpan={selectedColumns.filter((item) => item.show).length}>
            <Flex justifyContent="center" alignItems="center" width="100%" py={3}>
              {emptyView}
            </Flex>
          </Box>
        </Box>
      )}

      {(isLoadingMore || !isInitialized) && (
        <TableLoader colSpan={selectedColumns.filter((item) => item.show).length} />
      )}
    </TableWrapperWithScroll>
  );
};

DataTable.propTypes = {
  itemName: PropTypes.string,
  items: PropTypes.array.isRequired,
  overscan: PropTypes.number,
  initialItemCount: PropTypes.number,
  totalItems: PropTypes.number,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  isLoading: PropTypes.bool,
  columnDefinition: PropTypes.array.isRequired,
  canSort: PropTypes.bool,
  onRowProps: PropTypes.func,
  rowActions: PropTypes.shape({ height: PropTypes.number, items: PropTypes.arrayOf(PropTypes.object) }),
  initialSortBy: PropTypes.shape({ id: PropTypes.string, desc: PropTypes.bool }),
  emptyView: PropTypes.node,
  isInitialized: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  sx: PropTypes.object,
  tableSx: PropTypes.object,
  onSort: PropTypes.func,
  onSelect: PropTypes.func,
  canSelect: PropTypes.bool,
  selectedAll: PropTypes.bool,
  onColumnAction: PropTypes.func,
  persistColumnKey: PropTypes.string,
  showFilters: PropTypes.bool,
  filtersTitle: PropTypes.string,
  FiltersComponent: PropTypes.elementType,
  filters: PropTypes.object,
  filterCount: PropTypes.number,
  leftActions: PropTypes.node,
  onSetFilters: PropTypes.func,
  onSaveFilters: PropTypes.func,
  fixedColumn: PropTypes.oneOf(['first', 'last']),
};

DataTable.defaultProps = {
  itemName: 'job',
  overscan: 10,
  initialItemCount: 10,
  hasMore: false,
  totalItems: 0,
  loadMore: () => {},
  isLoading: false,
  canSort: false,
  onRowProps: () => {},
  rowActions: null,
  initialSortBy: { id: 'asc', desc: false },
  emptyView: null,
  isInitialized: false,
  isLoadingMore: false,
  sx: {},
  tableSx: {},
  onSort: () => {},
  onSelect: () => {},
  canSelect: false,
  selectedAll: false,
  onColumnAction: () => {},
  persistColumnKey: null,
  showFilters: false,
  filtersTitle: '',
  FiltersComponent: null,
  filters: {},
  filterCount: 0,
  leftActions: null,
  onSetFilters: () => {},
  onSaveFilters: () => {},
  fixedColumn: 'first',
};
