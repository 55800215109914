import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { FormLabel, Input, Select } from '@getro/rombo';

import { convertFromCents, convertToCents } from '../../../helpers/money';

export const SalaryFilter = ({ value, onChange }) => {
  const currencyOptions = [
    { label: 'AUD', value: 'AUD' },
    { label: 'BRL', value: 'BRL' },
    { label: 'CAD', value: 'CAD' },
    { label: 'EUR', value: 'EUR' },
    { label: 'GBP', value: 'GBP' },
    { label: 'INR', value: 'INR' },
    { label: 'JPY', value: 'JPY' },
    { label: 'MXN', value: 'MXN' },
    { label: 'NZD', value: 'NZD' },
    { label: 'SGD', value: 'SGD' },
    { label: 'USD', value: 'USD' },
  ];
  const periodOptions = [
    { label: 'Per year', value: 'year' },
    { label: 'Per month', value: 'month' },
    { label: 'Per day', value: 'day' },
    { label: 'Per hour', value: 'hour' },
  ];
  const [currency, setCurrency] = useState(
    value?.currency ? currencyOptions.find((c) => c.value === value.currency) : { label: 'USD', value: 'USD' },
  );
  const [period, setPeriod] = useState(
    value?.period ? periodOptions.find((p) => p.value === value.period) : { label: 'Per year', value: 'year' },
  );
  const [amountMin, setAmountMin] = useState(value?.amountMinCents && convertFromCents(value.amountMinCents));

  useEffect(() => {
    onChange({
      currency: currency.value,
      period: period.value,
      amountMinCents: amountMin && convertToCents(amountMin),
    });
  }, [currency, period, amountMin]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <FormLabel>Minimum salary</FormLabel>
      <Flex
        sx={{
          input: { m: 0, borderColor: 'border.default' },
          position: 'relative',
          alignItems: 'center',
          svg: { color: 'neutral.400' },
          cursor: 'pointer',
          gap: '8px',
        }}
      >
        <Box flex="1">
          <Input
            placeholder="0"
            value={amountMin}
            onChange={(e) => {
              setAmountMin(e.target.value);
            }}
          />
        </Box>
        <Box flex="1">
          <Select
            data-testid="currency-select"
            options={currencyOptions}
            value={currency}
            onChange={(newValue) => {
              setCurrency(newValue);
            }}
          />
        </Box>
        <Box flex="1">
          <Select
            data-testid="period-select"
            options={periodOptions}
            value={period}
            onChange={(newValue) => {
              setPeriod(newValue);
            }}
          />
        </Box>
      </Flex>
    </Box>
  );
};

SalaryFilter.defaultProps = {
  value: null,
};

SalaryFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};

export default SalaryFilter;
