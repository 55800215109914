import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@getro/rombo';
import { Flex, Text, Card } from 'rebass/styled-components';

const EMPTY_MESSAGE = 'None specified';

export const JobRules = ({ isLoading, titles, keywords, locations }) => {
  if (isLoading) {
    return <Loader />;
  }
  const renderListItem = (text) => (
    <Text as="li" key={text} display="block">
      {text}
    </Text>
  );
  return (
    <Flex flexDirection="column">
      <Text color="text.dark" fontSize="14px" fontWeight="medium">
        Job titles
      </Text>
      <Text pt="16px" pb="8px" fontSize="14px">
        Show jobs with one of these keywords in their title
      </Text>
      <Card as="ul" tx="card" variant="threeUp" width={[1]} fontSize="14px">
        {titles && titles.map((item) => renderListItem(item))}
        {!titles && renderListItem(EMPTY_MESSAGE)}
      </Card>
      <Text pt="16px" pb="8px" fontSize="14px">
        Hide jobs with one of these keywords in their title
      </Text>
      <Card as="ul" tx="card" variant="threeUp" width={[1]} fontSize="14px">
        {keywords && keywords.map((item) => renderListItem(item))}
        {!keywords && renderListItem(EMPTY_MESSAGE)}
      </Card>
      <Text mt="40px" color="text.dark" fontSize="14px" fontWeight="medium">
        Job locations
      </Text>
      <Text pt="16px" pb="8px" fontSize="14px">
        Only show jobs in one of these locations
      </Text>
      <Card as="ul" tx="card" variant="threeUp" width={[1]} fontSize="14px">
        {locations && locations.map((item) => renderListItem(item))}
        {!locations && renderListItem(EMPTY_MESSAGE)}
      </Card>
    </Flex>
  );
};

JobRules.propTypes = {
  isLoading: PropTypes.bool,
  titles: PropTypes.array,
  keywords: PropTypes.array,
  locations: PropTypes.array,
};

JobRules.defaultProps = {
  isLoading: false,
  titles: null,
  keywords: null,
  locations: null,
};
