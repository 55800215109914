import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import FundFilterGrowth from 'components/atoms/FundFilterGrowth';
import OptionsFilter from '../../atoms/OptionsFilter';
import KeywordFilter from '../../atoms/KeywordFilter';
import { ApiFacet } from '../../molecules/filters/apiFacet';
import { LocationFilter } from '../../molecules/filters/location';
import YearsOfExperienceFilter from '../../molecules/filters/yearsOfExperience';
import { ContactOfFilter } from '../../molecules/filters/contactOf';
import { JobTitle } from '../../molecules/filters/jobTitle';
import { SharedList } from '../../molecules/filters/sharedListFilters';

export const Filters = ({ sharedList, filters, filterCount, setFilters, showListFilters, clearFundFilters }) => (
  <Flex
    flexGrow="1"
    alignItems="flex-end"
    pb={filterCount > 0 ? '72px' : 0}
    sx={{
      gap: '12px',
      flexWrap: 'wrap',
    }}
  >
    <Box width="100%" data-testid="contact-filters-keywords" mb="8px">
      <KeywordFilter selected={filters.keywords} onEnter={(value) => setFilters({ keywords: value })} />
    </Box>
    <Flex width="100%" flexDirection="column" sx={{ gap: '8px' }} data-testid="contact-filters-job-titles">
      <JobTitle filters={filters} setFilters={setFilters} sharedList={sharedList} showListFilters={showListFilters} />
    </Flex>
    <Box width="100%" data-testid="contact-filters-location">
      <LocationFilter
        locationRadius={filters.locationRadius}
        value={filters.locationIds}
        setFilters={setFilters}
        showRadius
      />
    </Box>
    <Flex width="100%" mb="8px" flexDirection="column" sx={{ gap: '8px' }} data-testid="contact-filters-company-name">
      <FundFilterGrowth
        onSelect={(stage, value) => {
          if (stage === 'clear') {
            clearFundFilters();
            return;
          }

          let key = stage === 'current' ? 'activePositionsOrganizationStages' : 'stageTransitions';
          if (stage === 'early') {
            key = 'companyStages';
          }

          setFilters({
            [key]: value.filter((item) => item.stage === stage),
          });
        }}
        selected={[
          ...(filters?.activePositionsOrganizationStages ?? []),
          ...(filters?.stageTransitions ?? []),
          ...(filters?.companyStages ?? []),
        ]}
      />
    </Flex>
    <Flex width="100%" data-testid="contact-filters-industry-tags" flexDirection="column" sx={{ gap: '8px' }}>
      <ApiFacet
        name="industry_tags"
        label="Industry"
        value={filters.industryTags}
        onChange={(value) => setFilters({ industryTags: value })}
        notFoundText="No contacts with experience in"
        orderBy="alpha"
        sharedList={sharedList}
        useInitialOpenDropDown
      />
      {!!filters.industryTags.length && (
        <OptionsFilter
          placeholder="2 or more years"
          options={[
            { label: '2 or more years', value: 2 },
            { label: '5 or more years', value: 5 },
            { label: '10 or more years', value: 10 },
          ]}
          selected={filters.industryTagsYears}
          onSelect={(values) => setFilters({ industryTagsYears: values })}
        />
      )}
    </Flex>
    {showListFilters && (
      <Box width="100%" data-testid="contact-filters-shared-lists-ids">
        <SharedList value={filters.sharedListIds} onChange={(value) => setFilters({ sharedListIds: value })} />
      </Box>
    )}
    <Box width="100%" data-testid="contact-filters-tags">
      <ApiFacet
        name="tags"
        label="Tags"
        value={filters.tags}
        onChange={(value) => setFilters({ tags: value })}
        notFoundText="No tag"
        orderBy="alpha"
        sharedList={sharedList}
        useShareListFilter={!showListFilters}
        useInitialOpenDropDown
      />
    </Box>
    <Box width="100%" data-testid="contact-filters-importer-ids">
      <ContactOfFilter value={filters.importerIds} onChange={(value) => setFilters({ importerIds: value })} />
    </Box>
    <Box width="100%" mb="8px" data-testid="contact-filters-years-experience">
      <YearsOfExperienceFilter
        label="Years of experience"
        name="years_experience"
        min={0}
        max={41}
        yearsOfExperience={filters.yearsOfExperience}
        onYearsOfExperienceChange={(value) => setFilters({ yearsOfExperience: value })}
      />
    </Box>
    <Box width="100%" data-testid="contact-filters-years-in-current-company">
      <YearsOfExperienceFilter
        label="Years in current company"
        name="years_in_current_company"
        min={0}
        max={41}
        yearsOfExperience={filters.yearsInCurrentCompany}
        onYearsOfExperienceChange={(value) => setFilters({ yearsInCurrentCompany: value })}
      />
    </Box>
  </Flex>
);

Filters.propTypes = {
  sharedList: PropTypes.object,
  filters: PropTypes.object.isRequired,
  filterCount: PropTypes.number,
  setFilters: PropTypes.func.isRequired,
  showListFilters: PropTypes.bool.isRequired,
  clearFundFilters: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  filterCount: 0,
  sharedList: null,
};
