import React from 'react';
import { Button } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Pencil } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import NetworkSchema from 'schemas/network';

export const JobBoardAppearancePanel = ({ activeNetwork }) => (
  <Flex mb="32px" sx={{ gap: '16px', flexDirection: 'column' }}>
    <Box>
      <Text fontSize="14px" fontWeight="medium" color="text.dark">
        Live editor
      </Text>
      <Text mt="4px" sx={{ color: 'text.main', fontSize: '14px' }}>
        Make real-time updates with our advanced live editor.
      </Text>
    </Box>
    <Box>
      <Button
        as={NavLink}
        to={`/networks/${activeNetwork?.slug}/job-board/live-preview`}
        icon={Pencil}
        iconPosition="right"
      >
        Open live editor
      </Button>
    </Box>
  </Flex>
);

JobBoardAppearancePanel.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
};
