import React from 'react';
import { Tooltip } from '@getro/rombo';
import PropTypes from 'prop-types';
import { Text } from 'rebass/styled-components';

export const TableHeaderTitle = ({ Header, tooltip }) => {
  if (typeof Header === 'function') {
    return React.createElement(Header);
  }

  if (tooltip) {
    return (
      <Tooltip
        content={
          <Text color="neutral.0" fontSize="14px" sx={{ textTransform: 'none', letterSpacing: 'normal' }}>
            {tooltip}
          </Text>
        }
        trigger={<Text sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{Header}</Text>}
      />
    );
  }

  return <Text sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{Header}</Text>;
};

TableHeaderTitle.propTypes = {
  Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  tooltip: PropTypes.string,
};

TableHeaderTitle.defaultProps = {
  tooltip: null,
};
