import { Button, Modal } from '@getro/rombo';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Filters } from './filters';

export const FiltersModal = ({
  sharedList,
  filters,
  filterCount,
  setFilters,
  resetFilters,
  extendedMode,
  isTalentNetworkEnabled,
  showListFilters,
  onClose,
  clearFundFilters,
}) => (
  <Box display={['block', 'block', 'none']}>
    <Modal
      isOpen
      onCancel={onClose}
      title="Filter contacts"
      pauseFocusTrap
      actions={
        <Flex alignItems="center" sx={{ gap: '8px' }}>
          <Button size="small"> Save changes</Button>
          {filterCount > 0 && (
            <Button variant="tertiary" size="small" px="8px" onClick={resetFilters}>
              Clear all filters
            </Button>
          )}
        </Flex>
      }
    >
      <Box sx={{ position: 'relative' }}>
        <Filters
          sharedList={sharedList}
          filters={filters}
          setFilters={setFilters}
          extendedMode={extendedMode}
          isTalentNetworkEnabled={isTalentNetworkEnabled}
          showListFilters={showListFilters}
          clearFundFilters={clearFundFilters}
        />
      </Box>
    </Modal>
  </Box>
);

FiltersModal.propTypes = {
  sharedList: PropTypes.object,
  filters: PropTypes.object.isRequired,
  filterCount: PropTypes.number.isRequired,
  setFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  extendedMode: PropTypes.bool,
  isTalentNetworkEnabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showListFilters: PropTypes.bool.isRequired,
  clearFundFilters: PropTypes.func.isRequired,
};

FiltersModal.defaultProps = {
  sharedList: null,
  extendedMode: false,
  isTalentNetworkEnabled: false,
};
