import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { Loader } from '@getro/rombo';
import { Card } from '../Card';
import { OptionItem } from './optionItem';
import { ScrollBox } from '../ScrollBox';

export const SelectDropdown = forwardRef(
  (
    {
      children,
      loading,
      options,
      currentOption,
      showSearch,
      toggle,
      sx,
      defaultText,
      bottomText,
      useSearchAsTag,
      isBoolean,
      errorMessage,
    },
    ref,
  ) => (
    <Card
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '240px',
        p: '8px 0',
        width: '100%',
        zIndex: 1,
        ...(sx || {}),
      }}
      data-testid="multi-select-dropdown"
    >
      {useSearchAsTag && errorMessage && (
        <Box
          sx={{
            bg: 'neutral.0',
            padding: '8px 16px',
            color: 'red.500',
            fontSize: '12px',
            pb: '0px',
          }}
        >
          {errorMessage}
        </Box>
      )}
      {((useSearchAsTag && !errorMessage) || (options.length > 0 && !loading && !errorMessage)) && (
        <ScrollBox
          sx={{
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          {options.map((option, index) => (
            <OptionItem
              key={option.value}
              option={option}
              index={index}
              currentOption={currentOption}
              showSearch={showSearch}
              toggle={toggle}
              inputRef={ref}
            />
          ))}
          {bottomText && (
            <Box as="p" sx={{ px: '16px', pt: '8px' }} color="text.subtle" fontSize="12px" fontStyle="italic">
              {bottomText}
            </Box>
          )}
        </ScrollBox>
      )}
      {(!errorMessage || (!isBoolean && useSearchAsTag)) && children}
      {loading && !isBoolean && (
        <Box data-testid="loader" sx={{ p: '24px' }}>
          <Loader size="small" />
        </Box>
      )}
      {!errorMessage && !loading && options.length === 0 && defaultText && (
        <Box as="p" color="text.subtle" fontSize="12px" fontStyle="italic" sx={{ px: '16px', pt: '8px' }}>
          {defaultText}
        </Box>
      )}
    </Card>
  ),
);

SelectDropdown.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  options: PropTypes.array,
  currentOption: PropTypes.object,
  showSearch: PropTypes.bool,
  toggle: PropTypes.func,
  defaultText: PropTypes.string,
  sx: PropTypes.object,
  bottomText: PropTypes.string,
  useSearchAsTag: PropTypes.bool,
  errorMessage: PropTypes.string,
  isBoolean: PropTypes.bool,
};

SelectDropdown.defaultProps = {
  children: null,
  loading: false,
  options: [],
  currentOption: null,
  showSearch: false,
  toggle: () => {},
  sx: {},
  defaultText: '',
  bottomText: '',
  useSearchAsTag: false,
  errorMessage: '',
  isBoolean: false,
};
