import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import NetworkSchema from 'schemas/network';
import { useTheme } from 'styled-components';
import { trackException } from 'helpers/errorTracker';

const JQUERY_URL = 'https://code.jquery.com/jquery-3.6.0.min.js';

export const CustomHeader = memo(({ network, advancedDesign, iframeRef }) => {
  const customHeaderId = useMemo(() => `${network.label}-custom-header`, [network.label]);
  const customHeaderClassName = `${network.label}-custom-part`;
  const [isJqueryLoaded, setIsJqueryLoaded] = useState(false);
  const [isStylesLoaded, setIsStylesLoaded] = useState(false);
  const containerRef = useRef();
  const theme = useTheme();

  const loadStyles = useCallback(async () => {
    if (!iframeRef) return;
    const link = document.createElement('link');
    link.href = advancedDesign.styleSheet;
    link.rel = 'stylesheet';
    link.id = 'custom-header-style';

    link.onload = () => {};
    iframeRef.contentWindow.document.body.classList.add(`custom-design-${network.label}`);
    iframeRef.contentWindow.document.head.appendChild(link);
    setIsStylesLoaded(true);
  }, [advancedDesign.styleSheet, iframeRef, network.label]);

  const loadScripts = useCallback(async () => {
    if (!iframeRef.contentWindow) return;
    const script = document.createElement('script');
    script.src = JQUERY_URL;
    script.type = 'text/javascript';
    script.onload = () => {
      setIsJqueryLoaded(true);
    };

    iframeRef.contentWindow.document.head.appendChild(script);
  }, [iframeRef]);

  useEffect(() => {
    (async () => {
      await loadStyles();

      if (advancedDesign.scripts.length) {
        await loadScripts();
      }
    })();
  }, [loadStyles, customHeaderId, network.label, advancedDesign.scripts.length, loadScripts]);

  useEffect(() => {
    if (isJqueryLoaded && isStylesLoaded) {
      advancedDesign.scripts.forEach((item, id) => {
        try {
          const scriptElement = document.createElement('script');
          scriptElement.innerHTML = item;
          scriptElement.type = 'text/javascript';
          scriptElement.id = `${customHeaderId}-custom-script-${id}`;
          iframeRef.current.contentWindow.document.body.appendChild(scriptElement);
        } catch (error) {
          trackException(error);
        }
      });
    }

    return () => {
      advancedDesign.scripts.forEach((item, id) => {
        const scriptElement = document.getElementById(`${customHeaderId}-custom-script-${id}`);
        if (scriptElement) {
          scriptElement.remove();
        }
      });
    };
  }, [advancedDesign.scripts, customHeaderId, iframeRef, isJqueryLoaded, isStylesLoaded]);

  return (
    <Box
      sx={{
        fontFamily: 'body',
        fontWeight: theme.fontWeights.body,
        fontStyle: 'normal',
        fontSize: theme.fontSizes[2],
        color: theme.colors.text.main,
      }}
    >
      <Box
        ref={containerRef}
        data-testid="custom-header"
        id={customHeaderId}
        className={`${customHeaderClassName}`}
        dangerouslySetInnerHTML={{ __html: advancedDesign.header }}
      />
    </Box>
  );
});

CustomHeader.propTypes = {
  network: NetworkSchema.isRequired,
  advancedDesign: PropTypes.object.isRequired,
  iframeRef: PropTypes.object.isRequired,
};
