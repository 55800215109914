import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route as RouterRoute, Redirect } from 'react-router-dom';
import { userSelector, networksSelector } from 'redux/selectors';
import * as Sentry from '@sentry/react';

import get from 'helpers/get';
import { setRole, mapRoleToPermissions } from './permissions';

import { onboardingEmployersRoutes } from './onboardingEmployersRoutes';
import { sessionRoutes } from './sessionRoutes';

const VISIBILITY_OPTIONS = ['public', 'user', 'onboarding', 'all'];
const EXCLUDE_ROUTES = ['/stay-tuned'];

// eslint-disable-next-line react/prop-types
const Route = ({ component: Component, visibility, path, ...rest }) => {
  const user = useSelector(userSelector);
  const networksStore = useSelector(networksSelector);
  const userLoggedIn = !!user.id;
  const { networks } = networksStore;

  const role = setRole({ authenticated: userLoggedIn, onboardingCompleted: user ? user.onboardingCompleted : null });
  const permissions = mapRoleToPermissions(role);

  const renderRoute = (props) => {
    let excludedRoute = EXCLUDE_ROUTES.includes(path);
    if (Array.isArray(path)) {
      // eslint-disable-next-line react/prop-types
      excludedRoute = path.some((p) => EXCLUDE_ROUTES.includes(p));
    }

    if (
      userLoggedIn &&
      !excludedRoute &&
      !Object.values(onboardingEmployersRoutes).some((r) => path === r.path) &&
      (!networks || !Object.keys(networks).length)
    ) {
      return <Redirect to="/stay-tuned" />;
    }

    let visibilities = visibility;
    if (!Array.isArray(visibility)) {
      visibilities = [visibility];
    }

    if (permissions.routes.some((e) => visibilities.includes(e))) {
      return <Component {...props} />;
    }

    // Redirect to previous URL after login
    const fromPath = get(props, 'location.state.from.pathname');
    const fromSearch = get(props, 'location.state.from.search');
    // eslint-disable-next-line react/prop-types
    if (props.location.pathname === sessionRoutes.login.path && fromPath) {
      return (
        <Redirect
          to={{
            pathname: fromPath,
            search: fromSearch,
            // eslint-disable-next-line react/prop-types
            state: { from: props.location },
          }}
        />
      );
    }

    return (
      <Redirect
        to={{
          pathname: permissions.home,
          // eslint-disable-next-line react/prop-types
          state: { from: props.location },
        }}
      />
    );
  };

  return <RouterRoute {...rest} path={path} render={renderRoute} />;
};

Route.propTypes = {
  component: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  visibility: (props, propName, componentName) => {
    // eslint-disable-next-line react/destructuring-assignment
    const propValues = props[propName];

    const isArray = Array.isArray(propValues);
    const isString = typeof propValues === 'string';

    const error = `\`${componentName}\` visibility prop only accept Array or string with this possible values: ${VISIBILITY_OPTIONS.join(
      ',',
    )}`;

    if (!isString && !isArray) {
      return new Error(
        `Invalid prop \`${propName}\` of type \`${typeof propValues}\` supplied to \`${componentName}\`, expected \`string\` or \`array\`.`,
      );
    }

    if (isArray && propValues.some((p) => !VISIBILITY_OPTIONS.includes(p))) {
      return new Error(error);
    }

    if (isString && !VISIBILITY_OPTIONS.includes(propValues)) {
      return new Error(error);
    }

    return null;
  },
};

export default Sentry.withSentryRouting(Route);
