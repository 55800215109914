import React from 'react';
import { Text, Flex, Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Pencil, X } from 'lucide-react';
import { AddMoreButton } from './addMoreButton';

export const SelectedTags = ({
  selected,
  onSelect,
  disableAddMore,
  disableAddMoreMessage,
  showSearch,
  onShowSearch,
  onToggle,
  handleSearch,
}) => (
  <Flex sx={{ gap: '4px', flexWrap: 'wrap', width: '100%', alignItems: 'center' }}>
    <Flex
      sx={{
        alignItems: 'center',
        gap: '4px',
        flexWrap: 'wrap',
      }}
    >
      {selected.map((item) => (
        <Flex
          sx={{
            bg: item.isEditable ? 'purple.100' : 'neutral.0',
            borderRadius: '99px',
            p: '4px 12px',
            border: '1px solid',
            borderColor: 'border.subtle',
            gap: '4px',
            height: '24px',
          }}
          data-testid={`tag-${item.value}`}
        >
          <Text
            sx={{
              fontSize: '14px',
              color: 'text.main',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              lineHeight: '1',
            }}
          >
            {item.label}
          </Text>
          <Flex
            sx={{
              gap: '4px',
              justifyContent: 'center',
              alignItems: 'center',
              height: '16px',
              minWidth: item.isEditable ? '36px' : '16px',
            }}
          >
            <Box
              onClick={(e) => {
                e.stopPropagation();
                onSelect(selected.filter((selectedItem) => selectedItem.value !== item.value));
              }}
              cursor="pointer"
              as={X}
              size="16px"
              sx={{ color: 'neutral.400' }}
              data-exclude-click-outside
            />
            {item.isEditable && (
              <Box
                cursor="pointer"
                as={Pencil}
                size="16px"
                sx={{ color: 'neutral.400' }}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelect(selected.filter((selectedItem) => selectedItem.value !== item.value));
                  handleSearch(item.label, false);
                  onShowSearch();
                }}
                data-exclude-click-outside
              />
            )}
          </Flex>
        </Flex>
      ))}
      {selected.length > 0 && (
        <AddMoreButton {...{ disableAddMore, disableAddMoreMessage, showSearch, onShowSearch, onToggle }} />
      )}
    </Flex>
  </Flex>
);

SelectedTags.propTypes = {
  selected: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  disableAddMore: PropTypes.bool,
  disableAddMoreMessage: PropTypes.string,
  showSearch: PropTypes.bool,
  onShowSearch: PropTypes.func,
  onToggle: PropTypes.func,
  handleSearch: PropTypes.func,
};

SelectedTags.defaultProps = {
  disableAddMore: false,
  disableAddMoreMessage: null,
  showSearch: false,
  onShowSearch: () => {},
  onToggle: () => {},
  handleSearch: null,
};
