import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmailNotificationsPanel from '../../components/molecules/emailNotifcationsPanel';
import { hasJobAlertsEnabledSelector, hasSuggestedCompaniesSelector, userSelector } from '../../redux/selectors';
import { loadUpdateUserProfile } from '../../actions/profileActions';
import { useInjectReducer } from '../../hooks/useInjectReducer';
import { key } from '../../redux/userProfile.selector';
import reducer from '../../redux/reducers/userProfileReducer';
import { useInjectSaga } from '../../hooks/useInjectSaga';
import saga from '../../redux/sagas/userProfileSaga';
import { isNetworkAdminSelector } from '../../hooks/usePermissions/selectors';
import usePermissions from '../../hooks/usePermissions';
import NoAccess from '../../components/atoms/NoAccess';

const Notifications = () => {
  const user = useSelector(userSelector);
  const isNetworkAdmin = useSelector(isNetworkAdminSelector);
  const areJobAlertsEnabled = useSelector(hasJobAlertsEnabledSelector);
  const areSuggestedCompaniesEnabled = useSelector(hasSuggestedCompaniesSelector);
  const {
    notifications: { canManageNotifications },
  } = usePermissions();
  const dispatch = useDispatch();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const {
    email,
    managerEmailOpts: {
      jobSourcesUpdates,
      managerJobsExpiring,
      jbEngagementWeeklyUpdate,
      jbSuggestedCompanies,
      cnHiresReport,
      cnMatchesReport,
    },
  } = user;

  if (!canManageNotifications) {
    return <NoAccess cy="notifications-unavailable" />;
  }
  return (
    <EmailNotificationsPanel
      email={email}
      isNetworkAdmin={isNetworkAdmin}
      areJobAlertsEnabled={areJobAlertsEnabled}
      jobSourcesUpdates={jobSourcesUpdates}
      jbEngagementWeeklyUpdate={jbEngagementWeeklyUpdate}
      cnHiresReport={cnHiresReport}
      cnMatchesReport={cnMatchesReport}
      managerJobsExpiring={managerJobsExpiring}
      areSuggestedCompaniesEnabled={areSuggestedCompaniesEnabled}
      jbSuggestedCompanies={jbSuggestedCompanies}
      onChange={(notification, checked) => {
        const obj = {};
        const keyName = `mail${notification}`;
        obj[keyName] = checked;
        const updatedUser = { ...{ authenticationToken: user.authenticationToken }, ...obj };
        dispatch(loadUpdateUserProfile({ updatedUser }));
      }}
    />
  );
};

export default Notifications;
