import React from 'react';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { AlertTriangle } from 'lucide-react';
import { Button } from '@getro/rombo';
import { trackEvent } from 'helpers/analytics';
import NetworkSchema from 'schemas/network';
import SettingsIcon from '../../../assets/icon-settings.svg';

export const ImportQuality = ({ details, onEdit, network }) => (
  <Flex
    sx={{
      m: '0px auto',
      maxWidth: '440px',
      flexDirection: 'column',
      gap: '8px',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    }}
  >
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '16px',
        p: '32px',
        borderRadius: '8px',
        boxShadow: '0px 1px 4px 0px rgba(27, 36, 50, 0.06), 0px 2px 32px 0px rgba(27, 36, 50, 0.06)',
      }}
    >
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', height: '64px' }}>
        <Text sx={{ color: 'text.dark', fontSize: '20px', lineHeight: '1.2', fontWeight: '600' }}>
          Improve match quality
        </Text>
        <Image src={SettingsIcon} size="64px" />
      </Flex>
      <Text fontSize="14px" lineHeight="1.4">
        Improve the accuracy of your matches by adding the missing details:
      </Text>
      {Object.entries(details)
        .filter(([key]) => !(Array.isArray(details[key].value) ? details[key].value.length : details[key].value))
        .map(([key, value]) => (
          <Box key={key}>
            <Flex sx={{ alignItems: 'center', gap: '8px' }}>
              <Box as={AlertTriangle} size="16px" sx={{ color: '#DE7B02' }} />
              <Text sx={{ color: 'text.main', fontSize: '14px', lineHeight: '1.4' }}>{value.missingMessage}</Text>
            </Flex>
          </Box>
        ))}
      <Button
        onClick={() => {
          onEdit();
          trackEvent('matches:get_better_matches_cta_click', {
            collectionId: network.id,
            origin: 'banner',
            action: 'add_missing_info',
          });
        }}
        sx={{ justifyContent: 'center' }}
      >
        Add criteria to improve matches
      </Button>
    </Flex>
  </Flex>
);

ImportQuality.propTypes = {
  network: NetworkSchema.isRequired,
  details: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
};
