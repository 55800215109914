import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';
import { ChevronLeft, X } from 'lucide-react';
import { Loader as DefaultLoader } from '@getro/rombo';

export const SideModal = ({
  children,
  onClose,
  width,
  goBack,
  headerText,
  disableScroll,
  sx,
  headerRebassProps,
  isLoading,
  loadingComponent: Loader = DefaultLoader,
}) => {
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    document.querySelector('html').scrollTo(0, 0);
    return () => {
      document.getElementsByTagName('body')[0].style.overflow = null;
    };
  }, []);

  return createPortal(
    <Flex
      data-testid="contact-detail-view"
      height="100%"
      width="100%"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Box data-testid="close-button" flexGrow="1" bg="neutral.20" onClick={onClose} sx={{ opacity: 0.8 }} />
      <Box
        bg="neutral.0"
        width={['100%', '100%', 'calc(100% - 40px)']}
        maxWidth={width}
        sx={{
          px: ['16px', '40px'],
          py: ['16px', goBack || headerText ? '24px' : '40px'],
          position: 'relative',
          boxShadow: '-4px 0px 20px rgba(0, 0, 0, 0.08)',
          ...(disableScroll && { overflowY: 'auto' }),
          ...sx,
        }}
      >
        {!isLoading && (
          <>
            <Box onClick={onClose} sx={{ position: 'absolute', right: '40px', cursor: 'pointer' }}>
              <Box as={X} size="24px" sx={{ color: 'neutral.400' }} />
            </Box>
            <>
              {(goBack || headerText) && (
                <Box sx={{ ...headerRebassProps }}>
                  {goBack && (
                    <Flex
                      onClick={goBack}
                      sx={{ gap: '8px', color: 'text.subtle', alignItems: 'center', cursor: 'pointer' }}
                    >
                      <Box as={ChevronLeft} size="16px" />
                      <Box fontSize="12px" as="p" color="text.subtle" lineHeight="10px">
                        Back
                      </Box>
                    </Flex>
                  )}
                  {headerText && (
                    <Box as="h3" color="text.dark" fontSize="20px" lineHeight="32px" fontWeight="600" pb="32px">
                      {headerText}
                    </Box>
                  )}
                </Box>
              )}
            </>
          </>
        )}
        {isLoading ? <Loader /> : children}
      </Box>
    </Flex>,
    document.getElementById('portals'),
  );
};

SideModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  headerText: PropTypes.node,
  width: PropTypes.string.isRequired,
  disableScroll: PropTypes.bool.isRequired,
  sx: PropTypes.object,
  headerRebassProps: PropTypes.object,
  isLoading: PropTypes.bool,
  loadingComponent: PropTypes.element,
};

SideModal.defaultProps = {
  headerText: '',
  disableScroll: true,
  sx: {},
  headerRebassProps: {},
  isLoading: false,
  loadingComponent: DefaultLoader,
};

export default SideModal;
