import { optimizeImage } from './file';

export const transformCompanyData = (companyData) => {
  const { data, included } = companyData;
  const { id, attributes } = data;

  const organizationSocialProfiles = included
    .filter((item) => item.type === 'organization_social_profile')
    .reduce((previous, current) => {
      const { attributes: socialProfileAttributes } = current;
      const { typeId, url } = socialProfileAttributes;
      return { ...previous, [`${typeId}Url`]: url };
    }, {});

  const topics = included
    .filter((item) => item.type === 'topic')
    .map((topic) => {
      const { id: topicId, attributes: topicAttributes } = topic;
      const { name } = topicAttributes;

      return { id: topicId, name };
    });

  const jobFunctions = included
    .filter((item) => item.type === 'job_function')
    .map((jobFunction) => {
      const { id: jobFunctionId, attributes: jobFunctionAttributes } = jobFunction;
      const { name } = jobFunctionAttributes;

      return { id: jobFunctionId, name };
    });

  const locations = included
    .filter((item) => item.type === 'location')
    .map((location) => {
      const { id: locationId, attributes: locationAttributes } = location;
      const { name, placeId } = locationAttributes;

      return { id: locationId, name, placeId };
    });

  const managers = included
    .filter((item) => item.type === 'manager')
    .map((manager) => {
      const { id: managerId, attributes: managerAttributes } = manager;
      return {
        id: parseInt(managerId, 10),
        ...managerAttributes,
      };
    });

  const organizationCareersPages = included
    .filter((item) => item.type === 'organization_career_page')
    .map((organizationCareerPage) => {
      const { id: ocpId, attributes: ocpAttributes } = organizationCareerPage;
      return {
        id: parseInt(ocpId, 10),
        ...ocpAttributes,
      };
    });

  const { logoUrl, ...otherAttributes } = attributes;

  return {
    id: parseInt(id, 10),
    ...otherAttributes,
    ...organizationSocialProfiles,
    logoUrl: optimizeImage(logoUrl),
    topics,
    jobFunctions,
    locations,
    managers,
    organizationCareersPages,
  };
};

export const mapOrganizationHeadCount = (headCount) => {
  switch (headCount) {
    case '1-10':
      return 1;
    case '11-50':
      return 2;
    case '51-200':
      return 3;
    case '201-1000':
      return 4;
    case '10001-5000':
      return 5;
    default:
      return 6;
  }
};

export const reverseMapOrganizationHeadCount = (value) => {
  switch (value) {
    case 1:
      return '1 - 10';
    case 2:
      return '11 - 50';
    case 3:
      return '51 - 200';
    case 4:
      return '201 - 1000';
    case 5:
      return '1001 - 5000';
    default:
      return '5001+';
  }
};

export const mapCompanyHeadCountIntervals = (data, format = false) => {
  const value = parseInt(data, 10);
  let result;

  switch (true) {
    case value < 11:
      result = '1 - 10';
      break;
    case value < 51:
      result = '11 - 50';
      break;
    case value < 201:
      result = '51 - 200';
      break;
    case value < 501:
      result = '201 - 500';
      break;
    case value < 5001:
      result = '1001 - 5000';
      break;
    default:
      result = '50001+';
  }

  if (format) {
    return result.replaceAll(' ', '');
  }

  return result;
};

export const mapApproxEmployees = (data) => {
  const value = parseInt(data, 10);
  return mapOrganizationHeadCount(mapCompanyHeadCountIntervals(value, true));
};

export const getCompanySize = (headCount) =>
  typeof headCount === 'string' ? mapOrganizationHeadCount(headCount) : headCount;

export const getCompanySizeLabel = (headCount) =>
  typeof headCount === 'number' ? reverseMapOrganizationHeadCount(headCount) : headCount?.split('-').join(' - ');
