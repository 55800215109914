import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Checkbox } from '@getro/rombo';
import TableActionMenu from './tableActionItems';

export const TableItem = ({
  item,
  isSticky,
  row,
  canSelect,
  onSelect,
  rowActions,
  disablePadding,
  isFirst,
  isLast,
  fixedColumn,
}) => {
  const id = `${row.id}-action-menu`;

  const sx = {
    ...(disablePadding ? { p: '0 !important' } : {}),
    position: 'relative',
    top: 0,
  };

  if (isFirst && fixedColumn === 'first') {
    sx.position = ['relative', 'sticky'];
    sx.left = 0;
  } else if (isLast && fixedColumn === 'last') {
    sx.position = ['relative', 'sticky'];
    sx.right = 0;
  }

  return (
    <Box
      as="td"
      sx={{
        ...sx,
        bg: row.selected ? 'purple.100' : 'white',
        zIndex: 1,
        cursor: 'initial',
        '&:hover .table-item-actions': {
          visibility: 'visible',
        },
      }}
    >
      <Flex sx={{ gap: '8px', height: '100%' }}>
        {isSticky && canSelect && (
          <Box sx={{ pt: '4px', minWidth: '16px' }}>
            <Checkbox
              name="job"
              value={row.id}
              checked={row.selected}
              onChange={(e) => onSelect(row, e.target.checked)}
              containerProps={{ width: '16px', minWidth: '16px' }}
            />
          </Box>
        )}
        <Box sx={{ gap: '8px', flex: 1 }} display="flex" alignItems="flex-start" justifyContent="space-between">
          {item}
        </Box>
        {isSticky && (
          <Box className="table-item-actions" minWidth="24px" minHeight="24px" id={id} sx={{ visibility: 'hidden' }}>
            <TableActionMenu row={row} isLast={isSticky} rowActions={rowActions} />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

TableItem.propTypes = {
  item: PropTypes.node,
  isSticky: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  canSelect: PropTypes.bool.isRequired,
  rowActions: PropTypes.object,
  disablePadding: PropTypes.bool,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  fixedColumn: PropTypes.oneOf(['first', 'last']),
};

TableItem.defaultProps = {
  item: null,
  rowActions: {},
  disablePadding: false,
  fixedColumn: 'first',
};
