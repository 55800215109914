import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const JobAppearanceNavigation = ({ tabsOrder, mode, showNumberInNav, companiesCount, jobsCount, showAlert }) => {
  const tab = tabsOrder.split(',').map((item) => item.trim());
  const tabs = {
    jobs: 'Search jobs',
    companies: 'Explore companies',
    talent_network: 'Talent network',
  };

  const tabsMobile = {
    jobs: 'Jobs',
    companies: 'Companies',
    talent_network: 'Talent',
  };

  return (
    <Flex
      sx={{
        bg: 'white',
        justifyContent: 'center',
        boxShadow: '0 1px 0 0 #e5e5e5',
        '*': {
          fontFamily:
            '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"',
        },
      }}
    >
      <Box bg="white" width="100%" maxWidth="1100px">
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            px: mode === 'mobile' ? '0px' : '32px',
            pr: mode === 'mobile' ? '16px' : '32px',
            maxWidth: '1100px',
            height: '50px',
          }}
        >
          <Flex
            sx={{
              color: 'text.dark',
              fontSize: '16px',
              gap: mode === 'mobile' ? '0px' : '16px',
              '& > div': {
                py: '16px',
                px: mode === 'mobile' ? '8px' : '16px',
              },
            }}
          >
            {tab.map((item, index) => (
              <Box
                sx={{
                  boxShadow: index === 0 ? '0px -2px 0px 0px #4000F9 inset' : 'none',
                  color: index === 0 ? 'neutral.900' : 'neutral.700',
                  fontWeight: index === 0 ? '600' : '400',
                }}
                key={item}
              >
                {(mode === 'desktop' ? tabs : tabsMobile)[item]}
                <Box
                  as="span"
                  sx={{
                    color: 'neutral.500',
                    fontSize: '14px',
                  }}
                >
                  {showNumberInNav && item === 'jobs' && <>&nbsp;{jobsCount.toLocaleString('en-US')}</>}
                  {showNumberInNav && item === 'companies' && <>&nbsp;{companiesCount.toLocaleString('en-US')}</>}
                </Box>
              </Box>
            ))}
          </Flex>
          {showAlert && (
            <Flex sx={{ gap: '8px', alignItems: 'center' }}>
              <Box sx={{ color: 'text.main', fontSize: ['14px', '14px'] }}>
                {mode === 'mobile' ? 'My alerts' : 'My job alerts'}
              </Box>
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

JobAppearanceNavigation.propTypes = {
  tabsOrder: PropTypes.string,
  mode: PropTypes.string.isRequired,
  showNumberInNav: PropTypes.bool.isRequired,
  jobsCount: PropTypes.number.isRequired,
  companiesCount: PropTypes.number.isRequired,
  showAlert: PropTypes.bool.isRequired,
};

JobAppearanceNavigation.defaultProps = {
  tabsOrder: 'jobs, companies',
};

export default JobAppearanceNavigation;
