import React, { useEffect, useState } from 'react';
import { Message } from '@getro/rombo';
import { Formik, Form } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import { openChat } from 'helpers/supportChat';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { SidebarOpen } from 'lucide-react';
import useOnClickOutside from 'hooks/useClickOutside';
import { GeneralJobBoardSettings } from './generalJobBoardSettings';
import { HeroContentSettings } from './heroContentSettings';
import { HeroBackgroundSettings } from './heroBackgroundSettings';
import { PublishChanges } from '../../../components/atoms/publishChanges';

export const SettingsSidebar = ({
  initialValues,
  customHeroAndAD,
  standardDesign,
  tabOrderOptionsToUse,
  handleSubmit,
  url,
  heroOptions,
  advancedDesign,
  isGCOrTN,
  onFormValuesChange,
}) => {
  const screenToShowSideBarByDefault = 1440;
  const [isSidebarVisible, setIsSidebarVisible] = useState(document.body.clientWidth > screenToShowSideBarByDefault);
  const ref = useOnClickOutside(() => setIsSidebarVisible(document.body.clientWidth > screenToShowSideBarByDefault));

  useEffect(() => {
    const handleResize = (entries) => {
      entries.forEach((entry) => setIsSidebarVisible(entry.contentRect.width < 1400));
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
        sx={{
          display: isSidebarVisible ? 'none' : 'initial',
          position: 'fixed',
          zIndex: 5,
          width: '48px',
          height: '48px',
          top: '64px',
          left: 0,
          borderRadius: '0px 4px 4px 0px',
          bg: 'white',
          cursor: 'pointer',
        }}
      >
        <Box as={SidebarOpen} size="16px" />
      </Flex>
      <Box
        ref={ref}
        sx={{
          '@media (max-width: 1400px)': {
            left: isSidebarVisible ? '0px' : '-280px',
            position: 'fixed',
          },
          zIndex: 20,
          bg: 'white',
          transition: 'left 0.1s linear',
          width: '280px',
          minWidth: '280px',
          left: '0px',
          p: '16px',
          pb: '80px',
          overflowY: 'scroll',
          height: '100%',
          minHeight: '100vh',
          maxHeight: '100vh',
          flexDirection: 'column',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            color: 'red',
            width: '3px',
          },
          '&::-webkit-scrollbar-track': {},
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
          },
          '&::-webkit-scrollbar-bar': {
            width: '12px',
          },
          scrollbarWidth: '3px',
        }}
      >
        <Formik
          isInitialValid={false}
          enableReinitialize
          validateOnMount
          validate={(values) => {
            onFormValuesChange(values);
            return {};
          }}
          onSubmit={(values) => {
            handleSubmit(values).then(() => {
              toast(<PublishChanges domain={url} />, {
                icon: 'success',
                id: 'job-board-settings-updated',
              });
            });
          }}
          initialValues={initialValues}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box sx={{ maxWidth: '280x', margin: '0px auto', mb: '48px' }}>
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '32px' }}>
                  <GeneralJobBoardSettings
                    heroOptions={heroOptions}
                    values={values}
                    tabOrderOptionsToUse={tabOrderOptionsToUse}
                  />
                  {customHeroAndAD && (
                    <Box sx={{ maxWidth: '600px', margin: '0px auto', width: '100%' }}>
                      <Message
                        variant="info"
                        sx={{
                          p: '16px',
                          bg: 'blue.50',
                          'div:first-child': {
                            display: 'none',
                          },
                        }}
                      >
                        Your job board features a highly customized advanced design. For updates, please{' '}
                        <Box as="span" sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={openChat}>
                          contact us
                        </Box>
                        .
                      </Message>
                    </Box>
                  )}
                  {standardDesign && (
                    <>
                      <HeroContentSettings
                        isAdvancedDesign={advancedDesign}
                        isTalentNetwork={isGCOrTN}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                      <HeroBackgroundSettings setFieldValue={setFieldValue} values={values} />
                    </>
                  )}
                </Box>
                <Box as="button" hidden type="submit" size="small" id="job-appearance-submit" />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

SettingsSidebar.propTypes = {
  initialValues: PropTypes.object.isRequired,
  customHeroAndAD: PropTypes.bool.isRequired,
  standardDesign: PropTypes.bool.isRequired,
  tabOrderOptionsToUse: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  heroOptions: PropTypes.array.isRequired,
  advancedDesign: PropTypes.bool.isRequired,
  isGCOrTN: PropTypes.bool.isRequired,
  onFormValuesChange: PropTypes.func.isRequired,
};
