import React from 'react';
import toast from 'react-hot-toast';
import { Box } from 'rebass/styled-components';
import strings from 'strings';

import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import usePageHook from '../../../hooks/usePageHook';
import { useGetTalentNetworkSettingsQuery, useUpdateTalentNetworkMutation } from '../../../services/network';
import TalentNetwork from '../contacts/TalentNetwork';
import TalentNetworkSettings from '../contacts/TalentNetworkSettings';

const pageTitle = 'Talent network';

export const JobBoardTalentNetwork = () => {
  const { data, isLoading, isError } = useGetTalentNetworkSettingsQuery();
  const [update, { isLoading: isUpdating }] = useUpdateTalentNetworkMutation();

  usePageHook(pageTitle);

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader
        title="Talent network"
        description="Grow your network passively, allowing professionals to signal their interest in working at one of your companies."
      />
      <Box pt="32px">
        <TalentNetwork
          toggleTalentNetwork={() => {
            update({ tnHidden: !data.tnHidden }).then(() => {
              toast('Changes saved successfully', { icon: 'success' });
            });
          }}
          tnHidden={!data?.tnHidden}
        />
        {!isLoading && !data?.tnHidden && (
          <TalentNetworkSettings
            isUpdating={isUpdating}
            update={(values) => {
              update(values).then(() => {
                toast('Changes saved successfully', { icon: 'success' });
              });
            }}
            talentNetwork={data}
            error={isError ? strings.genericError : ''}
          />
        )}
      </Box>
    </Box>
  );
};
