import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ExternalLink, Settings2 } from 'lucide-react';
import { Virtuoso } from 'react-virtuoso';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { useParams, Switch, Route, useHistory } from 'react-router-dom';
import moment from 'moment';

import { useGetSharedListMatchesQuery } from 'services/contacts';
import { Button, Message } from '@getro/rombo';
import { useLazyGetMatchStatusQuery } from 'services/matches';
import { useFetchContacts } from 'pages/contactsExtended/hooks/useFetchContacts';
import { getCompanySizeLabel } from 'helpers/organizations';
import { ContactCard } from '../../../components/organisms/contactCard';
import { addProtocolToUrl } from '../../../helpers/urlHelpers';
import usePageHook from '../../../hooks/usePageHook';
import { activeNetworkSelector } from '../../../redux/selectors';
import { CreateList } from '../../list/createList';
import { GeneratingMatches } from './generatingMatches';
import JobDetail from './jobDetail';
import { ContentSkeleton } from './skeletons/content';
import { AiContactDetail } from './aiContactDetail';
import { useJobDetail } from './hooks/useJobDetail';
import { ImportQuality } from './importQuality';
import { LessContacts } from './lessContacts';
import { NoMatches } from './noMatches';

export const AiMatches = ({ listName }) => {
  const { listId } = useParams();
  const { data, isLoading, refetch } = useGetSharedListMatchesQuery({ listId });
  const [getMatchStatus] = useLazyGetMatchStatusQuery();
  const activeNetwork = useSelector(activeNetworkSelector);
  const history = useHistory();
  const pollingRef = useRef(null);
  const { data: allContactsData, queryParams } = useFetchContacts(true);

  useEffect(() => {
    if (!isLoading) {
      window?.CommandBar?.trackEvent('matches_search_loaded', {});
    }
  }, [isLoading]);

  usePageHook(listName);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const cleanup = () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };

    if (!data?.matchingProject || data?.matchingProject?.status === 'active') {
      cleanup();

      return () => {};
    }

    pollingRef.current = setInterval(() => {
      getMatchStatus({
        matchListId: data?.matchingProject.id,
      }).then((res) => {
        if (res?.data?.status === 'active') {
          refetch();
          cleanup();
        }
      });
    }, 3000);

    return cleanup;
  }, [data?.matchingProject, getMatchStatus, listId, refetch]);

  const matches = data?.matchingProject?.matches ?? [];

  const matchesCount = matches.length;
  const locations = data?.matchingProject?.job?.locations ?? [];
  const skills = data?.matchingProject?.job?.skills ?? [];
  const companyStage = data?.matchingProject?.organization?.stage;
  const companySize = data?.matchingProject?.organization?.headCount
    ? getCompanySizeLabel(data?.matchingProject?.organization?.headCount)
    : '';
  const isPrivate = data?.matchingProject?.privateJob;

  const { jobName, missingMatchingDetails, ...details } = useJobDetail({
    locations,
    skills,
    industryTags: data?.matchingProject?.organization?.industryTags ?? [],
    organizationName: data?.matchingProject?.organization?.name,
    job: data?.matchingProject?.job,
    companyStage,
    companySize,
  });

  const isGeneratingMatches = data?.matchingProject?.status === 'generating_matches';

  const onEdit = () => {
    history.push(`/networks/${activeNetwork.slug}/list/${listId}/ai-matches/lists/settings`, {
      matchType: 'job',
      step: 'contacts:matchesSettings',
    });
  };

  const showLessContacts =
    allContactsData?.meta?.contactsTotal < 5000 && data?.matchingProject?.matches.length < 5 && !missingMatchingDetails;

  return (
    <Box height="inherit">
      <Box height="inherit">
        <Flex flexDirection={['column', 'column', 'row']} sx={{ gap: '24px', height: 'inherit' }}>
          <Box width="280px" sx={{ borderRight: '1px solid', borderColor: 'border.subtle', pr: '16px' }}>
            <JobDetail
              isPrivate={isPrivate}
              isLoading={isLoading}
              details={details}
              jobName={jobName}
              missingMatchingDetails={missingMatchingDetails}
              onEdit={onEdit}
            />
            {!isLoading && !isPrivate && (
              <Flex sx={{ flexDirection: 'column', gap: '16px', mt: '24px' }}>
                {data?.matchingProject?.job?.url && (
                  <Box>
                    <Link
                      display="flex"
                      alignItems="center"
                      color="text.main"
                      href={
                        data.matchingProject.job.description
                          ? addProtocolToUrl(
                              `${activeNetwork.url}/companies/${data.matchingProject.organization?.slug}/jobs/${data.matchingProject.job.slug}#content`,
                            )
                          : addProtocolToUrl(data.matchingProject.job.url)
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '8px' }}
                    >
                      <Box as={ExternalLink} width="16px" height="16px" aria-hidden="true" />
                      <Text fontSize="14px" fontWeight="medium">
                        See job post
                      </Text>
                    </Link>
                  </Box>
                )}
                <Button
                  sx={{ border: 'none', p: 0, '&:hover': { bg: 'transparent' }, height: 'fit-content' }}
                  icon={Settings2}
                  variant="secondary"
                  size="small"
                  onClick={onEdit}
                >
                  Change job
                </Button>
              </Flex>
            )}
            {!isLoading && (
              <Flex
                sx={{
                  mt: '24px',
                  gap: '4px',
                  flexDirection: 'column',
                  fontSize: '12px',
                  color: 'text.subtle',
                }}
              >
                <Box sx={{ height: '1px', bg: 'border.subtle', mb: '16px' }} />
                <Text>
                  {Number(allContactsData?.meta?.contactsTotal || 0).toLocaleString()}{' '}
                  {pluralize('contacts', allContactsData?.meta?.contactsTotal)} analyzed
                </Text>
                <Text>
                  {matchesCount} {pluralize('matches', matchesCount)} found
                </Text>
              </Flex>
            )}
          </Box>
          <Box flex="1">
            {!isLoading && !isPrivate && data?.matchingProject?.job?.status !== 'active' && (
              <Box mb="24px">
                <Message>
                  This job has been closed and updates to these matches have stopped. Last update was on{' '}
                  {moment(data?.matchingProject?.job?.closedAt).format('D MMM, YYYY')}.
                </Message>
              </Box>
            )}
            {isLoading && <ContentSkeleton />}
            {!isLoading && !isGeneratingMatches && (
              <>
                {missingMatchingDetails && <ImportQuality network={activeNetwork} onEdit={onEdit} details={details} />}
                {showLessContacts && (
                  <LessContacts
                    activeNetwork={activeNetwork}
                    matchesCount={data.matchingProject.matches.length}
                    contactsCount={allContactsData?.meta?.contactsTotal}
                    limit={activeNetwork.contactsLimit}
                  />
                )}

                {matches.length > 0 && (
                  <>
                    <Box flex="1">
                      <Text data-testid="matches-count" fontSize="12px">{`Showing ${matchesCount} ${pluralize(
                        'match',
                        matchesCount,
                      )}`}</Text>
                      <Flex
                        as={Virtuoso}
                        flex="1"
                        data={matches}
                        increaseViewportBy={400}
                        useWindowScroll
                        itemContent={(index, match) => (
                          <ContactCard
                            match={match}
                            index={index}
                            contact={match.contact}
                            type="matches"
                            page="list:matches"
                            notSelectable
                            matchListInfo={{
                              id: data.matchingProject?.id,
                              job: data.matchingProject?.job || {},
                              organization: data.matchingProject?.organization,
                              name: data?.name,
                              listId,
                            }}
                            mb="0px"
                            showAddToLisButton
                            addedToSharedList={match.addedToSharedList}
                            list={{
                              id: listId,
                              name: data?.name,
                            }}
                            showDetail={() => {
                              history.push(
                                `/networks/${activeNetwork.slug}/list/${listId}/ai-matches/${match.contact.id}`,
                              );
                            }}
                          />
                        )}
                      />
                    </Box>
                  </>
                )}
                {!isLoading && !matches.length && !missingMatchingDetails && !showLessContacts && (
                  <NoMatches onEdit={onEdit} activeNetwork={activeNetwork} />
                )}
              </>
            )}
            {isGeneratingMatches && (
              <Box flex="1">
                <GeneratingMatches
                  missingMatchingDetails={missingMatchingDetails}
                  status={data?.matchingProject?.status}
                  contactCount={allContactsData?.meta?.contactsTotal}
                />
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
      <Switch>
        <Route exact path={`/networks/${activeNetwork.slug}/list/:listId/ai-matches/lists/settings`}>
          <CreateList
            goBack={() => {
              history.push(`/networks/${activeNetwork.slug}/list/${listId}/ai-matches${history.location.search}`);
            }}
          />
        </Route>
        <Route path={[`/networks/${activeNetwork.slug}/list/:listId/ai-matches/:contactId`]}>
          <AiContactDetail queryParams={queryParams} data={data} isLoading={isLoading} />
        </Route>
      </Switch>
    </Box>
  );
};

AiMatches.propTypes = {
  listName: PropTypes.string,
};

AiMatches.defaultProps = {
  listName: null,
};
