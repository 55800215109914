import React from 'react';
import { Plus, UserCheck, Wand2 } from 'lucide-react';
import { Box, Flex } from 'rebass/styled-components';
import { NavLink, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageHeader from '../../../components/atoms/pageHeader';
import { Tabs } from '../../../components/atoms/tabs';
import { ContactList } from '../contactList';
import { AiMatches } from '../aiContacts';
import { AddContacts } from '../addContacts';

const ContactsLayout = ({ sharedList, activeNetwork, listId, listName }) => {
  const isJobList = Boolean(Object.keys(sharedList?.matchingProject ?? {}).length);
  const autoUpdate = sharedList?.smartOptions?.includes('auto_update');
  const tabs = [
    {
      id: 'tab-contacts-in-list',
      title: `Contacts in list · ${sharedList?.contactsCount ?? '0'} `,
      to: `/networks/${activeNetwork.slug}/list/${listId}/contacts`,
      icon: UserCheck,
      show: true,
    },
    {
      id: 'tab-ai-matches',
      title: 'AI matches',
      to: `/networks/${activeNetwork.slug}/list/${listId}/ai-matches`,
      icon: Wand2,
      show: isJobList,
    },
    {
      id: 'tab-add-contacts',
      title: 'Add contacts',
      to: `/networks/${activeNetwork.slug}/list/${listId}/add-contacts`,
      icon: Plus,
      show: true,
    },
  ];

  return (
    <>
      <Box px="16px" pt="24px">
        {(sharedList?.description || autoUpdate) && (
          <PageHeader description={sharedList?.description} autoUpdate={autoUpdate} />
        )}
        <Tabs
          tabs={tabs
            .filter(({ show }) => show)
            .map(({ id, title, to, icon }) => (
              <Box as="li" key={id}>
                <Flex
                  data-testid={id}
                  as={NavLink}
                  to={to}
                  sx={{
                    gap: '8px',
                    textDecoration: 'none',
                    alignItems: 'flex-start',
                    fontSize: '14px',
                    lineHeight: '16px',
                    pb: '12px',
                    height: '100%',
                  }}
                >
                  <Box as={icon} size="16px" />
                  <Box>{title}</Box>
                </Flex>
              </Box>
            ))}
        />
      </Box>
      <Box px="16px" width="100%" maxWidth="1600px" mx="auto">
        <Switch>
          <Route path={`/networks/${activeNetwork.slug}/list/:listId/contacts`}>
            <ContactList isJobList={isJobList} sharedList={sharedList} />
          </Route>
          <Route path={`/networks/${activeNetwork.slug}/list/:listId/ai-matches`}>
            <AiMatches matchListId={sharedList?.matchingProject?.id} listName={listName} />
          </Route>
          <Route path={`/networks/${activeNetwork.slug}/list/:listId/add-contacts`}>
            <AddContacts sharedList={sharedList} />
          </Route>
        </Switch>
      </Box>
    </>
  );
};

ContactsLayout.propTypes = {
  sharedList: PropTypes.object.isRequired,
  activeNetwork: PropTypes.object.isRequired,
  listId: PropTypes.string.isRequired,
  listName: PropTypes.string,
};

ContactsLayout.defaultProps = {
  listName: '',
};

export default ContactsLayout;
