import React from 'react';
import { Button, Loader, MessageBlock } from '@getro/rombo';
import { FocusLayout } from 'components/layout/focus';
import { Box, Flex } from 'rebass/styled-components';
import { useJobBoardAppearance } from './hooks/useJobBoardAppearance';
import { SettingsSidebar } from './settingsSidebar';
import { SettingsPreview } from './settingsPreview';
import { useLeaveBeforeSaving } from './hooks/useLeaveBeforeSaving';

export const JobBoardAppearance = () => {
  const {
    initialValues,
    slug,
    isLoading,
    customHeroAndAD,
    standardDesign,
    tabOrderOptionsToUse,
    handleSubmit,
    isUpdating,
    url,
    heroOptions,
    advancedDesign,
    isGCOrTN,
    network,
    onFormValuesChange,
    formData,
    noCustomHeroAndAD,
    tnSettings,
    dataHasChanged,
    advancedDesignSettings,
    showHeroInNav,
    customHero,
  } = useJobBoardAppearance();

  useLeaveBeforeSaving(dataHasChanged);

  return (
    <FocusLayout
      backUrl={`/networks/${slug}/settings/job-board-appearance`}
      title="Job board appearance"
      bodyProps={{
        m: '0px',
        mt: '0px',
        width: '100%',
      }}
      sideHeader={
        <Flex alignItems="center" display={['none', 'none', 'flex']}>
          <Button
            type="submit"
            size="small"
            loading={isUpdating}
            onClick={() => document.querySelector('#job-appearance-submit').click()}
          >
            Save and publish
          </Button>
        </Flex>
      }
    >
      {isLoading && (
        <Flex pt="40px">
          <Loader isLoading={isLoading} />
        </Flex>
      )}
      {!isLoading && network && (
        <>
          <MessageBlock
            sx={{
              display: ['flex', 'flex', 'none'],
              mt: '48px',
              '& > div:nth-child(2)': {
                color: 'text.dark',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '25px',
              },
            }}
            title="Not available on small screens"
          >
            <Box sx={{ maxWidth: '326px', textAlign: 'center' }}>
              <Box>Open it on a bigger screen and/or resize the window.</Box>
            </Box>
          </MessageBlock>
          <Box sx={{ display: ['none', 'none', 'flex'] }}>
            <SettingsSidebar
              initialValues={initialValues}
              slug={slug}
              customHeroAndAD={customHeroAndAD}
              standardDesign={standardDesign}
              tabOrderOptionsToUse={tabOrderOptionsToUse}
              handleSubmit={handleSubmit}
              url={url}
              heroOptions={heroOptions}
              advancedDesign={advancedDesign}
              isGCOrTN={isGCOrTN}
              onFormValuesChange={onFormValuesChange}
            />
            <SettingsPreview
              advancedDesignSettings={advancedDesignSettings}
              noCustomHeroAndAD={noCustomHeroAndAD}
              customHeroAndAD={customHeroAndAD}
              standardDesign={standardDesign}
              network={network}
              tnSettings={tnSettings}
              customHero={customHero}
              showHeroInNav={showHeroInNav}
              {...formData}
            />
          </Box>
        </>
      )}
    </FocusLayout>
  );
};

export default JobBoardAppearance;
