import Login from '../pages/Login';
import { LogOut } from '../pages/logOut';
import RecoverPassword from '../pages/RecoverPassword';
import { ContactsSharedListsSlackSubscribe } from '../pages/contactsSharedListsSlackSubscribe';
import { ContactsSharedListsUnsubscribe } from '../pages/contactsSharedListsUnsubscribe';

export const sessionRoutes = {
  login: {
    path: '/login',
    component: Login,
    name: 'Login',
    visibility: ['public'],
  },
  logout: {
    path: '/logout',
    component: LogOut,
    name: 'Logout',
    visibility: ['public', 'onboarding', 'user'],
  },
  recoverPassword: {
    path: '/recover-password',
    component: RecoverPassword,
    name: 'Recover password',
    visibility: ['public', 'onboarding', 'user'],
  },
  sharedListsSlackSubscribe: {
    path: '/shared-lists/:slug/slack/subscribe',
    component: ContactsSharedListsSlackSubscribe,
    name: 'Shared lists subscribe',
    visibility: ['public', 'onboarding', 'user'],
  },
  sharedListsUnsubscribe: {
    path: '/shared-lists/:slug/unsubscribe',
    component: ContactsSharedListsUnsubscribe,
    name: 'Shared lists unsubscribe',
    visibility: ['public', 'onboarding', 'user'],
  },
};
