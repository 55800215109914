export const useListTitle = ({ isEdit, step }) => {
  let title = isEdit ? 'List settings' : 'Create list';

  if (step === 'contacts:matches') {
    title = 'Set up AI matches';
  }

  if (step === 'contacts:matchesSettings') {
    title = 'AI matches settings';
  }

  if (step === 'contacts:autoUpdate') {
    title = 'Set up Auto-update';
  }

  if (step === 'companies:create') {
    title = 'Create companies list';
  }

  return title;
};
