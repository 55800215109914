import React from 'react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { CompaniesList } from '../companiesList';

const CompaniesLayout = ({ sharedList, isLoadingSharedList }) => (
  <Box pt="24px" width="100%" ml="-1px">
    <CompaniesList sharedList={sharedList} isLoadingSharedList={isLoadingSharedList} />
  </Box>
);

CompaniesLayout.propTypes = {
  sharedList: PropTypes.object,
  isLoadingSharedList: PropTypes.bool,
};

CompaniesLayout.defaultProps = {
  sharedList: {},
  isLoadingSharedList: false,
};

export default CompaniesLayout;
