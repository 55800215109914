import { useState } from 'react';
import copy from 'copy-to-clipboard';
import strings from 'strings';
import toast from 'react-hot-toast';

import { trackEvent } from '../../../helpers/analytics';
import { useDeleteContactMutation, useRemoveContactFromSharedListMutation } from '../../../services/contacts';

const useContactActionMenu = ({ contact, queryParams, network, companyId, sharedList }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRefreshModal, setOpenRefreshModal] = useState(false);
  const [removeContactFromSharedList, { isLoading: isLoadingExcludeContact }] =
    useRemoveContactFromSharedListMutation();
  const [deleteContact, { isLoading: isLoadingDelete }] = useDeleteContactMutation();

  const onCopyContactLink = () => {
    copy(
      `${window.location.protocol}//${window.location.hostname}/app/networks/${network.slug}/list/${queryParams.listId}/contacts/${contact.id}`,
    );
    toast('Contact profile URL copied', { icon: 'success', id: 'copy-contact-to-clipboard' });
  };

  const onExcludeContactFromList = () => {
    if (!isLoadingExcludeContact && contact?.id && queryParams.listId) {
      removeContactFromSharedList({
        list: { id: queryParams.listId, kind: sharedList.kind },
        contactId: contact.id,
        companyId,
      });

      trackEvent('contacts:list:remove_contact_single', {
        listId: queryParams.listId,
        collectionId: network.id,
        origin: 'table_contact_actions',
        page: 'companies_list',
      });

      toast('Contact excluded', { icon: 'success', id: 'delete-contact-from-list-success' });
    }
  };

  const onDeleteContact = async () => {
    const { error } = await deleteContact({
      list: { id: queryParams.listId, kind: sharedList.kind },
      contactId: contact.id,
      queryParams,
      companyId,
    });

    if (error) {
      toast(strings.genericError, { icon: 'error', id: 'delete-contact-error' });
      return;
    }

    trackEvent('contacts:delete_contacts', {
      collectionId: network.id,
      numberOfDeletedContacts: 1,
      method: 'single_delete',
      origin: queryParams.listId ? 'list' : 'contacts',
      contactId: contact.id,
      enriched: contact.enrichmentStatus === 'enriched' ? 'true' : 'false',
      contactSource: null,
    });

    setOpenDeleteModal(false);
    toast('Contact deleted', { icon: 'success', id: 'delete-contact-success' });
  };

  return {
    onCopyContactLink,
    openDeleteModal,
    setOpenDeleteModal,
    isLoadingDelete,
    onDeleteContact,
    openRefreshModal,
    setOpenRefreshModal,
    trackRefreshEvent: () => {
      trackEvent('contacts:refresh_profile_click', {
        collectionId: network.id,
        contactId: contact.id,
        linkedinHandle: contact.linkedinHandle,
        page: 'companies_list',
        informationDaysOld: contact.enrichedProfileAgeInDays,
        contactEnriched: contact.enrichmentStatus === 'enriched',
        experiencesCount: contact.contactWorkExperiences.length,
      });
    },
    onExcludeContactFromList,
    isLoadingExcludeContact,
  };
};

export default useContactActionMenu;
