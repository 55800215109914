import React from 'react';
import MainLayout from '../components/layout/Main';

const withMainLayout = (Component, { containerProps } = {}) => {
  const Wrapped = ({ ...params }) => (
    <MainLayout containerProps={containerProps}>
      <Component {...params} />
    </MainLayout>
  );
  Wrapped.displayName = `withMainLayout(${Component.displayName || Component.name || 'Component'})`;
  return Wrapped;
};

export default withMainLayout;
