import { createApi } from '@reduxjs/toolkit/query/react';
import { get } from 'api';

export const locationsApi = createApi({
  reducerPath: 'locationsApi',
  tagTypes: ['locations'],
  endpoints: (builder) => ({
    getLocationsFromGoogle: builder.query({
      async queryFn(params) {
        try {
          const { data } = await get('/locations/search/google', {
            params,
          });
          return { data };
        } catch (axiosError) {
          const err = axiosError;
          return {
            error: {
              status: err.response?.status,
              data: err.response?.data || err.message,
            },
          };
        }
      },

      providesTags: ['locations'],
      transformResponse: ({ items }) =>
        items.map(({ placeId, description }) => ({
          value: placeId,
          label: description,
        })),
    }),
  }),
});

export const { useLazyGetLocationsFromGoogleQuery } = locationsApi;
