import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ProfilePicture, SocialIcons, Tag } from '@getro/rombo';
import capitalize from 'helpers/capitalize';
import { mapCompanyHeadCountIntervals } from 'helpers/organizations';
import NetworkSchema from '../../../schemas/network';
import { mapCompanyFundType } from '../../../services/organizations/organizationFundTypes';

export const InfoView = ({ network, company, socials, logoUrl }) => {
  const { jobBoardFilters } = network;
  const { stageFilter, industryTagsFilter, companySizeFilter } = jobBoardFilters;

  const hasIndustryTags = useMemo(
    () => industryTagsFilter && company.industryTags?.length > 0,
    [industryTagsFilter, company.industryTags?.length],
  );

  const hasStageTags = useMemo(() => stageFilter && company.stage, [company.stage, stageFilter]);

  const hasCompanySizeTags = useMemo(
    () => companySizeFilter && company.approxEmployees,
    [company.approxEmployees, companySizeFilter],
  );

  const hasTopics = useMemo(() => company.topics?.length > 0, [company.topics?.length]);

  const hasTags = useMemo(
    () => hasIndustryTags || hasStageTags || hasCompanySizeTags || hasTopics,
    [hasCompanySizeTags, hasIndustryTags, hasStageTags, hasTopics],
  );

  return (
    <Flex flexWrap="wrap" sx={{ gap: '24px' }}>
      <Box minWidth="64px">
        <ProfilePicture variant="square" name={company.name} imageUrl={logoUrl} width="64px" height="64px" />
      </Box>
      <Box width="calc(100% - 88px)" flexBasis="330px" flexGrow="1">
        <Flex flexWrap="wrap" marginBottom="3" flexDirection="column" sx={{ gap: '8px' }}>
          <Flex flexWrap="wrap" sx={{ gap: '8px' }}>
            {company.name && (
              <Text as="h1" fontSize="3" fontWeight="semibold">
                {company.name}
              </Text>
            )}
            {!!company.domain && <Text color="text.main">{company.domain}</Text>}
          </Flex>
          {company?.locations?.length > 0 && (
            <Text fontSize="1" color="text.subtle">
              {company.locations.map((l) => l.name).join(` · `)}
            </Text>
          )}
          <Flex color="text.subtle" alignItems="center" sx={{ gap: '8px' }}>
            {company.founded && <Text fontSize="1">Founded on {company.founded}</Text>}
          </Flex>
        </Flex>
        {hasTags && (
          <Flex sx={{ mb: '8px', rowGap: '8px', flexWrap: 'wrap' }}>
            {hasIndustryTags && (
              <>
                {company.industryTags.map((tag) => (
                  <Tag key={tag} variant="transparent">
                    {capitalize(tag)}
                  </Tag>
                ))}
              </>
            )}
            {hasCompanySizeTags && (
              <Tag key={company.approxEmployees} variant="transparent">
                {mapCompanyHeadCountIntervals(company.approxEmployees)}
              </Tag>
            )}
            {hasStageTags && (
              <Tag key={company.stage} variant="transparent">
                {mapCompanyFundType(company.stage)}
              </Tag>
            )}
            {hasTopics && (
              <>
                {company.topics.map(({ id, name }) => (
                  <Tag key={id} variant="transparent">
                    {capitalize(name)}
                  </Tag>
                ))}
              </>
            )}
          </Flex>
        )}
        {company.description && (
          <Text fontSize="14px" color="text.main" marginBottom="3" className="text-break">
            {company.description}
          </Text>
        )}
        <SocialIcons {...socials} />
      </Box>
    </Flex>
  );
};

InfoView.propTypes = {
  network: NetworkSchema.isRequired,
  company: PropTypes.object.isRequired,
  socials: PropTypes.object.isRequired,
  logoUrl: PropTypes.string.isRequired,
};
