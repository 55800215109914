import useDebouncedEffect from 'hooks/useDebouncedEffect';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import { useGetJobsQuery } from 'services/jobsV2';

export const useJobForList = () => {
  const [keywords, setKeywords] = useState();
  const [debouncedKeywords, setDebouncedKeywords] = useState();
  const [page, setPage] = useState(1);
  const activeNetwork = useSelector(activeNetworkSelector);

  const {
    data: jobData,
    isFetching: isFetchingJobs,
    error: jobError,
  } = useGetJobsQuery({
    page,
    perPage: 20,
    filters: {
      q: debouncedKeywords,
      includeMatchesLists: true,
      status: 'active',
    },
  });

  useDebouncedEffect(
    () => {
      setDebouncedKeywords(keywords);
    },
    600,
    keywords,
  );

  const normalizedJobData = jobData?.data
    ? jobData.data.map((job) => ({
        organization: job.attributes.organization,
        job: { id: job.id, title: job.attributes.title },
        matches: (job.attributes.sharedLists || []).map((list) => ({
          ...list,
          url: `/networks/${activeNetwork.slug}/list/${list.id}/contacts`,
        })),
        matchLists: { id: null, status: job.status || 'none' },
        network: activeNetwork,
      }))
    : [];

  return {
    isLoadingJobs: isFetchingJobs,
    jobs: jobData,
    search: setKeywords,
    searchResults: keywords || !normalizedJobData.length ? normalizedJobData : normalizedJobData,
    showEmptyState: jobData?.length === 0,
    error: jobError,
    keywords,
    loadMore: () => {
      if (jobData?.hasMore) {
        setPage(page + 1);
      }
    },
  };
};
