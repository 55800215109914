import { LoadMore } from 'components/atoms/LoadMore';
import React, { useCallback, useRef, useState } from 'react';
import { DataTable } from '@getro/rombo';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Building2, Contact, ExternalLink, Globe, KeyRound, Lock, MoreHorizontal, Trash } from 'lucide-react';
import { Flex, Box, Text } from 'rebass/styled-components';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import { DeleteSharedListModal } from '../../components/atoms/deleteSharedListModal';
import { SkeletonRow } from '../../components/atoms/rowSkeleton';
import { Dropdown } from '../../components/molecules/dropdown';
import { Card } from '../../components/atoms/Card';

export const getCompaniesColumns = () => [
  {
    Header: 'Name',
    accessor: 'name',
    width: '38%',
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'kind',
    width: '15%',
    disableSortBy: true,
  },
  {
    Header: 'Contacts',
    accessor: 'contacts',
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Visibility',
    accessor: 'visibility',
    width: '15%',
    disableSortBy: true,
  },
  {
    Header: 'Created by',
    accessor: 'createdBy',
    width: '22%',
    disableSortBy: true,
  },
  {
    Header: 'Created on',
    accessor: 'createdOn',
    width: '32%',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'actions',
    width: '10%',
    disableSortBy: true,
  },
];

export const ListDataTable = ({
  sharedLists,
  isLoading,
  isFetching,
  pathname,
  isManager,
  deleteSharedList,
  isLoadingDelete,
  loadMore,
  hasMore,
}) => {
  const [deleteList, setDeleteList] = useState({ showModal: false, list: null });
  const menuRef = useRef();
  const companiesColumns = getCompaniesColumns();
  const showSkeleton = isLoading && !isFetching;

  const createListRows = useCallback(
    () =>
      sharedLists.map((list) => {
        let shareIcon = Lock;
        let shareText = 'Internal';

        if (list.passwordProtected) {
          shareIcon = KeyRound;
          shareText = 'Shared';
        } else if (list.shareable) {
          shareIcon = Globe;
          shareText = 'Shared';
        }

        const actionMenuItems = [
          {
            isDisabled: !list.shareable,
            title: 'Open shared view',
            icon: ExternalLink,
            onClick: () => {
              window.open(`/app/shared-lists/${list.slug}`, '_blank');
            },
          },
          {
            isDisabled: !isManager,
            title: 'Delete',
            icon: Trash,
            onClick: () => {
              setDeleteList({ showModal: true, list });
            },
          },
        ];

        return {
          passwordProtected: list.passwordProtected,
          id: list.id,
          slug: list.slug,
          name: (
            <Flex width="100%" justifyContent="flex-start" fontSize="14px" color="text.dark" fontWeight={500}>
              {showSkeleton ? <SkeletonRow width={160} /> : list.name}
            </Flex>
          ),
          kind: (
            <Flex width="100%" justifyContent="flex-start" fontSize="14px" sx={{ gap: '8px' }}>
              {showSkeleton ? (
                <SkeletonRow width={80} />
              ) : (
                <>
                  {list.kind === 'people' && (
                    <Flex
                      alignItems="center"
                      flex="0 0 auto"
                      bg="neutral.50"
                      sx={{ gap: '4px', borderRadius: 'circle', px: '8px', py: '1px' }}
                    >
                      <Box as={Contact} height="12px" width="12px" aria-hidden="true" />
                      <Text fontSize="12px" fontWeight="500" lineHeight="16px">
                        People
                      </Text>
                    </Flex>
                  )}
                  {list.kind === 'companies' && (
                    <Flex
                      alignItems="center"
                      flex="0 0 auto"
                      bg="neutral.50"
                      sx={{ gap: '4px', borderRadius: 'circle', px: '8px', py: '1px' }}
                    >
                      <Box as={Building2} height="12px" width="12px" aria-hidden="true" />
                      <Text fontSize="12px" fontWeight="500" lineHeight="16px">
                        Companies
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          ),
          contacts: (
            <Flex width="100%" justifyContent="flex-end" fontSize="14px">
              {showSkeleton ? <SkeletonRow width={24} /> : list.contactsCount}
            </Flex>
          ),
          visibility: (
            <Flex width="100%" justifyContent="flex-start" sx={{ gap: '8px', alignItems: 'center' }}>
              {showSkeleton ? (
                <SkeletonRow width={80} />
              ) : (
                <>
                  <Box size="16px" as={shareIcon} />
                  <Box as="p" color="text.main" fontSize="14px">
                    {shareText}
                  </Box>
                </>
              )}
            </Flex>
          ),
          createdBy: (
            <Flex width="100%" justifyContent="flex-start" fontSize="14px">
              {showSkeleton ? <SkeletonRow width={80} /> : list.pointOfContact.fullName}
            </Flex>
          ),
          createdOn: (
            <Flex width="100%" justifyContent="flex-start" fontSize="14px" color="text.subtle">
              {showSkeleton ? <SkeletonRow width={160} /> : moment(list.createdAt).format('D MMM YYYY')}
            </Flex>
          ),

          actions: (
            <Flex justifyContent="flex-end">
              <Dropdown
                ref={menuRef}
                placement="left-start"
                triggerProps={{
                  onClick: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  },
                }}
                trigger={
                  <Flex maxWidth="24px" width="16px" height="40px" alignItems="center" justifyContent="flex-end" p="0">
                    <Box as={MoreHorizontal} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" />
                  </Flex>
                }
              >
                <Card width="240px" height="96px" p="8px 16px" bg="neutral.0">
                  {actionMenuItems.map((item) => (
                    <>
                      {item.title === 'Delete' && <Box sx={{ height: '1px', bg: 'neutral.20', my: '8px' }} />}
                      <Box
                        sx={{
                          color: item.isDisabled ? 'text.placeholder' : 'text.main',
                          textDecoration: 'none',
                          fontSize: '14px',
                          lineHeight: '24px',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          py: '4px',
                          cursor: item.isDisabled ? 'not-allowed' : 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (item.onClick && !item.isDisabled) {
                            menuRef.current.closeDropdown();
                            item.onClick();
                          }
                        }}
                      >
                        <Box as={item.icon} size="16px" />
                        <Box as="span">{item.title}</Box>
                      </Box>
                    </>
                  ))}
                </Card>
              </Dropdown>
            </Flex>
          ),
        };
      }),
    [isManager, sharedLists, showSkeleton],
  );

  const onDeleteList = async (list) => {
    await deleteSharedList({
      listId: list.id,
    });

    toast('List deleted', { icon: 'success', id: 'list-deleted-successfully' });

    setDeleteList({ showModal: false, list: null });
  };

  return (
    <Flex flexDirection="column" flexGrow="1">
      <DataTable
        sx={{
          pb: 3,
          minWidth: '980px',
          height: ['600px', '500px', '100%'],
          width: ['1000px!important', '1000px!important', '100%!important'],
          flexGrow: 1,
        }}
        canSort
        virtuosoProps={{
          useWindowScroll: true,
          components: {
            // eslint-disable-next-line react/no-multi-comp
            Footer: () => (isFetching ? <LoadMore /> : null),
          },
        }}
        loadMore={loadMore}
        items={createListRows()}
        columnDefinition={companiesColumns}
        hasMore={hasMore}
        isLoadingMore={isFetching}
        emptyView={
          <Text pt="40px" sx={{ alignSelf: 'center' }}>
            No results for this search
          </Text>
        }
        isInitialized
        rowProps={({ original }) => ({
          as: Link,
          to: `${pathname}/${original.id}/contacts`,
          sx: {
            textDecoration: 'none!important',
            minHeight: '40px',
            transition: 'background-color ease 0.3s',
            py: '8px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'neutral.20',
            },

            '& > div': {
              '& > div': {
                minHeight: '40px',
                py: '0px',

                '& > div': {
                  minHeight: '40px',
                  margin: 0,
                },
              },

              '& > div > div': {
                display: 'flex',
                alignItems: 'center',
              },
            },
          },
        })}
      />
      {deleteList.showModal && (
        <DeleteSharedListModal
          list={deleteList.list}
          isLoading={isLoadingDelete}
          onCloseModal={() => setDeleteList({ showModal: false, list: null })}
          onDeleteSharedList={onDeleteList}
        />
      )}
    </Flex>
  );
};

ListDataTable.propTypes = {
  sharedLists: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  pathname: PropTypes.string.isRequired,
  isManager: PropTypes.bool.isRequired,
  deleteSharedList: PropTypes.func.isRequired,
  isLoadingDelete: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

ListDataTable.defaultProps = {
  sharedLists: [],
  isFetching: false,
  isLoading: false,
  isLoadingDelete: false,
};
