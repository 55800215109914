import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ProfilePicture, Tooltip } from '@getro/rombo';
import PropTypes from 'prop-types';

export const PreviewAiMatches = ({ match }) => (
  <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', gap: '24px' }}>
    <Flex mt="4px" alignItems="center" sx={{ gap: '8px ' }}>
      <Box width="24px" height="24px">
        <ProfilePicture imageUrl={match.organization.logoUrl} name={match.organization.name} variant="square" />
      </Box>
      <Box>
        <Tooltip
          position="top"
          content={match.job.title}
          trigger={
            <Text
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              fontSize="14px"
              fontWeight="medium"
              color="text.dark"
            >
              {match.job.title}
            </Text>
          }
        />
      </Box>
    </Flex>
  </Flex>
);

PreviewAiMatches.propTypes = {
  match: PropTypes.object.isRequired,
};
