import { convertFromCents } from './money';

export const displaySalaryAmount = (number) => {
  const num = typeof number === 'string' ? parseFloat(number) : number;

  if (num >= 1000000) {
    return `${Math.round(num / 100000) / 10}M`;
  }
  if (num >= 1000) {
    return `${Math.round(num / 100) / 10}k`;
  }

  return Math.round(num).toString();
};

export const displayCompensationPeriod = (compensation = {}) => {
  const {
    amountMinCents,
    amountMaxCents,
    currency,
    period,
    offersEquity,
    convertedAmountMinCents,
    convertedAmountMaxCents,
    convertedCurrency,
  } = compensation;
  const converted =
    convertedAmountMinCents && convertedAmountMaxCents && currency !== convertedCurrency
      ? ` (${convertedCurrency} ${displaySalaryAmount(convertFromCents(convertedAmountMinCents))}-${displaySalaryAmount(
          convertFromCents(convertedAmountMaxCents),
        )} / ${period.toLowerCase()})`
      : '';

  return `${currency} ${displaySalaryAmount(convertFromCents(amountMinCents))}-${displaySalaryAmount(
    convertFromCents(amountMaxCents),
  )} / ${period.toLowerCase()}${converted}${offersEquity ? ' + Equity' : ''}`;
};
