import { Box } from 'rebass/styled-components';
import styled, { css } from 'styled-components';

export const TableWrapper = styled(Box)`
  th:first-child,
  td:first-child {
    left: 0;
    z-index: 10;
  }

  th:first-child {
    z-index: 12 !important;
  }

  .is-scrolling {
    ${({ fixedColumn }) =>
      fixedColumn === 'first' &&
      css`
        th:first-child,
        td:first-child {
          box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
        }
      `}

    ${({ fixedColumn }) =>
      fixedColumn === 'last' &&
      css`
        th:last-child,
        td:last-child {
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
        }
      `}
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  thead {
    z-index: 12 !important;
  }

  th,
  td {
    border-top: 1px solid ${(props) => props.theme.colors.border.subtle};
    border-left: 1px solid ${(props) => props.theme.colors.border.subtle};
    padding: 8px;
    vertical-align: top;
  }

  tbody {
    tr:first-child {
      td,
      th {
        border-top: 0px;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1px solid ${(props) => props.theme.colors.border.subtle};
      }
    }
  }

  td:last-child,
  th:last-child {
    border-right: 1px solid ${(props) => props.theme.colors.border.subtle};
  }

  td {
    height: 64px;
  }

  th {
    background: white;
    border-bottom: 1px solid ${(props) => props.theme.colors.border.subtle};

    :first-child {
      z-index: 15;
      background-color: white;
    }
  }

  & > div {
    &::-webkit-scrollbar {
      color: red;
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-bar {
      width: 12px;
    }

    scrollbar-width: 3px;
  }

  tfoot {
    z-index: 13 !important;
  }
`;
