import React from 'react';
import { Text, Flex, Box } from 'rebass/styled-components';
import { ChevronRight, FlaskConical } from 'lucide-react';
import PropTypes from 'prop-types';

const Initial = ({ setStep }) => (
  <>
    <Text as="h4" fontWeight="medium" fontSize="14px" color="text.dark">
      What type of list do you want?
    </Text>
    <Flex
      mt="16px"
      p="16px"
      alignItems="center"
      sx={{
        gap: '16px',
        border: '1px solid',
        borderColor: 'border.subtle',
        borderRadius: 'default',
        cursor: 'pointer',
      }}
      onClick={() => setStep('contacts:create')}
    >
      <Box>
        <Text as="h5" fontWeight="medium" fontSize="16px" color="text.dark">
          Contacts list
        </Text>
        <Text mt="8px" fontSize="14px" color="text.dark">
          Stay organized with your contacts and share them when needed.
        </Text>
        <Text mt="16px" fontSize="14px" color="text.subtle">
          This list is ideal for recruiting, sharing top talent, or connecting others with investors, mentors, or
          advisors. It&apos;s also great for nurturing key relationships in business development and sales.
        </Text>
      </Box>
      <Box sx={{ color: 'neutral.500' }}>
        <Box as={ChevronRight} width="24px" height="24px" aria-hidden="true" />
      </Box>
    </Flex>
    <Flex
      mt="16px"
      p="16px"
      alignItems="center"
      sx={{
        gap: '16px',
        border: '1px solid',
        borderColor: 'border.subtle',
        borderRadius: 'default',
        cursor: 'pointer',
      }}
      onClick={() => setStep('companies:create')}
    >
      <Box>
        <Flex alignItems="center" sx={{ gap: '8px' }}>
          <Text as="h5" fontWeight="medium" fontSize="16px" color="text.dark">
            Companies list
          </Text>
          <Flex
            px="12px"
            py="4px"
            bg="purple.100"
            alignItems="center"
            sx={{ gap: '8px', color: 'purple.500', borderRadius: 'circle' }}
          >
            <Box as={FlaskConical} width="16px" height="16px" aria-hidden="true" />
            <Text fontSize="14px" color="text.dark" fontWeight="medium">
              Experimental
            </Text>
          </Flex>
        </Flex>
        <Text mt="4px" fontSize="14px" color="text.dark">
          Discover warm intro paths to the companies on the list.
        </Text>
        <Text mt="16px" fontSize="14px" color="text.subtle">
          Use this list to explore hiring opportunities, generate sales leads, or build partnerships through networked
          introductions. This feature is in its early stages, so you may experience a few hiccups. We&apos;d love to
          hear your feedback!
        </Text>
      </Box>
      <Box sx={{ color: 'neutral.500' }}>
        <Box as={ChevronRight} width="24px" height="24px" aria-hidden="true" />
      </Box>
    </Flex>
  </>
);

Initial.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default Initial;
