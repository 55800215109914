import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const JobAppearanceListLoader = ({ mode }) => (
  <Flex
    sx={{
      p: '16px',
      borderRadius: '4px',
      div: {
        borderRadius: '8px',
      },
      gap: '16px',
      flexDirection: 'column',
      boxShadow: '0px 1px 0px 0px #0000000F',
    }}
    width="100%"
    backgroundColor="#ffffff"
  >
    <Flex
      sx={{ justifyContent: ['flex-start', 'space-between'], alignItems: 'center', flexDirection: ['column', 'row'] }}
    >
      <Flex sx={{ alignItems: 'flex-start', gap: '16px', width: '100%' }}>
        <Box
          width="100%"
          maxWidth={mode === 'mobile' ? '42px' : '72px'}
          height={mode === 'mobile' ? '42px' : '72px'}
          bg="neutral.50"
        />
        <Flex sx={{ gap: '16px', flexDirection: 'column', width: '100%' }}>
          <Flex sx={{ gap: '10px', flexDirection: 'column', flexGrow: 1 }}>
            <Box width={mode === 'mobile' ? '90%' : '195px'} height="16px" bg="neutral.50" />
            <Box width={mode === 'mobile' ? '60%' : '77px'} height="16px" bg="neutral.50" />
            <Box width={mode === 'mobile' ? '55%' : '119px'} height="16px" bg="neutral.50" />
          </Flex>
          {mode !== 'mobile' && (
            <Flex
              sx={{
                gap: '16px',
                div: {
                  bg: 'neutral.50',
                },
              }}
            >
              {[0, 1, 2, 3].map((i) => (
                <Box key={i} width="77px" height="16px" />
              ))}
            </Flex>
          )}
        </Flex>
      </Flex>
      {mode !== 'mobile' && <Box width="77px" height="16px" bg="neutral.50" />}
    </Flex>
    {mode === 'mobile' && (
      <Flex sx={{ alignItems: 'flex-start', gap: '16px' }}>
        {[0, 1, 2].map((i) => (
          <Box key={i} width="77px" height="16px" bg="neutral.50" />
        ))}
      </Flex>
    )}
  </Flex>
);

JobAppearanceListLoader.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default JobAppearanceListLoader;
