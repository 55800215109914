import React from 'react';
import { Box } from 'rebass/styled-components';

import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import usePageHook from '../../../hooks/usePageHook';
import Notifications from '../../Notifications';

const pageTitle = 'Notifications';

export const AccountNotifications = () => {
  usePageHook(pageTitle);

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader
        title={pageTitle}
        description="These settings apply to every network you are an admin of."
      />
      <Box pt="32px">
        <Notifications />
      </Box>
    </Box>
  );
};
