import React, { useState } from 'react';
import MainLayout from 'components/layout/Main';
import usePageHook from 'hooks/usePageHook';
import { Box, Flex } from 'rebass/styled-components';
import { Input } from '@rebass/forms/styled-components';
import { ListPlus, Search } from 'lucide-react';
import { Label } from '@rebass/forms';
import { Button, FormSelect } from '@getro/rombo';
import { useRouteMatch, Redirect, Route, Switch, Link } from 'react-router-dom';
import { useGetNetworkAdminsQuery } from 'services/networkAdmins';
import { useDeleteSharedListMutation, useGetSharedListsQuery } from 'services/contacts';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import { trackEvent } from 'helpers/analytics';
import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import { ListDataTable } from './listDataTable';
import { EmptyView } from './emptyView';
import { CreateList } from './createList';

const List = () => {
  usePageHook('Lists');
  const { path } = useRouteMatch();
  const [createdBy, setCreatedBy] = useState(null);
  const { data: networkAdmins } = useGetNetworkAdminsQuery();
  const [page, setPage] = useState(1);

  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword, setDebouncedKeyword] = useState('');
  const {
    data: { sharedLists = [], meta = {} } = {},
    isFetching,
    isLoading,
    error,
  } = useGetSharedListsQuery(
    {
      keyword: debouncedKeyword,
      userId: createdBy?.value,
      page,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isLoading,
    },
  );

  const { slug, isManager, id: networkId } = useSelector(activeNetworkSelector);
  const [deleteSharedList, { isLoading: isLoadingDelete }] = useDeleteSharedListMutation();

  const admins = [
    {
      label: 'Any creator',
      value: null,
    },
  ];

  for (let i = 0; i < networkAdmins?.length; i += 1) {
    const { id, lastName, firstName } = networkAdmins[i];
    admins.push({
      label: `${firstName} ${lastName}`,
      value: parseInt(id, 10),
    });
  }

  useDebouncedEffect(
    () => {
      setPage(1);
      setDebouncedKeyword(keyword);
    },
    599,
    keyword,
  );

  return (
    <MainLayout>
      <MainLayoutHeader
        title="Lists"
        actions={[
          <Button
            as={Link}
            to={`${path}/create`}
            size="small"
            icon={ListPlus}
            variant="accent"
            onClick={() => {
              trackEvent('lists:create_list_click', {
                collection_id: networkId,
                page: 'lists',
                origin: 'top_nav_button',
              });
            }}
          >
            Create list
          </Button>,
        ]}
      />
      <Box pt="24px" px="16px" mx="auto" width="100%" maxWidth="1600px">
        {(isLoading || meta.sharedListTotal > 0) && (
          <Flex sx={{ gap: '8px' }}>
            <Label mb="40px" flexDirection="column" display="flex" data-testid="Search Input">
              <Box color="neutral.300" display="flex" alignItems="center" sx={{ position: 'relative' }}>
                <Box
                  variant="icon"
                  sx={{ stroke: 'currentColor', position: 'absolute', left: 2 }}
                  height="16px"
                  width="16px"
                  strokeWidth="1.5"
                  aria-hidden="true"
                  as={Search}
                  mr={1}
                />
                <Input
                  sx={{ borderColor: 'neutral.400' }}
                  variant="search"
                  aria-label="Search Input"
                  type="text"
                  name="keyword"
                  size="medium"
                  placeholder="Search by name or company..."
                  onChange={({ target }) => {
                    setKeyword(target.value);
                  }}
                />
              </Box>
            </Label>
            <Box sx={{ '& div': { mt: 0 }, input: { color: 'text.main' }, width: '150px' }}>
              <FormSelect
                name="createdBy"
                placeholder="Created by"
                options={admins}
                containerProps={{ mt: 4 }}
                isSearchable
                onChange={(value) => {
                  setPage(1);
                  setCreatedBy(value);
                }}
                value={createdBy}
              />
            </Box>
          </Flex>
        )}
        {(isLoading || isFetching) && page === 1 ? (
          <ListDataTable
            isLoading={isLoading || isFetching}
            sharedLists={Array.from(Array(4)).map(() => ({ pointOfContact: {} }))}
          />
        ) : (
          <>
            {meta.sharedListTotal <= 0 && <EmptyView slug={slug} />}
            {meta.sharedListTotal > 0 && (
              <ListDataTable
                sharedLists={sharedLists}
                isFetching={isFetching}
                isLoading={isLoading}
                error={error}
                userId={createdBy?.value}
                keyword={keyword}
                isManager={isManager}
                slug={slug}
                deleteSharedList={async (payload) => {
                  const { error: sharedListError } = await deleteSharedList({
                    listId: payload.listId,
                    queryParams: {
                      userId: createdBy?.value,
                      keyword: debouncedKeyword,
                      page,
                    },
                    force: true,
                  });

                  if (!sharedListError) {
                    trackEvent('contacts:list:delete_list', {
                      collectionId: networkId,
                      listId: payload.listId,
                    });
                  }
                }}
                isLoadingDelete={isLoadingDelete}
                pathname={`/networks/${slug}/list`}
                hasMore={meta?.sharedListTotal > sharedLists?.length}
                loadMore={() => {
                  if (sharedLists?.length % 20 !== 0) return;
                  setPage(Math.floor(sharedLists?.length / 20) + 1);
                }}
                page={page}
              />
            )}
          </>
        )}
        <Switch>
          <Route exact path={`/networks/${slug}/lists/create`}>
            <CreateList />
          </Route>
          <Route
            path={`/networks/${slug}/lists/:listId/contacts`}
            render={({ match }) => <Redirect to={`/networks/${slug}/list/${match.params.listId}/contacts`} />}
          />
        </Switch>
      </Box>
    </MainLayout>
  );
};

export default List;
