import { createSelector } from 'reselect';

export const networksSelector = (state) => state.networks;
export const userSelector = (state) => state.user;
export const pageSelector = (state) => state.page;
export const jobFunctionsSelector = (state) => state.jobFunctions;
export const activeCompanySelector = (state) => {
  if (state.companyProfile && state.companyProfile.company) {
    return state.companyProfile.company;
  }
  return null;
};
export const introductionRequestsSelector = (state) => state.introductionRequests || {};

export const companyAdminSelector = createSelector(activeCompanySelector, (state) => {
  if (!state) {
    return [];
  }
  return state.managers || [];
});

export const activeNetworkSelector = createSelector(networksSelector, (networksStore) => {
  const { networks, active } = networksStore;
  if (networks === null || networks.length === 0 || !active) {
    return null;
  }

  return networks[active];
});

export const membershipSettingsSelector = createSelector(activeNetworkSelector, userSelector, (activeNetwork, user) => {
  const { userTalentCollections } = user;
  if (!activeNetwork || !userTalentCollections) {
    return {};
  }

  const userTalentCollection = userTalentCollections.find((utc) => utc.collectionId === activeNetwork.id);
  const { membershipSetting } = userTalentCollection || {};

  return membershipSetting || {};
});

export const hasJobAlertsEnabledSelector = createSelector(activeNetworkSelector, ({ features }) => {
  if (!features) return false;

  return !features.includes('no_job_alerts') && !features.includes('talent_network');
});

export const makeHasAutoSubscribe = createSelector(
  membershipSettingsSelector,
  (membershipSettings) => membershipSettings.subscribeToAllBoards,
);
export const makeHasDailyAlerts = createSelector(
  membershipSettingsSelector,
  (membershipSettings) => membershipSettings.boardsDailyDigest,
);

export const hasTalentNetworkV2Selector = createSelector(activeNetworkSelector, ({ features }) => {
  if (!features) return false;

  return features.includes('tn_onboarding_v2');
});

export const hasSuggestedCompaniesSelector = createSelector(
  activeNetworkSelector,
  ({ supportsSuggestedCompanies, isManager }) => supportsSuggestedCompanies && isManager,
);

export const hasImpactAnalyticsEnabledSelector = createSelector(activeNetworkSelector, ({ isManager }) => isManager);

export const hasContactsSelector = createSelector(activeNetworkSelector, (activeNetwork) => {
  const { features, isManager } = activeNetwork || {};
  if (!features) return false;

  return features.includes('add_contacts') && isManager;
});

export const hasMatchesSelector = createSelector(activeNetworkSelector, (activeNetwork) => {
  const { features, isManager } = activeNetwork || {};
  if (!features) return false;

  return (features.includes('add_contacts') || features.includes('tn_onboarding_v2')) && isManager;
});

export const isGetroUserSelector = createSelector(
  activeNetworkSelector,
  userSelector,
  ({ features, isManager, supportsInvoiceView }) => {
    if (!features) return false;

    return isManager && supportsInvoiceView;
  },
);
