import { parseScript } from 'esprima-next';
import { generate } from 'escodegen';
import { replace } from 'estraverse';

export const removeWindowLoadEvent = (code) => {
  try {
    const ast = parseScript(code, { range: true, ecmaVersion: 2020 });

    const functionBodies = [];

    // Traverse the AST and extract event listeners
    replace(ast, {
      enter(node) {
        if (
          node.type === 'CallExpression' &&
          node.callee.type === 'MemberExpression' &&
          node.callee.object.name === 'window' &&
          node.callee.property.name === 'addEventListener' &&
          (node.arguments[0].value === 'DOMContentLoaded' || node.arguments[0].value === 'load')
        ) {
          if (node.arguments[1].type === 'FunctionExpression' || node.arguments[1].type === 'ArrowFunctionExpression') {
            // Store the body of the addEventListener function
            functionBodies.push(...node.arguments[1].body.body);

            // Replace the addEventListener call with a call to the new function
            return {
              type: 'CallExpression',
              callee: {
                type: 'Identifier',
                name: 'initializeScript',
              },
              arguments: [],
            };
          }
          if (node.arguments[1].type === 'Identifier') {
            // Replace the addEventListener call with a call to the named function
            return {
              type: 'CallExpression',
              callee: node.arguments[1],
              arguments: [],
            };
          }
        } else if (
          node.type === 'CallExpression' &&
          node.callee.type === 'MemberExpression' &&
          node.callee.object.type === 'CallExpression' &&
          (node.callee.object.callee.name === '$' || node.callee.object.callee.name === 'jQuery') &&
          node.callee.object.arguments[0].type === 'Identifier' &&
          node.callee.object.arguments[0].name === 'document' &&
          node.callee.property.name === 'ready'
        ) {
          if (node.arguments[0].type === 'FunctionExpression' || node.arguments[0].type === 'ArrowFunctionExpression') {
            // Store the body of the ready function
            functionBodies.push(...node.arguments[0].body.body);

            // Replace the ready call with a call to the new function
            return {
              type: 'CallExpression',
              callee: {
                type: 'Identifier',
                name: 'initializeScript',
              },
              arguments: [],
            };
          }
          if (node.arguments[0].type === 'Identifier') {
            // Replace the ready call with a call to the named function
            return {
              type: 'CallExpression',
              callee: node.arguments[0],
              arguments: [],
            };
          }
        }
        return node;
      },
    });

    // Create a new function declaration node
    if (functionBodies.length > 0) {
      const newFunction = {
        type: 'FunctionDeclaration',
        id: {
          type: 'Identifier',
          name: 'initializeScript',
        },
        params: [],
        body: {
          type: 'BlockStatement',
          body: functionBodies, // Merge all function bodies
        },
      };

      ast.body.unshift(newFunction);
    }

    return `{${generate(ast)}}`;
  } catch (e) {
    return code;
  }
};

export const getInLineScripts = (htmlString) => {
  const regex = /<script\s*>([\s\S]*?)<\/\s*script\s*>/gi;
  let match = regex.exec(htmlString);
  const inlineScripts = [];

  while (match) {
    inlineScripts.push(match[1]);
    match = regex.exec(htmlString);
  }

  return inlineScripts;
};

export const addLazyLoadingToImages = (htmlString) => {
  const regex = /<img([^>]+)>/gi;
  return htmlString.replace(regex, '<img$1 loading="lazy">');
};

const parseHtml = ({ html = '', values = {} }) => {
  const formatter = new Intl.NumberFormat('en-US');

  const COMMENT_PSEUDO_COMMENT_OR_LT_BANG = new RegExp(
    '<!--[\\s\\S]*?(?:-->)?' +
      '<!---+>?' + // A comment with no body
      '|<!(?![dD][oO][cC][tT][yY][pP][eE]|\\[CDATA\\[)[^>]*>?' +
      '|<[?][^>]*>?', // A pseudo-comment
    'g',
  );

  return html
    .replace(COMMENT_PSEUDO_COMMENT_OR_LT_BANG, '')
    .replace(/<%=tagline=%>/g, values.tagline || '')
    .replace(/<%=subtitle=%>/g, values.subtitle || '')
    .replace(/<%=organizationsCount=%>/g, formatter.format(values.organizationsCount || ''))
    .replace(/<%=jobsCount=%>/g, formatter.format(values.jobsCount || ''))
    .replace(/<%=heroColorMask=%>/g, values.heroColorMask);
};

export const getLinkedScripts = (htmlString) => {
  const regex = /<script[^>]*src="(.*?)"[^>]*>[^<]*<\/\s*script\s*>/gi;
  let match = regex.exec(htmlString);
  const linkedScripts = [];

  while (match) {
    linkedScripts.push(match[1]);
    match = regex.exec(htmlString);
  }

  return linkedScripts;
};

export const processHtml = (html = '', advancedDesignValues) => {
  const result = { html: null, scripts: [] };

  if (html) {
    const [h] = html.split('<script');

    result.html = parseHtml({ html: h, values: advancedDesignValues });
    result.scripts = getInLineScripts(html) || [];
    result.linkedScripts = getLinkedScripts(html);
  }

  return result;
};
