import React from 'react';
import { Button } from '@getro/rombo';
import { Flex, Box, Text, Image } from 'rebass/styled-components';
import { Download, Plus } from 'lucide-react';
import PropTypes from 'prop-types';
import { Switch, Route, useHistory, NavLink } from 'react-router-dom';

import IconHandEmptyIcon from '../../assets/icon-hand-empty.svg';
import { DataTable } from '../../components/molecules/dataTable';
import { CompanyFilters } from '../../components/organisms/companyFilters';
import { ContactDetail } from '../../components/organisms/contactDetail';
import { trackEvent } from '../../helpers/analytics';
import { useCompanyList } from './hooks/useCompanyList';
import { EditListCompanies } from './editListCompanies';
import ExportListCompanies from './exportListCompanies';

export const CompaniesList = ({ sharedList, isLoadingSharedList }) => {
  const {
    activeNetwork,
    listId,
    companiesColumns,
    tableRows,
    totalItems,
    initialSortBy,
    handleSort,
    isLoading,
    isFetching,
    actionMenuItems,
    onColumnAction,
    hasMore,
    onLoadMore,
    queryParams,
    filtersCount,
    onSetFilters,
    onSaveFilters,
    removeContactFromSharedList,
  } = useCompanyList(sharedList);
  const history = useHistory();

  return (
    <Flex flexDirection="column" flexGrow="1" sx={{ zIndex: 1, position: 'relative' }}>
      {tableRows.length === 0 && !isLoading ? (
        <Box width="248px" margin="0 auto" mt="100px">
          <Image src={IconHandEmptyIcon} width="64px" maxWidth="64px" />
          <Text fontSize="14px" fontWeight="500" color="text.dark">
            Your companies list is ready
          </Text>
          <Box as={NavLink} to={`/networks/${activeNetwork.slug}/list/${listId}/contacts/edit`}>
            <Button
              mt="24px"
              size="small"
              variant="secondary"
              icon={Plus}
              width="100%"
              sx={{ justifyContent: 'center' }}
            >
              Add companies
            </Button>
          </Box>
        </Box>
      ) : (
        <DataTable
          itemName="company"
          isInitialized={!isLoading && !(isFetching && queryParams.page === 1)}
          overscan={20}
          canSort
          onSort={handleSort}
          initialSortBy={initialSortBy}
          hasMore={hasMore}
          loadMore={onLoadMore}
          items={tableRows}
          totalItems={totalItems}
          columnDefinition={companiesColumns}
          isLoadingMore={isFetching}
          onColumnAction={onColumnAction}
          persistColumnKey={`${activeNetwork.id}-${listId}-companies-columns`}
          showFilters
          FiltersComponent={CompanyFilters}
          filters={queryParams.filters}
          filtersTitle="Filter contacts"
          filterCount={filtersCount}
          onSetFilters={onSetFilters}
          onSaveFilters={onSaveFilters}
          rowActions={{
            height: 330,
            items: actionMenuItems,
          }}
          tableSx={{
            minWidth: '2000px',
            height: 'fit-content!important',
          }}
          sx={{
            height: 'calc(100vh - 126px)!important',
          }}
          leftActions={
            <Flex
              sx={{ alignItems: 'center', gap: '8px', cursor: 'pointer' }}
              onClick={() => {
                history.push(`/networks/${activeNetwork.slug}/list/${listId}/contacts/export${window.location.search}`);
                trackEvent('lists:export_csv_click', {
                  collection_id: activeNetwork.id,
                  list_slug: sharedList.slug,
                  object_type: 'companies',
                });
              }}
            >
              <Box as={Download} size="16px" />
              <Text
                display={['none', 'none', 'block']}
                sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '16px' }}
              >
                Export
              </Text>
            </Flex>
          }
        />
      )}
      <Switch>
        <Route exact path="/networks/:slug/list/:listId/contacts/edit">
          <EditListCompanies isLoading={isLoadingSharedList} name={sharedList?.name} domains={sharedList?.domains} />
        </Route>
        <Route exact path="/networks/:slug/list/:listId/contacts/export">
          <ExportListCompanies sharedList={sharedList} />
        </Route>
        <Route path="/networks/:activeNetwork/list/:listId/contacts/:contactId">
          <ContactDetail
            queryParams={queryParams}
            isTalentNetworkEnabled={!activeNetwork?.tnHidden}
            list={sharedList}
            onClose={() => {
              history.push(`/networks/${activeNetwork.slug}/list/${sharedList.id}/contacts${history.location.search}`);
            }}
            deleteContactFromList={removeContactFromSharedList}
            page="companies_list"
          />
        </Route>
      </Switch>
    </Flex>
  );
};

CompaniesList.propTypes = {
  sharedList: PropTypes.object,
  isLoadingSharedList: PropTypes.bool,
};

CompaniesList.defaultProps = {
  sharedList: {},
  isLoadingSharedList: false,
};
