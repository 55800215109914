import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Box } from 'rebass/styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '@getro/rombo';
import { Plus } from 'lucide-react';

import compose from '../../helpers/compose';
import pageHoc from '../../hocs/pageHoc';
import { activeNetworkSelector } from '../../redux/selectors';
import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import MainLayout from '../../components/layout/Main';
import JobsTableWithFilters from '../../components/organisms/jobsTableWithFilters';
import DownloadJobApplications from './DownloadJobApplications';

const Jobs = () => {
  const currentNetwork = useSelector(activeNetworkSelector);
  const { isManager: isNetworkManager } = currentNetwork;
  const history = useHistory();
  const match = useRouteMatch();

  const onPostJob = () => {
    history.push(`${match.url}/post-job`);
  };

  return (
    <MainLayout containerProps={{ minHeight: '100vh', pb: '0px' }}>
      <MainLayoutHeader
        breadcrumbsProps={{
          flexDirection: 'row',
        }}
        title="Jobs"
        actions={[
          <DownloadJobApplications />,
          isNetworkManager ? (
            <Button size="small" variant="accent" onClick={onPostJob}>
              <Flex alignItems="center">
                <Box as={Plus} mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" />
                Post job
              </Flex>
            </Button>
          ) : null,
        ]}
      />
      <Box pt="24px" px="16px" mx="auto" width="100%" maxWidth="1600px">
        <JobsTableWithFilters source="jobs" />
      </Box>
    </MainLayout>
  );
};

export default compose(pageHoc({ title: 'Jobs' }))(Jobs);
