import React, { useRef } from 'react';
import { ExternalLink } from 'lucide-react';
import PropTypes from 'prop-types';
import { FormField, Loader, Message, MessageBlock, Button } from '@getro/rombo';
import toast from 'react-hot-toast';
import strings from 'strings';
import { Field, Form, Formik } from 'formik';
import { Box, Link } from 'rebass/styled-components';
import * as Yup from 'yup';
import { PublishChanges } from 'components/atoms/publishChanges';
import NetworkSchema from '../../../../schemas/network';

const formSchema = Yup.object().shape({
  legalName: Yup.string().label('Legal business name').nullable().required(),
  legalEmail: Yup.string().email().label('Data controller email').nullable().required(),
  legalAddress: Yup.string().label('Business address').nullable().required(),
});

const PrivacyPolicy = ({ activeNetwork, generalSettings, fetchError, onSubmit, saveError, submitting }) => {
  const formRef = useRef(null);
  const errorMessage = saveError ? strings.genericError : null;
  const { url } = activeNetwork;

  if (fetchError) {
    return <MessageBlock>{strings.genericError}</MessageBlock>;
  }

  if (generalSettings === null) {
    return <Loader />;
  }

  const { legalName, legalEmail, legalAddress } = generalSettings;
  const initialValues = {
    legalName,
    legalEmail,
    legalAddress,
  };
  const emptyState = !legalName && !legalEmail && !legalAddress;

  const LABELS = {
    legalName: 'Legal business name',
    legalAddress: 'Business address',
    legalEmail: 'Data controller email',
  };

  const labelFor = (id) => LABELS[id];

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values) => {
        onSubmit(values).then(() => {
          toast(<PublishChanges domain={url} />, {
            icon: 'success',
            id: 'gdpr-settings-updated',
          });
        });
      }}
      enableReinitialize
    >
      {({ values }) => (
        <Form data-testid="network-settings-privacy-policy">
          {emptyState && (
            <Message mb="24px" type="warning" data-testid="empty-state-message">
              Fill in your data controller information below.
            </Message>
          )}

          <Field
            type="text"
            name="legalName"
            component={FormField}
            label={labelFor('legalName')}
            value={values.legalName}
          />
          <Field
            type="text"
            name="legalAddress"
            label={labelFor('legalAddress')}
            component={FormField}
            value={values.legalAddress}
          />
          <Field
            type="text"
            name="legalEmail"
            label={labelFor('legalEmail')}
            component={FormField}
            value={values.legalEmail}
          />
          {errorMessage && (
            <Box mt="24px">
              <Message type="error" content={errorMessage} />
            </Box>
          )}
          <Box mt="24px">
            <Button size="small" type="submit" loading={submitting}>
              Save
            </Button>
          </Box>
          <Box mt="24px">
            <Link
              href={`https://${url}/trust/privacy`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                fontSize: '15px',
                textDecoration: 'none',
                color: 'text.main',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              View your network&apos;s privacy policy
              <Box as={ExternalLink} strokeWidth="1.5" aria-hidden="true" width="16px" height="16px" />
            </Link>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

PrivacyPolicy.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
  fetchError: PropTypes.string,
  generalSettings: PropTypes.shape({
    legalName: PropTypes.string,
    legalEmail: PropTypes.string,
    legalAddress: PropTypes.string,
  }),
  saveError: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

PrivacyPolicy.defaultProps = {
  generalSettings: null,
  fetchError: null,
};

export default PrivacyPolicy;
