import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

export const ScrollBox = styled(Box)`
  overflow-y: scroll;
  overflow-x: ${({ overflowX }) => overflowX || 'hidden'};
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-bar {
    width: 3px;
  }
  scrollbar-width: 3px;
`;
