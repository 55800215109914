import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass/styled-components';
import { Input } from '@getro/rombo';

const MultiSelectInput = ({
  inputRef,
  onLoseFocus,
  selected,
  handleSearch,
  setShowDropDown,
  setSearch,
  useSearchAsTag,
  onSelect,
  onCloseDropDown,
  search,
  placeholder,
  disableSearch,
  disableSearchText,
  hasError,
}) => {
  if (disableSearch) {
    return (
      <Text fontSize="14px" color="text.placeholder">
        {disableSearchText}
      </Text>
    );
  }

  return (
    <Input
      ref={inputRef}
      autoFocus
      onBlur={() => {
        if (onLoseFocus) {
          onLoseFocus(selected.map((item) => item.value));
        }
      }}
      data-testid="multi-select-search"
      onChange={({ target }) => {
        setShowDropDown(true);
        handleSearch(target.value);
        setSearch(target.value);
      }}
      onKeyDown={({ target, key }) => {
        if (useSearchAsTag && key === 'Enter' && target.value.trim() && !hasError) {
          onSelect([...selected, { label: target.value, value: target.value }]);
          setSearch('');
          onCloseDropDown();
        }
      }}
      value={search}
      sx={{
        height: '32px',
        fontSize: '14px',
        outline: hasError ? 'none' : 'initial',
        borderColor: hasError ? 'red.500' : 'neutral.400',
      }}
      placeholder={placeholder}
    />
  );
};

MultiSelectInput.propTypes = {
  inputRef: PropTypes.object.isRequired,
  onLoseFocus: PropTypes.func,
  selected: PropTypes.array.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setShowDropDown: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  useSearchAsTag: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onCloseDropDown: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disableSearch: PropTypes.bool,
  disableSearchText: PropTypes.string,
  hasError: PropTypes.bool,
};

MultiSelectInput.defaultProps = {
  placeholder: '',
  disableSearch: true,
  disableSearchText: '',
  onLoseFocus: true,
  useSearchAsTag: false,
  hasError: false,
};

export default MultiSelectInput;
