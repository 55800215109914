import { getItem } from 'helpers/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { activeNetworkSelector } from 'redux/selectors';
import { useLazyGetContactByIdQuery } from 'services/contactsV2';
import { useGetSharedListContactByIdQuery } from 'services/sharedListV2';
import { useGetSharedListBySlugQuery } from 'services/sharedLists';

export const useGetContactDetailContactById = ({ contactId, type }) => {
  const isNotPublic = ['default', 'read', 'matches'].includes(type);
  const [getContact, { data: privateContact, isLoading: isFetchingPrivateContact }] = useLazyGetContactByIdQuery();
  const network = useSelector(activeNetworkSelector);

  useEffect(() => {
    if (type !== 'public') {
      getContact(contactId, false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { slug } = useParams();
  const {
    data: listInfo,
    isFetching: isFetchingList,
    status,
  } = useGetSharedListBySlugQuery(
    { slug },
    {
      skip: isNotPublic,
    },
  );

  const { data: publicContact, isLoading: isFetchingPublicContact } = useGetSharedListContactByIdQuery(
    {
      contactId,
      slug,
      password: listInfo?.sharedList?.passwordProtected
        ? getItem(`sharedListPwd-${slug}-${listInfo?.sharedList?.id}`)
        : '',
    },
    {
      skip: isNotPublic || isFetchingList,
    },
  );

  return {
    contact: type === 'public' ? publicContact : privateContact,
    isFetching: isFetchingPrivateContact || isFetchingPublicContact,
    status,
    network: network || listInfo?.sharedList?.collection,
  };
};
