import React from 'react';
import { Input, Loader, Message } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Search } from 'lucide-react';
import { Virtuoso } from 'react-virtuoso';
import propTypes from 'prop-types';
import { LoadMore } from 'components/atoms/LoadMore';
import { useJobForList } from './hooks/useJobForList';
import { JobListItem } from './components/jobListItem';

export const JobList = ({ onMatchSelected }) => {
  const {
    isLoadingJobs: isLoading,
    searchResults: jobs,
    showEmptyState,
    search,
    error,
    keywords,
    loadMore,
    isLoadingMoreMatches,
    hasMoreMatches,
  } = useJobForList();

  const parentRef = React.useRef();

  return (
    <Box ref={parentRef}>
      <Flex
        sx={{
          '&, & > div:first-child': {
            gap: '24px',
            flexDirection: 'column',
          },
        }}
      >
        <Box px="40px">
          <Flex sx={{ position: 'relative', zIndex: 5, alignItems: 'center', mb: '16px' }}>
            <Input
              value={keywords || ''}
              name="job"
              placeholder="Find jobs by title or company"
              onChange={({ target }) => search(target.value)}
              sx={{ pl: '32px', fontSize: '14px' }}
            />
            <Box
              sx={{ position: 'absolute', ml: '12px', color: 'text.placeholder', left: 0, top: '11px' }}
              as={Search}
              size="16px"
            />
          </Flex>
        </Box>

        {error && (
          <Message type="error" title="Invalid website format">
            There was an unexpected error, please try again or contact us.
          </Message>
        )}
      </Flex>
      {isLoading && (
        <Flex mt="40px">
          <Loader />
        </Flex>
      )}
      {!isLoading && showEmptyState && (
        <Flex mt="40px" mx="auto" maxWidth="300px" flexDirection="column" justifyContent="center" alignItems="center">
          <Text fontWeight="semibold">No posted jobs found</Text>
          <Text mt="8px" color="text.subtle" textAlign="center">
            Try changing your search or create a search for a private job.
          </Text>
        </Flex>
      )}

      {!isLoading && !showEmptyState && (
        <>
          <Box
            as={Virtuoso}
            style={{ height: 'calc(100vh - 300px)' }}
            totalCount={jobs?.length || 0}
            rangeChanged={({ endIndex }) => {
              if (endIndex === jobs.length - 1 && hasMoreMatches) {
                loadMore();
              }
            }}
            sx={{
              '& > div > div': {
                flexDirection: 'column',
                display: 'flex',
                gap: '16px',
              },
            }}
            itemContent={(index) => (
              <JobListItem
                data={jobs[index]}
                onClick={(data) => {
                  onMatchSelected({ ...data, matchType: 'job' });
                }}
              />
            )}
            components={{
              Footer:
                isLoadingMoreMatches && hasMoreMatches
                  ? () => (
                      <Box mt="40px">
                        <LoadMore />
                      </Box>
                    )
                  : null,
            }}
          />
        </>
      )}
    </Box>
  );
};

JobList.propTypes = {
  onMatchSelected: propTypes.func.isRequired,
};
