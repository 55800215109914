import React from 'react';
import { FormSectionTitle, FormSelect } from '@getro/rombo';
import { FastField } from 'formik';
import { Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const GeneralJobBoardSettings = ({ values, tabOrderOptionsToUse, heroOptions }) => (
  <Flex flexDirection="column" sx={{ gap: '16px' }}>
    <FormSectionTitle
      fontSize="12px"
      p="0px"
      pt="0px"
      sx={{ borderBottom: '0px', pb: '16px', pt: '0px', '& > div': { fontSize: '12px' } }}
    >
      General
    </FormSectionTitle>
    <FastField
      value={values.tabsOrder}
      options={tabOrderOptionsToUse}
      component={FormSelect}
      name="tabsOrder"
      label="Tabs order"
    />
    <FastField
      value={values.placement}
      options={heroOptions}
      component={FormSelect}
      name="placement"
      label="Numbers placement"
    />
  </Flex>
);

GeneralJobBoardSettings.propTypes = {
  values: PropTypes.object.isRequired,
  tabOrderOptionsToUse: PropTypes.array.isRequired,
  heroOptions: PropTypes.array.isRequired,
};
