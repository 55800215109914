import { useDispatch, useSelector } from 'react-redux';
import { toggleAllJobsSelections } from '../../../../hooks/useJobs/actions';
import { selectAllJobsSelector } from '../../../../hooks/useJobs/selectors';

export const useHeaderSelectAll = () => {
  const selectAllJobs = useSelector(selectAllJobsSelector);
  const dispatch = useDispatch();

  const onSelectAllClick = () => {
    dispatch(toggleAllJobsSelections({ selectAll: !selectAllJobs }));
  };

  return {
    onSelectAllClick,
    selectAllJobs,
  };
};
