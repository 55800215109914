import React, { useRef, useState } from 'react';
import { Button } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useHistory, Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import { ContactFooter } from 'components/atoms/ContactsFooter';
import { ContactsNoResults } from 'components/atoms/ContactsNoResult';
import { ContactDetail } from 'components/organisms/contactDetail';
import { ListRestart } from 'lucide-react';
import { ContactsImportBanner } from '../../components/molecules/contactsImportBanner';
import { ContactsReconnectBanner } from '../../components/molecules/contactsReconnectBanner';
import { ContactsUpgradeBanner } from '../../components/molecules/contactsUpgradeBanner';
import { ContactCard } from '../../components/organisms/contactCard';
import { ContactsFilters } from '../../components/organisms/contactsFilters';
import { defaultFilters } from '../../components/organisms/contactsFilters/defaultFilters';
import { trackEvent } from '../../helpers/analytics';
import useWindowSize from '../../hooks/useWindowSize';
import { MainSkeleton } from './skeletons';
import { BulkActions } from './bulkActions';
import { ContactsEmptyState } from './emptyState';
import { SharedListEmptyState } from './sharedListEmptyState';

export const ExtendedContact = ({
  onSetFilters,
  queryParams,
  selectedRows,
  setSelectedRows,
  onChangeSelectedContact,
  onLoadMore,
  data,
  isLoading,
  isFetching,
  meta,
  filtersCount,
  activeNetwork,
  showAllContactsEmptyState,
  listId,
  showAddToLisButton,
  showFiltersBottomBar,
  emptyState,
  showListFilters,
  list,
  deleteContactFromList,
  filtersTitle,
  bulkActions,
  page,
}) => {
  const filtersContainerRef = useRef();
  const history = useHistory();
  const { offsetLeft = 0 } = filtersContainerRef?.current || {};

  const [showAddNote, setShowAddNote] = useState(false);
  const route = useRouteMatch();

  useWindowSize();

  const onResetFilters = () => {
    onSetFilters(defaultFilters);
  };

  const onCreateList = () => {
    trackEvent('lists:create_list_click', {
      collection_id: activeNetwork.id,
      page: 'all_contacts',
      origin: 'filters_footer',
      object_type: 'people',
    });
    history.push(`/networks/${activeNetwork.slug}/contacts/lists/create-list${history.location.search}`, {
      filters: queryParams.filters,
    });
  };

  const params = useParams();

  const paths = [
    {
      exact: true,
      route: '/networks/:activeNetwork/contacts/:contactId',
      goBack: () => history.push(`/networks/${activeNetwork.slug}/contacts${history.location.search}`),
    },
    {
      exact: true,
      route: '/networks/:activeNetwork/list/:listId/add-contacts/:contactId',
      goBack: () =>
        history.push(`/networks/${activeNetwork.slug}/list/${params.listId}/add-contacts${history.location.search}`),
    },
    {
      exact: true,
      route: '/networks/:activeNetwork/list/:listId/contacts/:contactId',
      goBack: () =>
        history.push(`/networks/${activeNetwork.slug}/list/${params.listId}/contacts${history.location.search}`),
    },
  ];

  return (
    <Flex height="100%" flexDirection={['column', 'column', 'row']}>
      {(isLoading || data?.ids?.length > 0 || filtersCount > 0) && (
        <Box ref={filtersContainerRef} width={['100%', '100%', '280px']} flex="0 0 auto" sx={{ position: 'relative' }}>
          <ContactsFilters
            showListFilters={showListFilters}
            filters={queryParams.filters}
            setFilters={onSetFilters}
            sharedList={list}
            extendedMode
            filtersTitle={filtersTitle}
          />
          {showFiltersBottomBar && (
            <Box
              display={['none', 'none', 'block']}
              width="280px"
              pt="8px"
              sx={{
                overflow: 'hidden',
                position: 'fixed',
                left: offsetLeft,
                bottom: 0,
                transform: `translate3d(0,72px), 0)`,
              }}
            >
              <Flex
                bg="neutral.0"
                p="16px"
                sx={{
                  gap: '16px',
                  boxShadow: 'medium',
                  position: 'relative',
                  '&::after': {
                    content: "''",
                    width: '32px',
                    height: '10px',
                    background: 'rgb(255,255,255)',
                    // eslint-disable-next-line no-dupe-keys
                    background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
                    position: 'absolute',
                    top: '-10px',
                    left: 0,
                  },
                }}
              >
                <Button
                  sx={{
                    width: filtersCount > 0 ? 'auto' : '100%',
                    minWidth: '130px',
                    justifyContent: 'center',
                  }}
                  icon={ListRestart}
                  iconGap="8px"
                  size="small"
                  onClick={onCreateList}
                >
                  Create list
                </Button>
                {filtersCount > 0 && (
                  <Button
                    sx={{
                      '& > div > div': {
                        width: 'max-content',
                      },
                    }}
                    p="0"
                    size="small"
                    variant="tertiary"
                    onClick={onResetFilters}
                  >
                    Clear all filters
                  </Button>
                )}
              </Flex>
            </Box>
          )}
        </Box>
      )}
      <Box
        flexGrow="1"
        pt="24px"
        sx={{
          borderLeft: data?.ids?.length > 0 ? ['none', 'none', '1px solid'] : 'none',
          borderColor: data?.ids?.length > 0 ? ['none', 'none', 'border.subtle'] : 'none',
          minHeight: 'calc(100dvh - 203px)',
          pl: ['0', '0', '32px'],
        }}
      >
        <Flex flexDirection="column" sx={{ gap: '16px', '&& > div:last-child': { mb: '40px' } }}>
          <ContactsUpgradeBanner id="contacts" network={activeNetwork} />
          <ContactsImportBanner network={activeNetwork} />
          <ContactsReconnectBanner network={activeNetwork} />
        </Flex>
        {isLoading && (
          <Box>
            <MainSkeleton />
          </Box>
        )}
        {!isLoading && !isFetching && !showAllContactsEmptyState && data.meta?.queryTotal > 0 && (
          <Flex sx={{ alignItems: 'center', pb: '16px', gap: '8px' }}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="0">
                {`Showing ${
                  !isLoading && isFetching && queryParams.page === 1 ? '-' : data.meta?.queryTotal
                } ${pluralize(filtersCount ? 'results' : 'contacts', data.meta?.queryTotal)}`}
              </Text>
            </Flex>
            <Box
              as="p"
              sx={{
                textDecoration: 'underline',
                color: 'text.subtle',
                fontSize: '12px',
                lineHeight: '16px',
                cursor: 'pointer',
              }}
              setSelectedRows={setSelectedRows}
              onClick={() => {
                if (selectedRows.length === meta.queryTotal) {
                  setSelectedRows([]);
                  return;
                }
                setSelectedRows(Array.from(Array(meta.queryTotal).keys()));
              }}
            >
              {selectedRows.length === meta.queryTotal ? 'Clear selection' : 'Select all'}
            </Box>
          </Flex>
        )}
        {!isLoading && data?.ids?.length > 0 ? (
          <>
            <BulkActions
              actions={bulkActions}
              sharedList={list}
              queryParams={queryParams}
              contactIds={data?.ids}
              selectedRows={selectedRows}
              meta={meta}
              setSelectedRows={setSelectedRows}
              page={page}
            />
            {!isLoading && isFetching && queryParams.page === 1 ? (
              <Box>
                <MainSkeleton />
              </Box>
            ) : (
              <>
                <Flex
                  as={Virtuoso}
                  flex="1"
                  data={data.ids}
                  increaseViewportBy={400}
                  useWindowScroll
                  itemContent={(index, id) => (
                    <ContactCard
                      index={index}
                      queryParams={queryParams}
                      contact={data.entities[id]}
                      isSelected={selectedRows.includes(index)}
                      onChangeSelected={() => onChangeSelectedContact(index)}
                      showAddToLisButton={showAddToLisButton}
                      list={list}
                      deleteContactFromList={deleteContactFromList}
                      page={page}
                      setShowAddNote={setShowAddNote}
                      showDetail={() => {
                        if (page === 'list') {
                          history.push(
                            `/networks/${activeNetwork.slug}/list/${list.id}/contacts/${id}${history.location.search}`,
                          );
                          return;
                        }

                        if (page === 'list:add') {
                          history.push(
                            `/networks/${activeNetwork.slug}/list/${list.id}/add-contacts/${id}${history.location.search}`,
                          );
                          return;
                        }

                        history.push(`${route.path}/${id}${history.location.search}`);
                      }}
                    />
                  )}
                  components={{
                    // eslint-disable-next-line react/no-multi-comp
                    Footer: () => (
                      <ContactFooter
                        isLoading={isFetching}
                        allContactsCount={data.ids.length}
                        contactsTotal={data.meta?.queryTotal ?? 0}
                        filters={queryParams.filters}
                        isList={Boolean(listId)}
                      />
                    ),
                  }}
                  endReached={onLoadMore}
                />
              </>
            )}
          </>
        ) : null}
        {showAllContactsEmptyState && <ContactsEmptyState />}
        {!isLoading &&
          !isFetching &&
          !data?.ids?.length &&
          listId &&
          isEqual(defaultFilters, queryParams.filters) &&
          (emptyState || <SharedListEmptyState />)}
        {!isLoading && !data?.ids?.length && !isEqual(defaultFilters, queryParams.filters) && (
          <ContactsNoResults isList={Boolean(listId)} />
        )}
      </Box>
      <Switch>
        {paths.map(({ route: path, goBack, exact }) => (
          <Route path={path} exact={exact}>
            <ContactDetail
              showAddNote={showAddNote}
              queryParams={queryParams}
              isTalentNetworkEnabled={!activeNetwork?.tnHidden}
              list={list}
              onClose={() => {
                goBack();
                setShowAddNote(false);
              }}
              deleteContactFromList={deleteContactFromList}
              page={page}
            />
          </Route>
        ))}
      </Switch>
    </Flex>
  );
};

ExtendedContact.defaultProps = {
  listId: null,
  showAddToLisButton: false,
  emptyState: null,
  showListFilters: true,
  showFiltersBottomBar: false,
  list: null,
  meta: null,
  deleteContactFromList: false,
  filtersTitle: '',
  bulkActions: ['add-to-list', 'add-tag', 'export', 'delete', 'clear-selection'],
};

ExtendedContact.propTypes = {
  onSetFilters: PropTypes.func.isRequired,
  queryParams: PropTypes.shape({
    page: PropTypes.number,
    filters: PropTypes.object,
  }).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  onChangeSelectedContact: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  data: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number),
    entities: PropTypes.object,
    meta: PropTypes.object,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  meta: PropTypes.object,
  filtersCount: PropTypes.number.isRequired,
  activeNetwork: PropTypes.object.isRequired,
  showAllContactsEmptyState: PropTypes.bool.isRequired,
  listId: PropTypes.number,
  showAddToLisButton: PropTypes.bool,
  showFiltersBottomBar: PropTypes.bool,
  emptyState: PropTypes.node,
  showListFilters: PropTypes.bool,
  list: PropTypes.object,
  deleteContactFromList: PropTypes.bool,
  filtersTitle: PropTypes.string,
  bulkActions: PropTypes.array,
  page: PropTypes.string.isRequired,
};
