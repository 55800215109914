import React, { useEffect, useRef } from 'react';
import { Box, Flex } from 'rebass/styled-components';

export const JobAppearancePreviewDragger = () => {
  const ref = useRef();
  const countRef = useRef(0);
  const initialContainerWidthRef = useRef();
  const animationFrameId = useRef();

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX } = e;
      const container = document.querySelector('#job-appearance-preview');
      const containerPadding = 60;
      const sidebarWidth = 280;
      const space = document.body.getBoundingClientRect().width - sidebarWidth + containerPadding;

      const changeInWidth = clientX - countRef.current;
      let newWidth = initialContainerWidthRef.current + changeInWidth;

      if (newWidth > space) {
        newWidth = space;
      } else if (newWidth < 400) {
        newWidth = 400;
      }

      container.style.width = `${newWidth + 5}px`;
    };

    const handleMouseMoveThrottled = (e) => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      animationFrameId.current = requestAnimationFrame(() => handleMouseMove(e));
    };

    const handleMouseDown = (e) => {
      e.stopPropagation();
      if (e.target.id === 'job-appearance-preview-dragger') {
        countRef.current = e.clientX;
        initialContainerWidthRef.current = document
          .querySelector('#job-appearance-preview')
          .getBoundingClientRect().width;
        document.addEventListener('mousemove', handleMouseMoveThrottled);
        document.addEventListener('mouseup', handleMouseUp);
      }
    };

    const handleMouseUp = () => {
      countRef.current = 0;
      document.removeEventListener('mousemove', handleMouseMoveThrottled);
      document.removeEventListener('mouseup', handleMouseUp);
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousemove', handleMouseMoveThrottled);
      document.removeEventListener('mouseup', handleMouseUp);
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  return (
    <Flex
      ref={ref}
      id="job-appearance-preview-dragger"
      sx={{
        position: 'absolute',
        height: '40px',
        width: '16px',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
        bg: 'neutral.700',
        '& > div': {
          bg: 'neutral.100',
          pointerEvents: 'none',
        },
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2px',
        cursor: 'ew-resize',
        right: '-16px',
        bottom: '20px',
        transition: 'left 0.1s linear',
        top: '40%',
      }}
    >
      <Box width="1px" height="24px" />
      <Box width="1px" height="24px" />
    </Flex>
  );
};
