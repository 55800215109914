import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useLeaveBeforeSaving = (trigger = false) => {
  const history = useHistory();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (trigger) {
        event.preventDefault();
      }
    };

    const handleRouteChange = (location) => {
      if (
        trigger &&
        location.pathname !== history.location.pathname &&
        // eslint-disable-next-line no-alert
        !window.confirm('You have unsaved changes. Are you sure you want to leave?')
      ) {
        return false;
      }

      return true;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    const unblock = history.block(handleRouteChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblock();
    };
  }, [history, trigger]);
};
