import React from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import { Wand2 } from 'lucide-react';
import { AvatarGroup } from 'components/molecules/avatarGroup';
import { Loader } from '@getro/rombo';

export const CreateListItem = ({ job, createMatches, isCreatingList, onListClicked }) => {
  const isLoading = isCreatingList(job);
  const showList = !isLoading && job.sharedLists?.length > 0;
  const showMatches = !isLoading && job?.matches?.total > 0;
  const showCreateList = !isLoading && !showList && !showMatches;

  if (isLoading) return <Loader size="small" />;

  return (
    <Flex minWidth="300px" fontSize="14px" sx={{ flexDirection: 'column', gap: '8px', justifyContent: 'flex-start' }}>
      {!showList && showMatches && (
        <Flex alignItems="center" sx={{ gap: '4px' }}>
          <AvatarGroup matchList={job.matches.contacts} />
          <Box onClick={() => createMatches(job)} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
            Create list to see {job.matches.total} {pluralize('match', job.matches.total)}
          </Box>
        </Flex>
      )}
      {showList &&
        job.sharedLists.map((list) => (
          <Box>
            <Flex
              key={list.name + list.matchesCount}
              onClick={() => onListClicked(list)}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              <Box
                sx={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: '400',
                  fontSize: '14px',
                }}
              >
                {list.name}
              </Box>{' '}
              <Box>
                · {list.matchesCount} {pluralize('match', list.matchesCount)}
              </Box>
            </Flex>
          </Box>
        ))}
      {showCreateList && (
        <Flex sx={{ alignItems: 'center', gap: '8px' }}>
          <Box as={Wand2} size="16px" sx={{ color: 'purple.400' }} />
          <Box onClick={() => createMatches(job)} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
            Create list and get AI matches
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

CreateListItem.propTypes = {
  job: PropTypes.object.isRequired,
  createMatches: PropTypes.func,
  isCreatingList: PropTypes.func,
  onListClicked: PropTypes.func.isRequired,
};

CreateListItem.defaultProps = {
  createMatches: null,
  isCreatingList: null,
};
