import { mapCompanyHeadCountIntervals } from 'helpers/organizations';
import { useMemo } from 'react';
import { mapCompanyFundType } from 'services/organizations/organizationFundTypes';

export const useJobDetail = ({ locations, industryTags, job, organizationName, companyStage, companySize }) => {
  const location = useMemo(() => {
    const matchLocations = locations.map((item) => item.name);
    if (!matchLocations.length) return '';

    return matchLocations.reduce(
      (acc, item, index) => {
        if (item.toLowerCase() === 'remote') {
          acc.hasRemote = true;
        } else {
          acc.value = acc.value ? `${acc.value}; ${item}` : item;
        }

        if (index === matchLocations.length - 1 && acc.hasRemote) {
          acc.value += acc.value ? ' · Remote' : 'Remote';
        }

        return acc;
      },
      {
        value: '',
        hasRemote: false,
      },
    ).value;
  }, [locations]);

  const tags = industryTags.reduce((carry, tag) => {
    const description = typeof tag === 'string' ? tag : tag.description;

    return carry ? `${carry} · ${description}` : description;
  }, '');

  const jobDetail = {
    location: {
      value: location,
      label: 'Location',
      missingMessage: 'Missing job location',
      showSidebar: true,
    },
    companySize: {
      value: companySize ? `${mapCompanyHeadCountIntervals(companySize)} employees` : '',
      label: 'Company Size',
      missingMessage: 'Missing company size',
      showSidebar: true,
    },
    companyStage: {
      value: companyStage ? mapCompanyFundType(companyStage) : '',
      label: 'Company Stage',
      missingMessage: 'Missing company stage',
      showSidebar: true,
    },
    tags: {
      value: tags,
      label: 'Industries',
      missingMessage: 'Missing company industry',
      showSidebar: true,
    },
    description: {
      value: job?.description,
      label: 'Job Description',
      missingMessage: 'Missing job description',
      showSidebar: !job?.description,
    },
  };

  const missingMatchingDetails = Object.entries(jobDetail).some(([, value]) => !value.value);

  return {
    ...jobDetail,
    jobName: job?.title ? `${job?.title}${organizationName ? ` at ${organizationName}` : ''}` : '',
    missingMatchingDetails,
  };
};
