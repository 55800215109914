const getWordPosition = (query, wordIndex) => query.split(/\s+/).slice(0, wordIndex).join(' ').length;

const isWithinQuotes = (query, word, wordIndex) => {
  const beforeWord = query.substring(0, query.indexOf(word, getWordPosition(query, wordIndex)));
  const quoteCount = (beforeWord.match(/"/g) || []).length;
  return quoteCount % 2 !== 0;
};

export const isInvalidBooleanSearch = (query) => {
  const booleanOperators = ['AND', 'OR', 'NOT'];

  // Check for parentheses
  const hasParentheses = query.includes('(') || query.includes(')');
  const hasBooleanOperators = booleanOperators.some((op) => query.includes(op));

  if (!hasParentheses && !hasBooleanOperators) {
    return null;
  }

  // 1. Check for straight quotes only, no curly quotes
  if (/[\u201C\u201D]/.test(query)) {
    return 'Only straight quotation marks (") are allowed.';
  }

  // 2. Check for unsupported symbols
  const unsupportedSymbols = query.match(/[^a-zA-Z0-9\s@#()"']/g);
  if (unsupportedSymbols) {
    return `Unsupported symbols detected: ${unsupportedSymbols.join(', ')}.`;
  }

  // Check for invalid Boolean operator placement at start or end
  const trimmedQuery = query.trim();
  if (
    trimmedQuery.startsWith('OR') ||
    trimmedQuery.startsWith('AND') ||
    trimmedQuery.endsWith('OR') ||
    trimmedQuery.endsWith('AND')
  ) {
    return 'Boolean operators (AND, OR) cannot be at the start or end of a search.';
  }

  // 3. Check for uppercase Boolean operators usage
  const words = query.split(/\s+/);
  for (let i = 0; i < words.length; i += 1) {
    const word = words[i];
    // Only check words that aren't within quotes
    if (
      !isWithinQuotes(query, word, i) &&
      [...booleanOperators, '*'].includes(word.toUpperCase()) &&
      word !== word.toUpperCase()
    ) {
      return `Boolean operators (${booleanOperators.join(', ')}) must be in uppercase.`;
    }
  }

  // 4. Ensure proper use of parentheses and quotes
  const openParentheses = (query.match(/\(/g) || []).length;
  const closeParentheses = (query.match(/\)/g) || []).length;
  if (openParentheses !== closeParentheses) {
    return 'Mismatched parentheses.';
  }

  // Check for spaces in unquoted terms
  const terms = query.replace(/"[^"]*"/g, '').split(/\s*(?:AND|OR|NOT)\s*/);
  // eslint-disable-next-line no-restricted-syntax
  for (const term of terms) {
    const trimmedTerm = term.trim().replace(/[()]/g, '');
    if (trimmedTerm.includes(' ') && trimmedTerm.length > 0) {
      return 'Terms containing spaces must be wrapped in quotes.';
    }
  }

  const quoteCount = (query.match(/"/g) || []).length;
  if (quoteCount % 2 !== 0) {
    return 'Unmatched quotation marks.';
  }

  return null;
};

export const isBooleanSearch = (query) => {
  if (!query) return false;

  const booleanOperators = ['AND', 'OR', 'NOT'];
  const words = query.split(/\s+/);

  // Check for parentheses grouping
  const hasParentheses = /[()]/.test(query);
  if (hasParentheses) {
    return true;
  }

  // Check if any word matches a boolean operator exactly
  for (let i = 0; i < words.length; i += 1) {
    const word = words[i];
    if (!isWithinQuotes(query, word, i) && booleanOperators.includes(word)) {
      return true;
    }
  }

  // Check for quoted phrases
  const quoteCount = (query.match(/"/g) || []).length;
  if (quoteCount >= 2) {
    return true;
  }

  return false;
};
