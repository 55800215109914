import React from 'react';
import { FormSectionTitle, FormSelect, FormInput, Checkbox, FormLabel } from '@getro/rombo';
import { FastField } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { heroTextColor } from './options';

export const HeroContentSettings = ({ values, setFieldValue, isAdvancedDesign }) => {
  const booleanSettings = [
    {
      label: 'Show logo',
      name: 'hideLogoInHero',
      helpText: null,
      show: !isAdvancedDesign,
    },
    {
      label: 'Show network name',
      name: 'hideNameInHero',
      helpText: null,
      show: !isAdvancedDesign,
    },
    {
      label: 'Show company sign-up link',
      name: 'claimOrganizationProfileInHero',
      show: true,
    },
  ].filter((setting) => setting.show);

  return (
    <Flex flexDirection="column" sx={{ gap: '16px' }}>
      <FormSectionTitle p="0px" sx={{ borderBottom: '0px', pb: '16px', '& > div': { fontSize: '12px' } }}>
        Hero content
      </FormSectionTitle>
      <FastField
        value={values.heroTitle}
        component={FormInput}
        name="heroTitle"
        label="Hero title"
        placeholder="Enter title or leave empty"
      />
      <FastField
        value={values.heroSubtitle}
        component={FormInput}
        name="heroSubtitle"
        label="Hero subtitle"
        placeholder="Enter subtitle or leave empty"
      />
      <FastField
        value={values.heroTextColor}
        options={heroTextColor}
        component={FormSelect}
        name="heroTextColor"
        label="Hero text color"
      />
      {booleanSettings.map((option) => (
        <Flex
          key={option.name}
          sx={{
            label: { height: 'fit-content', pointerEvents: 'none' },
            cursor: 'pointer',
            '*': { cursor: 'pointer' },
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFieldValue(option.name, !values[option.name]);
          }}
        >
          <Checkbox checked={values[option.name]} name={option.name} />
          <Box sx={{ mt: '-2px' }}>
            <FormLabel>{option.label}</FormLabel>
          </Box>
        </Flex>
      ))}
    </Flex>
  );
};

HeroContentSettings.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isAdvancedDesign: PropTypes.bool.isRequired,
};
