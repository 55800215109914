import React, { useMemo } from 'react';
import { Box } from 'rebass/styled-components';
import { ListX } from 'lucide-react';

export const useActionItems = ({ removeSharedListDomains }) =>
  useMemo(
    () => [
      {
        title: 'Remove',
        children: (
          <>
            <Box as={ListX} mr="8px" height="16px" width="16px" aria-hidden="true" />
            Remove from list
          </>
        ),
        sx: { fontWeight: 400, fontSize: '14px', height: '32px' },
        onClick: (e) => {
          removeSharedListDomains(e.domain);
        },
      },
    ],
    [removeSharedListDomains],
  );
