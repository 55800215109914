import { trackEvent } from 'helpers/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { activeNetworkSelector } from 'redux/selectors';
import { useCreateSharedListMutation } from 'services/contacts';
import { useCallback, useState } from 'react';
import { toggleJobSelection } from 'hooks/useJobs/actions';
import useJobRules from 'hooks/jobRules/useJobRules';
import { useHeaderSelectAll } from './useHeaderSelectAll';

export const useJobTableFunctions = ({ onSort, jobs }) => {
  const history = useHistory();
  const [showJobRules, setShowJobRules] = useState(false);
  const [createSharedList, { isLoading, originalArgs }] = useCreateSharedListMutation();
  const activeNetwork = useSelector(activeNetworkSelector);
  const dispatch = useDispatch();
  const { loadJobRulesRequest } = useJobRules();

  const { onSelectAllClick, selectAllJobs } = useHeaderSelectAll();

  const createMatches = useCallback(
    async (job) => {
      const { data } = await createSharedList({
        name: job.title,
        matchingProject: {
          jobId: parseInt(job.id, 10),
        },
      });
      if (data?.sharedList?.id) {
        history.push(`/networks/${activeNetwork.slug}/list/${data.sharedList.id}/ai-matches/lists/settings`);
      }

      trackEvent('jobs:get_matches_click', {
        networkId: activeNetwork.id,
        jobId: job.id,
        organizationId: job.organization.id,
        networkUsingConnect:
          activeNetwork.features.includes('talent_network') ||
          (!activeNetwork.tnHidden && activeNetwork.features.includes('tn_onboarding_v2')),
      });
    },
    [activeNetwork.features, activeNetwork.id, activeNetwork.slug, activeNetwork.tnHidden, createSharedList, history],
  );

  const onSelect = useCallback(
    (job, checked, isAll) => {
      if (isAll) {
        onSelectAllClick();
      } else {
        dispatch(toggleJobSelection({ job, checked }));
      }
    },
    [onSelectAllClick, dispatch],
  );

  const handleSort = useCallback(
    (args) => {
      onSort(args);
      const [{ desc, column }] = args;

      trackEvent('table:sort', {
        networkId: activeNetwork.id,
        page: 'jobs',
        type: desc ? 'desc' : 'asc',
        columnName: column.title,
      });
    },
    [onSort, activeNetwork],
  );

  const onColumnAction = useCallback(
    (operation, { payload: column }) => {
      trackEvent('table:change_columns', {
        networkId: activeNetwork.id,
        page: 'jobs',
        operation,
        columnName: column.title,
      });
    },
    [activeNetwork],
  );

  const isCreatingList = useCallback(
    (job) => isLoading && originalArgs?.matchingProject?.jobId === job.id,
    [isLoading, originalArgs],
  );

  const getJobById = useCallback(
    (job) => jobs.find((j) => parseInt(j.attributes.id, 10) === parseInt(job?.id, 10))?.attributes ?? {},
    [jobs],
  );

  const isJobSourcePostedActiveOrExpired = useCallback(
    (e) => {
      const job = getJobById(e);
      if (job.source === 'posted') {
        if (job.status === 'active' || job.status === 'expired') {
          return false;
        }
      }
      return true;
    },
    [getJobById],
  );

  const onShowJobRulesClick = useCallback(() => {
    loadJobRulesRequest();
    setShowJobRules(true);
  }, [loadJobRulesRequest]);

  return {
    createMatches,
    onSelect,
    handleSort,
    onColumnAction,
    isCreatingList,
    selectAllJobs,
    getJobById,
    isJobSourcePostedActiveOrExpired,
    showJobRules,
    onShowJobRulesClick,
    setShowJobRules,
  };
};
