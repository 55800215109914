import React, { memo } from 'react';
import { FileText, List, Tag, Users } from 'lucide-react';
import { Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { trackEvent } from 'helpers/analytics';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import {
  useAddContactToSharedListMutation,
  useAddTagToContactsMutation,
  useAddTagToSingleContactMutation,
  useCreateSharedListMutation,
  useEditContactMutation,
  useRemoveContactFromSharedListMutation,
  useRemoveTagFromContactMutation,
} from '../../../services/contacts';
import { SearchImportersDropdown } from '../../molecules/searchImportersDropdown';
import { SearchSharedListsDropdown } from '../../molecules/searchSharedListsDropdown';
import { SearchTagsDropdown } from '../../molecules/searchTagsDropdown';
import { EditableAction } from './editableAction';
import { useCreateTagMutation } from '../../../services/tags';
import { EditableActionItem } from './editableActionItem';
import { EditableActionItemNotes } from './editableActionItemNotes';
import { ContactMoreActions } from './contactMoreActions';
import AddContactsToList from '../contactCard/addContactToList';

export const ContactActionPanel = memo(
  ({
    type,
    page,
    contact,
    matchId,
    queryParams,
    actions,
    extendMethods,
    showDetailView,
    showMoreOptions,
    list: sharedList,
    deleteContactFromList,
    fullContactView,
    addedToSharedList,
  }) => {
    const [editContact] = useEditContactMutation();
    const [addContactToSharedList] = useAddContactToSharedListMutation();
    const [removeContactFromSharedList] = useRemoveContactFromSharedListMutation();
    const [createSharedList] = useCreateSharedListMutation();
    const [addTagToContacts] = useAddTagToContactsMutation();
    const [addTagToSingleContacts] = useAddTagToSingleContactMutation();
    const [removeTagFromContact] = useRemoveTagFromContactMutation();
    const [createTag] = useCreateTagMutation();
    const activeNetwork = useSelector(activeNetworkSelector);

    const onChangeImporter = (importers) => {
      editContact({ queryParams, contactId: contact.id, values: { contactOf: importers } });
    };

    const onRemoveImporter = (importerOpt) => {
      onChangeImporter(contact.importers.filter((item) => item.id !== importerOpt.value));
    };

    const onChangeList = (sharedLists, opt, checked) => {
      if (checked) {
        addContactToSharedList({
          queryParams,
          contactId: contact.id,
          list: sharedLists.find((list) => list?.id === opt.value),
        });

        trackEvent('contacts:list:add_contact_single', {
          listId: opt.value,
          collectionId: activeNetwork?.id,
          origin: fullContactView ? 'full_profile_lists_management' : 'card_lists_management',
          page,
        });
      } else {
        removeContactFromSharedList({
          queryParams,
          contactId: contact.id,
          list: { id: opt.value },
        });

        trackEvent('contacts:list:remove_contact_single', {
          listId: opt.value,
          collectionId: activeNetwork?.id,
          origin: fullContactView ? 'full_profile_lists_management' : 'card_lists_management',
          page,
        });
      }
    };

    const onCreateList = async (listName) => {
      const { data } = await createSharedList({ name: listName });

      trackEvent('lists:create_list', {
        collection_id: activeNetwork.id,
        object_type: 'people',
      });
      addContactToSharedList({ queryParams, contactId: contact.id, list: data.sharedList });
    };

    const onRemoveList = (list) => {
      trackEvent('contacts:list:remove_contact_single', {
        listId: list.id,
        collectionId: activeNetwork?.id,
        origin: fullContactView ? 'full_profile_lists_management' : 'card_lists_management',
        page,
      });

      removeContactFromSharedList({
        queryParams,
        contactId: contact.id,
        list,
      });
    };

    const onChangeTag = (tags, opt, checked) => {
      if (checked) {
        addTagToSingleContacts({ contactId: contact.id, tag: tags.find((item) => item.id === opt.value), queryParams });
      } else {
        removeTagFromContact({ queryParams, tag: { id: opt.value }, contactId: contact.id });
      }
    };

    const onCreateTag = async (tagName) => {
      const { data } = await createTag({ tagName });

      addTagToContacts({ queryParams, tag: data.tag, contactIds: [contact.id] });
    };

    const onRemoveTag = (opt) => {
      removeTagFromContact({ queryParams, tag: { id: opt.value }, contactId: contact.id });
    };

    const formatAuthorFullname = (name) => {
      const tokens = name.split(' ');

      return `${tokens[0]}${tokens[1] ? ` ${Array.from(tokens[1])[0]}` : ''}.`;
    };

    const actionList = [
      {
        id: 'add-importer',
        icon: Users,
        label: 'Contact of',
        items: contact?.importers?.map((item) => ({ label: item.name, value: item.id })) ?? [],
        DropdownComponent: SearchImportersDropdown,
        EditableActionItemComponent: EditableActionItem,
        onChange: onChangeImporter,
        onRemove: onRemoveImporter,
        ...(extendMethods?.['add-importer'] ? { extendMethods: extendMethods['add-importer'] } : undefined),
      },
      {
        id: 'show-importers',
        icon: Users,
        label: 'Contact of',
        items: contact?.importers?.map((item) => ({ label: item.name, value: item.id })) ?? [],
        EditableActionItemComponent: EditableActionItem,
        isPublic: type === 'public',
      },
      {
        id: 'lists',
        icon: List,
        label: 'Lists',
        items: contact?.sharedList?.map((item) => ({ id: item.id, label: item.name, value: item.id })) || [],
        DropdownComponent: SearchSharedListsDropdown,
        EditableActionItemComponent: EditableActionItem,
        onChange: onChangeList,
        onRemove: onRemoveList,
        onCreate: onCreateList,
        ...(extendMethods?.lists ? { extendMethods: extendMethods.lists } : undefined),
      },
      {
        id: 'tags',
        icon: Tag,
        label: 'Tags',
        items: contact?.tags?.map((item) => ({ id: item.id, label: item.tag, value: item.id })) || [],
        DropdownComponent: SearchTagsDropdown,
        EditableActionItemComponent: EditableActionItem,
        onChange: onChangeTag,
        onRemove: onRemoveTag,
        onCreate: onCreateTag,
        ...(extendMethods?.tags ? { extendMethods: extendMethods.tags } : undefined),
      },
      {
        id: 'notes',
        icon: FileText,
        label: 'Notes',
        items: contact?.notes?.map((item) => ({
          id: item.id,
          label: `${formatAuthorFullname(item.author.fullName)}: ${item.title}`,
          value: item.id,
        })),
        EditableActionItemComponent: EditableActionItem,
        showDetailView,
        ...(extendMethods?.notes ? { extendMethods: extendMethods.notes } : undefined),
      },
      {
        id: 'notes-extended',
        icon: FileText,
        label: 'Notes',
        items: contact.notes,
        EditableActionItemComponent: EditableActionItemNotes,
        showDetailView,
        isPublic: type === 'public',
        ...(extendMethods?.['notes-extended'] ? { extendMethods: extendMethods['notes-extended'] } : undefined),
      },
    ];

    return (
      <Flex sx={{ gap: '16px', flexDirection: 'column' }}>
        {['default', 'matches'].includes(type) && sharedList?.id && !deleteContactFromList && (
          <AddContactsToList
            page={page}
            addedToSharedList={addedToSharedList}
            fullContactView={fullContactView}
            queryParams={queryParams}
            contact={contact}
            matchId={matchId}
            list={sharedList}
          />
        )}
        <Flex justifyContent="space-between" sx={{ position: 'relative' }}>
          <Flex flexDirection="column" sx={{ gap: '16px' }}>
            {actions.map((actionId) => {
              const action = actionList.find((item) => item.id === actionId);

              return action ? <EditableAction key={`panel-action-${action.id}`} action={action} /> : null;
            })}
          </Flex>
          {!['public', 'matches'].includes(type) && (
            <ContactMoreActions
              page={page}
              queryParams={queryParams}
              showMoreOptions={showMoreOptions}
              contact={contact}
              deleteContactFromList={deleteContactFromList}
              list={sharedList}
              fullContactView={fullContactView}
            />
          )}
        </Flex>
      </Flex>
    );
  },
);

ContactActionPanel.propTypes = {
  type: PropTypes.oneOf(['default', 'read', 'matches', 'public']),
  page: PropTypes.string,
  actions: PropTypes.array,
  queryParams: PropTypes.object,
  contact: PropTypes.object.isRequired,
  matchId: PropTypes.number,
  extendMethods: PropTypes.object,
  showDetailView: PropTypes.func,
  showMoreOptions: PropTypes.bool,
  list: PropTypes.object,
  deleteContactFromList: PropTypes.bool,
  fullContactView: PropTypes.bool,
  addedToSharedList: PropTypes.bool,
};

ContactActionPanel.defaultProps = {
  type: 'default',
  page: '',
  actions: ['add-importer', 'lists', 'tags', 'notes'],
  extendMethods: null,
  queryParams: {},
  matchId: null,
  showDetailView: null,
  showMoreOptions: false,
  list: null,
  deleteContactFromList: false,
  fullContactView: false,
  addedToSharedList: false,
};
