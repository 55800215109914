import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import qs from 'query-string';
import { setCookie } from 'nookies';
import { init as initCommandbar } from 'commandbar';

import { setActiveNetwork, setNetworks, setNetworksReady } from 'actions/networksActions';
import { getAndsetInitialUser, logOut, setUserReady } from 'actions/userActions';

import { trackException } from 'helpers/errorTracker';
import { storeItem, getItem } from 'helpers/store';
import history from 'createHistory';
import settings from 'settings';

import Loading from 'components/molecules/LoadingFullPage';
import Routes from 'router';

import { COMMAND_BAR_ID } from './helpers/constants';
import { getDomainName } from './helpers/urlHelpers';
import { useCommandBar } from './hooks/useCommandBar';
import { networksSelector, pageSelector, userSelector } from './redux/selectors';
import useCacheBuster from './hooks/useCacheBuster';

initCommandbar(COMMAND_BAR_ID);

// App bootstraps the application.
// It makes sure that user information and networks are ready before rendering anything.
const App = () => {
  const dispatch = useDispatch();
  const [bootstrapped, setBootstrapped] = useState(false);
  const { networks, active: activeNetworkId, ready: networksReady } = useSelector(networksSelector);
  const { title } = useSelector(pageSelector);
  const { ready: userReady } = useSelector(userSelector);

  useCacheBuster();
  useCommandBar();

  // 1. App first load: check if a valid token is present and get user information
  const getInitialDataSideEffect = async () => {
    const token = getItem('authToken');

    // If there is no token
    if (typeof token !== 'string' || token.length === 0) {
      dispatch(setUserReady(true));
      dispatch(setNetworksReady(true));
      return true;
    }

    if (!userReady) {
      try {
        const userAction = await getAndsetInitialUser();
        if (userAction) {
          dispatch(userAction);
          dispatch(setNetworks(userAction.payload));
        } else {
          logOut();
          dispatch(setUserReady(true));
          dispatch(setNetworksReady(true));
          history.push('/login');
        }
      } catch (error) {
        const invalidToken = error && error.response && error.response.status === 401;

        if (!invalidToken) {
          trackException(error);
        }

        logOut();
        dispatch(setUserReady(true));
        dispatch(setNetworksReady(true));
        history.push('/login');
      }
    }

    return true;
  };

  useEffect(() => {
    const { token, ...params } = qs.parse(history.location.search);

    if (token) {
      storeItem('authToken', token);
      setCookie(null, 'authToken', token, { path: '/', domain: getDomainName(), maxAge: 365 * 24 * 60 * 60 });

      history.push({
        search: qs.stringify(params),
      });
    }

    // Get initial user information
    getInitialDataSideEffect(dispatch, userReady, history);
    // eslint-disable-next-line
  }, [])

  // 2. Set an active network when networks list is ready
  // eslint-disable-next-line no-shadow
  const setInitialActiveNetworkSideEffect = (currentPath, historyState, networks) => {
    const slugsToIds = Object.values(networks).reduce((a, b) => ({ ...a, [b.slug]: b.id }), {});

    const currentSlug = currentPath.replace('/', '');
    if (slugsToIds[currentSlug]) {
      return slugsToIds[currentSlug];
    }

    const historySlug = historyState && historyState.from && historyState.from.pathname.replace('/', '');

    if (slugsToIds[historySlug]) {
      return slugsToIds[historySlug];
    }

    const firstNetwork = networks[Object.keys(networks)[0]];

    return firstNetwork ? firstNetwork.id : null;
  };

  useEffect(() => {
    // Do nothing if networks list is not ready or there is an active one
    if (!networks || activeNetworkId) {
      return;
    }

    // Get the network id to be set as active
    const newActiveNetworkId = setInitialActiveNetworkSideEffect(
      history.location.pathname,
      history.location.state,
      networks,
    );

    if (newActiveNetworkId) {
      dispatch(setActiveNetwork(newActiveNetworkId));
    } else {
      dispatch(setNetworksReady(true));
    }

    // eslint-disable-next-line
  }, [networks])

  // 3. Update page title when pageStore changes
  useEffect(() => {
    const activeNetworkName = networks && activeNetworkId && networks[activeNetworkId]?.name;

    if (!title) {
      document.title = settings.companyName;
    } else if (activeNetworkName) {
      document.title = `${title} | ${activeNetworkName} |  ${settings.companyName}`;
    } else {
      document.title = `${title} | ${settings.companyName}`;
    }
    // eslint-disable-next-line
  }, [title])

  // 4. Whenever user and networks are ready we're ready to start our application
  useEffect(() => {
    if (userReady && networksReady) {
      setBootstrapped(true);
    }
  }, [userReady, networksReady]);

  return bootstrapped ? <Routes /> : <Loading />;
};

export default Sentry.withProfiler(App);
