import { useEffect, useState, useRef } from 'react';
import { useTheme } from 'styled-components';

export const useLiveViewFrame = (customHero, props) => {
  const [ref, setRef] = useState(null);
  const [ready, setReady] = useState(false);
  const container = ref?.contentWindow?.document?.body;
  const theme = useTheme();
  const renderRef = useRef(null);

  useEffect(() => {
    const updateFrame = () => {
      if (container && renderRef.current) {
        Array.from(ref.contentWindow.document.head.querySelectorAll('style[data-styled]')).forEach((element) => {
          element.remove();
        });

        window.document.head.querySelectorAll('style[data-styled]').forEach((element) => {
          ref.contentWindow.document.head.appendChild(element.cloneNode(true));
        });
      }
    };

    if (container && !renderRef.current) {
      setTimeout(() => {
        ref.contentWindow.document.body.style.overflowY = 'hidden';
        ref.contentWindow.document.body.style.background = theme.colors.neutral[20];
        ref.contentWindow.document.body.style.margin = '0px';
        Array.from(window.document.head.children)
          .filter((child) => child.tagName !== 'STYLE')
          .forEach((child) => {
            ref.contentWindow.document.head.appendChild(child.cloneNode(true));
          });

        updateFrame();
        setReady(true);
        renderRef.current = true;
      }, 300);
    } else if (container && renderRef.current) {
      updateFrame();
    }
  }, [container, ref, theme.colors.neutral, props]);

  return { ref, ready, setRef, container: ref?.contentWindow?.document?.body };
};
