import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isNetworkAdminSelector } from 'hooks/usePermissions/selectors';
import { activeNetworkSelector } from 'redux/selectors';
import { JobsBulkActions } from '../jobsBulkActions';
import { JobsDataTable } from '../jobsDataTable';
import strings from '../../../strings';
import { qsParse } from '../../../helpers/queryString';
import useJobs from '../../../hooks/useJobs';
import OrganizationSchema from '../../../schemas/organization';
import AllFiltersModal from './allFiltersModal';
import KeywordFilter from './keywordFilter';
import CompanyFilter from './companyFilter';
import StatusFilter from './statusFilter';
import VisibilityFilter from './visibilityFilter';
import ExpirationFilter from './expirationFilter';

const JobsTableWithFilters = ({ company, source }) => {
  const history = useHistory();
  const hideCompanyFilter = useMemo(() => parseInt(company?.id, 10) === company?.id, [company]);
  const isNetworkAdmin = useSelector(isNetworkAdminSelector);
  const activeNetwork = useSelector(activeNetworkSelector);
  const showListColumn = activeNetwork.features.includes('add_contacts') && isNetworkAdmin;

  const {
    jobs,
    totalJobs,
    isJobsLoading,
    hasJobsInitializationFailed,
    isJobsInitialized,
    loadNextPage,
    jobsFilters,
    hasJobsFilters,
    hasMoreJobs,
    statusOptions,
    visibilityOptions,
    expirationOptions,
    filterOrSortJobs,
    updateQueryString,
    initialSortBy,
    sourceOptions,
    seniorityOptions,
    featuredOptions,
    equityOptions,
  } = useJobs({ forCompany: company, includeMatchesLists: showListColumn });
  const location = useLocation();
  const initSearch = useMemo(() => qsParse(location).q || '', [location]);
  const [search, setSearch] = useState(initSearch);

  useEffect(() => {
    setSearch(initSearch);
  }, [initSearch]);

  const filterBySearchTerm = useMemo(() => {
    const onSearchChange = (v) => {
      const value = v.trim();
      filterOrSortJobs({ search: updateQueryString({ filter: 'q', value }) });
    };

    return debounce(300, onSearchChange);
  }, [filterOrSortJobs, updateQueryString]);

  const onFilterChange = ({ filter, value }) => {
    filterOrSortJobs({ search: updateQueryString({ filter, value: value.toString() }) });
  };

  const onSort = useCallback(
    (c) => {
      const { id, desc } = c[0];
      const params = qsParse(location);
      params.sortBy = id;
      params.sortDirection = !desc ? 'asc' : 'desc';
      filterOrSortJobs({ search: params });
    },
    [filterOrSortJobs, location],
  );

  const emptyView = useMemo(() => {
    if (hasJobsInitializationFailed) {
      return (
        <Flex width={1} justifyContent="center" mt={5}>
          <Text>{strings.genericError}</Text>
        </Flex>
      );
    }

    if (hasJobsFilters) {
      return (
        <Flex width={1} justifyContent="center" mt={5} flexDirection="column">
          <Text mx="auto" mb={[3]} fontWeight="semibold">
            No search results
          </Text>
          <Text mx="auto">There are no job openings with this criteria, try changing your filters.</Text>
        </Flex>
      );
    }

    return (
      <Flex width={1} justifyContent="center" mt={5}>
        <Text>There are no posted jobs for the network at this time</Text>
      </Flex>
    );
  }, [hasJobsFilters, hasJobsInitializationFailed]);

  return (
    <>
      <Flex
        mb={3}
        justifyContent="stretch"
        flexDirection={['column', 'column', 'row']}
        sx={{ position: 'relative', zIndex: 2 }}
      >
        <Flex width={[1, 1, 'auto']} mr={[0, 0, 2]} mb={[2, 2, 0]} flexGrow={1}>
          <KeywordFilter
            showLabel={false}
            value={search}
            onChange={(e) => {
              const { value } = e.target;
              setSearch(value);
              filterBySearchTerm(value);
            }}
          />
        </Flex>
        <Flex width={[1, 1, 'fit-content']} alignItems="center" flexGrow={1}>
          {!hideCompanyFilter && (
            <Box display={['none', 'block']} flexGrow={2} mr={2}>
              <CompanyFilter
                showLabel={false}
                onChange={({ value }) => {
                  onFilterChange({ filter: 'companyId', value: value || '' });
                }}
                value={jobsFilters.companyId}
              />
            </Box>
          )}
          <Box display={['none', 'block']} mr={2} flexGrow={1}>
            <StatusFilter
              showLabel={false}
              onChange={({ value }) => {
                onFilterChange({ filter: 'status', value: value || '' });
              }}
              statusOptions={statusOptions}
              value={jobsFilters.status}
            />
          </Box>
          <Box display={['none', 'block']} width="140px" mr={2}>
            <VisibilityFilter
              showLabel={false}
              onChange={({ value }) => {
                onFilterChange({ filter: 'visibility', value: value || '' });
              }}
              value={jobsFilters.visibility}
              visibilityOptions={visibilityOptions}
            />
          </Box>
          <Box display={['none', 'block']} width="140px" mr={2}>
            <ExpirationFilter
              showLabel={false}
              value={jobsFilters.expirationWithin}
              onChange={({ value }) => {
                onFilterChange({ filter: 'expirationWithin', value: value || '' });
              }}
              expirationOptions={expirationOptions}
            />
          </Box>
          <AllFiltersModal
            jobsFilters={jobsFilters}
            filterOrSortJobs={filterOrSortJobs}
            sourceOptions={sourceOptions}
            statusOptions={statusOptions}
            visibilityOptions={visibilityOptions}
            seniorityOptions={seniorityOptions}
            featuredOptions={featuredOptions}
            expirationOptions={expirationOptions}
            equityOptions={equityOptions}
            hideCompanyFilter={hideCompanyFilter}
            company={company}
          />
        </Flex>
      </Flex>

      <JobsBulkActions source={source} company={company}>
        <JobsDataTable
          initialSortBy={initialSortBy}
          onSort={onSort}
          total={totalJobs}
          history={history}
          isJobsLoading={isJobsLoading}
          isJobsInitialized={isJobsInitialized}
          totalJobs={totalJobs}
          loadNextPage={loadNextPage}
          jobs={jobs}
          hasMoreJobs={hasMoreJobs}
          emptyView={emptyView}
          source={source}
          showListColumn={showListColumn}
        />
      </JobsBulkActions>
    </>
  );
};

JobsTableWithFilters.propTypes = {
  company: OrganizationSchema,
  source: PropTypes.string,
};

JobsTableWithFilters.defaultProps = {
  company: null,
  source: 'jobs',
};

export default JobsTableWithFilters;
