import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import { Box } from 'rebass/styled-components';

import { useInjectReducer } from 'hooks/useInjectReducer';
import { useInjectSaga } from 'hooks/useInjectSaga';
import NoAccess from 'components/atoms/NoAccess';
import toast from 'react-hot-toast';
import { PublishChanges } from 'components/atoms/publishChanges';
import { useUpdateGeneralSettingsMutation } from 'services/network';
import GeneralSection from '../components/General';
import { loadGeneralSettings } from '../actions';
import reducer from '../reducer';
import saga from '../saga';
import {
  key,
  makeSelectGeneralSettings,
  makeSelectGeneralSettingsLoaded,
  makeSelectGeneralSettingsError,
  makeSelectGeneralSettingsSaveError,
  makeSelectGdprSettingsSaveSucceed,
} from '../selectors';
import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import usePageHook from '../../../hooks/usePageHook';

const pageTitle = 'Network general settings';

const General = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const activeNetwork = useSelector(activeNetworkSelector);
  const generalSettingsLoaded = useSelector(makeSelectGeneralSettingsLoaded);
  const generalSettingsError = useSelector(makeSelectGeneralSettingsError);
  const generalSettings = useSelector(makeSelectGeneralSettings);
  const generalSettingsSaveError = useSelector(makeSelectGeneralSettingsSaveError);
  const gdprSettingsSaveSucceed = useSelector(makeSelectGdprSettingsSaveSucceed);
  const { id: active } = activeNetwork;
  const [, setEditingGdprSettings] = useState(false);
  const [loadSaveGeneralSettings, { isLoading: isSavingGeneralSettings, isError }] = useUpdateGeneralSettingsMutation();

  usePageHook(pageTitle);

  useEffect(() => {
    if (!generalSettingsLoaded && !generalSettingsError) {
      dispatch(loadGeneralSettings({ networkId: active }));
    }
  }, [active, dispatch, generalSettingsError, generalSettingsLoaded]);

  useEffect(() => {
    if (gdprSettingsSaveSucceed) {
      setEditingGdprSettings(false);
    }
  }, [gdprSettingsSaveSucceed]);

  const updateGeneralSettings = async (values) => {
    const { error } = await loadSaveGeneralSettings(values);

    if (error) {
      return;
    }

    toast(<PublishChanges domain={activeNetwork.url} />, {
      icon: 'success',
      id: 'general-settings-updated',
    });
  };

  if (!activeNetwork.isManager) {
    return <NoAccess cy="settings-unavailable" />;
  }

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader title={pageTitle} description="Manage your network settings." />
      <Box mt="32px">
        <GeneralSection
          activeNetwork={activeNetwork}
          generalSettings={generalSettings}
          fetchError={isError}
          saveError={generalSettingsSaveError}
          submitting={isSavingGeneralSettings}
          onSubmit={updateGeneralSettings}
        />
      </Box>
    </Box>
  );
};

export default General;
