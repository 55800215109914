import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasImpactAnalyticsEnabledSelector, networksSelector } from '../../redux/selectors';

const Home = () => {
  const networksStore = useSelector(networksSelector);
  const { networks, active } = networksStore;
  const isImpactAnalyticsEnabled = useSelector(hasImpactAnalyticsEnabledSelector);

  const pathname = useMemo(() => {
    if (Object.keys(networks).length) {
      const key = active || Object.keys(networks)[0];
      const activeNetwork = networks[key];

      if (isImpactAnalyticsEnabled) {
        return `/networks/${activeNetwork.slug}/home`;
      }

      // If network is using the talent_network, go to Explore Members
      if (
        activeNetwork.isManager ||
        activeNetwork.features.includes('talent_network') ||
        activeNetwork.connectTier === 'premium'
      ) {
        return `/networks/${activeNetwork.slug}`;
      }

      // If user is managing an organization, go to the organization profile
      if (Object.keys(activeNetwork.isOrganizationManager).length) {
        const organizationKey = Object.keys(activeNetwork.isOrganizationManager)[0];
        const organization = activeNetwork.isOrganizationManager[organizationKey];
        return `/networks/${activeNetwork.slug}/companies/${organization.slug}`;
      }
    }

    return `/settings/account-my-profile`;
  }, [active, isImpactAnalyticsEnabled, networks]);

  return <Redirect to={{ pathname }} />;
};

export default Home;
