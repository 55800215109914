import React from 'react';
import { Box, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const OptionItem = ({ option, index, currentOption, showSearch, toggle, inputRef }) => (
  <Box
    data-testid={`option-${option.value}`}
    id={`option-${index}`}
    sx={{
      cursor: option.disabled ? 'auto' : 'pointer',
      label: {
        '& > div:last-child': {
          lineHeight: '1.4',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: '100%',
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': '2',
          m: 0,
        },
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
      },
    }}
    onClick={(e) => {
      if (showSearch) {
        inputRef.current.querySelector('input')?.focus();
      }

      e.preventDefault();
      e.stopPropagation();

      if (!option.disabled) {
        toggle(option);
      }
    }}
    key={option.value}
  >
    <Box
      sx={{
        minHeight: '28px',
        px: '16px',
        pt: '8px',
        bg: currentOption === index ? 'neutral.20' : 'transparent',
        '&:hover': {
          backgroundColor: 'neutral.20',
        },
      }}
    >
      <Text
        as="p"
        sx={{
          fontSize: '14px',
          color: option.disabled ? 'text.placeholder' : 'text.main',
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        }}
      >
        {option.children ?? option.label}
      </Text>
      {option.description && (
        <Text
          as="p"
          sx={{
            fontSize: '14px',
            color: option.disabled ? 'text.placeholder' : 'text.subtle',
            '&:first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {option.description}
        </Text>
      )}
    </Box>
  </Box>
);

OptionItem.propTypes = {
  option: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  currentOption: PropTypes.number.isRequired,
  showSearch: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  inputRef: PropTypes.object.isRequired,
};
