import { useEffect, useMemo, useRef, useState } from 'react';

export const useElementResize = () => {
  const ref = useRef(null);
  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = (entries) => {
      entries.forEach((entry) => {
        setDimension({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
          inlineWidth: entry.borderBoxSize[0].inlineSize,
        });
      });
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    const currentRef = ref.current;

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, []);

  const mode = useMemo(() => {
    if (dimension.width < 639) return 'mobile';
    if (dimension.width >= 640 && dimension.width <= 991) return 'tablet';
    return 'desktop';
  }, [dimension.width]);

  return [ref, { dimension, mode }];
};
