import React from 'react';
import { Button } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import { EyeOff, Link, MoreHorizontal, RefreshCcw, Trash } from 'lucide-react';
import PropTypes from 'prop-types';

import { Card } from '../../../components/atoms/Card';
import { Dropdown } from '../../../components/molecules/dropdown';
import { RefreshContactModal } from '../../../components/molecules/refreshContactModal';
import { DeleteContactModal } from '../../../components/organisms/contactActionPanel/deleteContactModal';
import useContactActionMenu from '../hooks/useContactActionMenu';

const ContactActionMenu = React.memo(({ contact, queryParams, network, companyId, sharedList }) => {
  const {
    onCopyContactLink,
    openDeleteModal,
    openRefreshModal,
    trackRefreshEvent,
    setOpenDeleteModal,
    setOpenRefreshModal,
    isLoadingDelete,
    onDeleteContact,
    onExcludeContactFromList,
  } = useContactActionMenu({ contact, queryParams, network, companyId, sharedList });

  return (
    <Box
      sx={{
        minWidth: '24px',
        maxHeight: '24px',
        height: '24px',
        justifyContent: 'center',
        cursor: 'pointer',
        alignItems: 'center',
      }}
      tx="dataTable"
      data-testid="row-action"
      display="flex"
    >
      <Dropdown
        autoPlacement
        trigger={
          <Box
            size="16px"
            variant="icon"
            sx={{ stroke: 'text' }}
            strokeWidth="1.5"
            aria-hidden="true"
            as={MoreHorizontal}
          />
        }
      >
        <Card variant="card.default" sx={{ position: 'fixed', width: '248px' }} py={2} px={0}>
          <Flex flexDirection="column" width="248px">
            <Button
              icon={Link}
              width={1}
              textAlign="left"
              variant="tertiary"
              size="small"
              pl={3}
              sx={{ fontWeight: 400 }}
              onClick={onCopyContactLink}
            >
              Copy contact link
            </Button>
            <Button
              icon={RefreshCcw}
              width={1}
              textAlign="left"
              variant="tertiary"
              size="small"
              pl={3}
              sx={{ fontWeight: 400 }}
              onClick={() => setOpenRefreshModal(true)}
            >
              Refresh profile
            </Button>
            <Box px="16px">
              <Box as="hr" variant="hr" />
            </Box>
            <Button
              icon={EyeOff}
              width={1}
              textAlign="left"
              variant="tertiary"
              size="small"
              pl={3}
              sx={{ fontWeight: 400 }}
              onClick={onExcludeContactFromList}
            >
              Exclude from this list
            </Button>
            <Box px="16px">
              <Box as="hr" variant="hr" />
            </Box>
            <Button
              icon={Trash}
              width={1}
              textAlign="left"
              variant="tertiary"
              size="small"
              pl={3}
              sx={{ fontWeight: 400 }}
              onClick={() => setOpenDeleteModal(true)}
            >
              Delete contact
            </Button>
          </Flex>
        </Card>
      </Dropdown>
      {openDeleteModal && (
        <DeleteContactModal
          onCloseModal={() => setOpenDeleteModal(false)}
          onDeleteContact={onDeleteContact}
          deleting={isLoadingDelete}
        />
      )}
      {openRefreshModal && (
        <RefreshContactModal
          trackRefreshEvent={trackRefreshEvent}
          linkedinUrl={contact?.linkedinUrl}
          onCloseModal={() => setOpenRefreshModal(false)}
        />
      )}
    </Box>
  );
});

ContactActionMenu.propTypes = {
  contact: PropTypes.object.isRequired,
  queryParams: PropTypes.object.isRequired,
  network: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  sharedList: PropTypes.object.isRequired,
};

export default ContactActionMenu;
