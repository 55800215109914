import { Button } from '@getro/rombo';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { activeNetworkSelector } from '../../../redux/selectors';
import { trackContactFiltersEvent } from '../../../services/contacts/filters';
import { Filters } from './filters';
import { FiltersModal } from './filtersModal';
import { defaultFilters } from './defaultFilters';

export const CompanyFilters = memo(
  ({
    filters,
    filterCount,
    setFilters,
    extendedMode,
    trackingLocation,
    sharedList,
    showListFilters,
    filtersTitle,
    setFiltersVisible,
    saveFilters,
  }) => {
    const activeNetwork = useSelector(activeNetworkSelector);
    const setMultipleFilters = (group, options = {}) => {
      const { removeFilters = [] } = options;
      const cleanFilters = Object.keys(group).reduce((arr, key) => {
        if (group[key]?.value === 'any') {
          removeFilters.push(key);
          return arr;
        }

        arr[key] = group[key]; // eslint-disable-line no-param-reassign
        return arr;
      }, {});
      const newFilters = { ...filters, ...cleanFilters };

      [...new Set(removeFilters)].forEach((item) => {
        delete newFilters[item];
      });

      setFilters(newFilters);

      trackContactFiltersEvent({
        group,
        activeNetwork,
        trackingLocation,
        sharedList,
        newFilters,
      });
    };

    const clearFundFilters = () => {
      setFilters({
        ...filters,
        stageTransitions: [],
        activePositionsOrganizationStages: [],
        companyStages: [],
      });
    };

    const onResetFilters = () => {
      setFilters(defaultFilters);
    };

    return (
      <>
        <Box
          data-testid="company-filters"
          px={[0, 0, '16px']}
          width="296px"
          flex="0 0 auto"
          display={['none', 'none', 'block']}
          sx={{
            mb: ['40px', '40px', 0],
            position: ['relative', 'relative', 'sticky'],
            top: 0,
            height: ['auto', 'auto', 'calc(100vh - 124px)'],
            overflowY: ['visible', 'visible', 'auto'],
          }}
        >
          {!!filtersTitle && (
            <Box
              display={['none', 'none', 'flex']}
              pt="4px"
              pb="8px"
              sx={{
                color: 'text.subtle',
                fontSize: '12px',
                textTransform: 'uppercase',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Text
                data-testid="contact-filters-title"
                sx={{
                  textTransform: 'uppercase',
                  color: 'text.subtle',
                  fontSize: '12px',
                  letterSpacing: '1px',
                }}
              >
                {filtersTitle}
              </Text>
            </Box>
          )}
          <Box pb="70px">
            <Filters
              sharedList={sharedList}
              filters={filters}
              filterCount={filterCount}
              setFilters={setMultipleFilters}
              extendedMode={extendedMode}
              isTalentNetworkEnabled={!activeNetwork?.tnHidden}
              showListFilters={showListFilters}
              clearFundFilters={clearFundFilters}
            />
          </Box>
          <Flex
            alignItems="center"
            bg="neutral.0"
            py="16px"
            sx={{ gap: '8px', position: 'absolute', bottom: 0, left: '16px', right: '16px' }}
          >
            <Button size="small" onClick={() => saveFilters(filters)}>
              Save changes
            </Button>
            {filterCount > 0 && (
              <Button variant="tertiary" size="small" px="8px" onClick={onResetFilters}>
                Clear all filters
              </Button>
            )}
          </Flex>
        </Box>
        <FiltersModal
          sharedList={sharedList}
          filters={filters}
          filterCount={filterCount}
          setFilters={setMultipleFilters}
          extendedMode={extendedMode}
          isTalentNetworkEnabled={!activeNetwork?.tnHidden}
          onClose={() => setFiltersVisible(false)}
          resetFilters={onResetFilters}
          showListFilters={showListFilters}
          clearFundFilters={clearFundFilters}
        />
      </>
    );
  },
);

CompanyFilters.propTypes = {
  sharedList: PropTypes.object,
  extendedMode: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  filterCount: PropTypes.number,
  setFilters: PropTypes.func.isRequired,
  trackingLocation: PropTypes.oneOf(['admin_portal', 'shared_list']),
  showListFilters: PropTypes.bool,
  filtersTitle: PropTypes.string,
  setFiltersVisible: PropTypes.func.isRequired,
  saveFilters: PropTypes.func,
};

CompanyFilters.defaultProps = {
  filterCount: 0,
  sharedList: null,
  extendedMode: false,
  trackingLocation: 'admin_portal',
  showListFilters: true,
  filtersTitle: '',
  saveFilters: () => {},
};
