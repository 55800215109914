import { ArrowUpDown } from 'lucide-react';
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass/styled-components';
import { Dropdown, Select } from '@getro/rombo';

export const TableSort = ({ columns, selectedColumn, setSelectedColumn, selectedSort, setSelectedSort }) => (
  <Dropdown
    placement="bottom-end"
    triggerProps={{
      variant: 'tertiary',
      display: 'unset',
      sx: {
        '&[data-is-open=true]': {
          bg: 'neutral.20',
        },
      },
      children: (
        <Flex sx={{ alignItems: 'center', gap: '8px' }}>
          <Box as={ArrowUpDown} size="16px" />
          <Text
            display={['none', 'none', 'block']}
            sx={{ fontSize: '14px', fontWeight: 500, color: 'text.main', lineHeight: '16px' }}
          >
            Sort
          </Text>
        </Flex>
      ),
    }}
  >
    <Flex
      sx={{
        p: '8px',
        width: '240px',
        boxShadow: '0px 2px 32px 0px #1B24320F, 0px 1px 4px 0px #1B24320F',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Select
        size="small"
        options={columns.map((column) => ({
          value: column,
          label: column.Header,
        }))}
        onChange={(value) => setSelectedColumn(value)}
        value={selectedColumn}
      />
      <Select
        size="small"
        sx={{ mt: '8px' }}
        options={[
          { value: 'asc', label: 'Ascending' },
          { value: 'desc', label: 'Descending' },
        ]}
        onChange={(value) => setSelectedSort(value)}
        value={selectedSort}
      />
    </Flex>
  </Dropdown>
);

TableSort.propTypes = {
  columns: PropTypes.array.isRequired,
  selectedColumn: PropTypes.string.isRequired,
  setSelectedColumn: PropTypes.func.isRequired,
  selectedSort: PropTypes.object.isRequired,
  setSelectedSort: PropTypes.func.isRequired,
};
