export const getJobsColumns = (columnsToHide) =>
  [
    {
      Header: 'Title',
      accessor: 'title',
      width: '360px',
      disableSortBy: true,
    },
    {
      Header: 'Company',
      accessor: 'company',
      width: '200px',
      disableSortBy: true,
    },
    {
      Header: 'Location',
      accessor: 'location',
      width: '200px',
      disableSortBy: true,
    },
    {
      Header: 'Compensation',
      accessor: 'compensation',
      width: '296px',
      disableSortBy: true,
    },
    {
      Header: 'Source',
      accessor: 'source',
      width: '200px',
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: '200px',
      disableSortBy: true,
    },
    {
      Header: 'Visibility',
      accessor: 'visibility',
      width: '200px',
      disableSortBy: true,
    },
    {
      Header: 'Featured',
      accessor: 'featured',
      width: '200px',
      disableSortBy: true,
    },
    {
      Header: 'List & ai matches',
      accessor: 'list',
      width: '240px',
      disableSortBy: true,
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      width: '200px',
    },
    {
      Header: 'Applicants',
      accessor: 'applicants',
      disableSortBy: true,
      width: '200px',
    },
    {
      Header: 'Expiring',
      accessor: 'expirationDate',
      width: '200px',
    },
  ].filter((column) => !columnsToHide.has(column.accessor));
