import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore from 'redux/store';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { Box } from 'rebass/styled-components';

import 'react-dates/initialize';
import * as serviceWorker from 'serviceWorker';
import './vendors/segment';
import { loadErrorTracker } from 'helpers/errorTracker';
import { loadChat } from 'helpers/supportChat';
import history from 'createHistory';
import { EOnboardingContext } from 'router/wrappers/EmployerOnboarding';

import './index.scss';
import App from 'App';
import { Toast } from './components/atoms/toast';
import { setStore } from './helpers/analytics';
import theme from './theme';
import { AnalyticsTracking } from './pages/analyticsTracking';
import 'react-popper-tooltip/dist/styles.css';

loadErrorTracker();
loadChat(true);

const initialState = {};
const store = configureStore(initialState, history);
setStore(store);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <StyleSheetManager disableCSSOMInjection>
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <AnalyticsTracking>
            <ConnectedRouter history={history}>
              <EOnboardingContext>
                <App />
                <Box id="portals" />
                <Toast />
              </EOnboardingContext>
            </ConnectedRouter>
          </AnalyticsTracking>
        </Provider>
      </Sentry.ErrorBoundary>
    </StyleSheetManager>
  </ThemeProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
