import React from 'react';
import { createPortal } from 'react-dom';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const IFrame = ({ children, ready, setRef, container }) => (
  <Box
    as="iframe"
    ref={setRef}
    sx={{
      visibility: ready ? 'visible' : 'hidden',
      width: '100%',
      height: '100%',
      border: 'none',
      overflowY: 'hidden',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      overflow: 'hidden',
      pointerEvents: 'none',
    }}
  >
    {container && createPortal(children, container)}
  </Box>
);

IFrame.propTypes = {
  children: PropTypes.node.isRequired,
  ready: PropTypes.bool.isRequired,
  setRef: PropTypes.func.isRequired,
  container: PropTypes.object,
};

IFrame.defaultProps = {
  container: null,
};
