import React from 'react';
import { Radio } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { PrivateList } from './privateList';
import { JobList } from './jobList';

export const AiMatches = ({ onMatchSelected, matchType, matchId, setMatchType, setStep, match }) => (
  <>
    <Box px="40px">
      <Box as="p" fontSize="14px" lineHeight="20px" mb="32px">
        Enter the details to fit your ideal candidate. This will influence AI matches but won’t alter your job board
        profile.
      </Box>
      <Flex sx={{ gap: '16px', flexDirection: 'column', pb: '16px' }}>
        <Flex sx={{ gap: '16px' }}>
          <Radio
            name="matchType"
            value="private"
            label="Select a job from your job board"
            onChange={() => setMatchType('job')}
            checked={matchType === 'job'}
          />
          <Radio
            name="matchType"
            value="job"
            label="Enter details for a private role"
            onChange={() => setMatchType('private')}
            checked={matchType === 'private'}
          />
        </Flex>
      </Flex>
      {matchType === 'private' && (
        <PrivateList match={match} onMatchSelected={onMatchSelected} setStep={setStep} matchId={matchId} />
      )}
    </Box>
    {matchType === 'job' && <JobList onMatchSelected={onMatchSelected} />}
  </>
);

AiMatches.propTypes = {
  onMatchSelected: PropTypes.func.isRequired,
  matchType: PropTypes.string.isRequired,
  setMatchType: PropTypes.func.isRequired,
  matchId: PropTypes.string,
  setStep: PropTypes.func.isRequired,
  match: PropTypes.object,
};

AiMatches.defaultProps = {
  match: null,
  matchId: null,
};
