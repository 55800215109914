import { useEffect, useRef } from 'react';

export const useOnClickOutside = (handler, options) => {
  const ref = useRef();
  useEffect(() => {
    const listener = (event) => {
      // instances where we don't want to trigger this when
      // clicking on an item that has the attribute exclude-click-outside
      if (event.target.hasAttribute('data-exclude-click-outside')) {
        return;
      }

      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event, ref);
    };

    const escListener = (event) => {
      if (event.key === 'Escape') {
        handler(event);
      }
    };

    document.addEventListener(options?.downEvent || 'mousedown', listener);
    document.addEventListener('touchstart', listener);

    if (options?.useEsc) {
      document.addEventListener('keyup', escListener);
    }

    return () => {
      document.removeEventListener(options?.downEvent || 'mousedown', listener);
      document.removeEventListener('touchstart', listener);
      if (options?.useEsc) {
        document.removeEventListener('keyup', escListener);
      }
    };
  }, [options, handler]);

  return ref;
};

export default useOnClickOutside;
