import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from '@getro/rombo';
import { Flex, Text, Box } from 'rebass/styled-components';

export const TalentNetwork = ({ tnHidden, toggleTalentNetwork }) => (
  <Box>
    <Flex alignItems="center" justifyContent="space-between" sx={{ gap: '16px' }}>
      <Box>
        <Text mb="8px" lineHeight="24px" fontSize="16px" fontWeight="500" as="h4">
          Show sign up on job board
        </Text>
        <Text as="p" color="text.main" lineHeight="20px" fontSize="14px">
          Let job board visitors sign up to signal their interest on working at one of your companies.
        </Text>
      </Box>
      <Toggle size="large" onChange={toggleTalentNetwork} checked={tnHidden} />
    </Flex>
  </Box>
);

TalentNetwork.propTypes = {
  tnHidden: PropTypes.bool.isRequired,
  toggleTalentNetwork: PropTypes.func.isRequired,
};

export default TalentNetwork;
