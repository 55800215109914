import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/molecules/Filter';

const SeniorityFilter = ({ value, onChange, seniorityOptions, embeddedInModal, onDropDownStateChange, showLabel }) => {
  const selectedOption = useMemo(() => seniorityOptions.find((i) => i.value === value), [value, seniorityOptions]);
  return (
    <Filter
      showLabel={showLabel}
      label="Seniority"
      placeholder="Seniority"
      name="seniority"
      value={selectedOption}
      onChange={onChange}
      options={[...seniorityOptions]}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

SeniorityFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  seniorityOptions: PropTypes.func.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
  showLabel: PropTypes.bool,
};

SeniorityFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
  showLabel: true,
};

export default SeniorityFilter;
