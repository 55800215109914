import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import { Button, FormInput, FormTextarea } from '@getro/rombo';

import { SmartOptions } from '../components/smartOptions';

const ContactsCreate = ({
  formRef,
  formDefaultValues,
  formSchema,
  isEdit,
  isUpdatingList,
  isSubmitting,
  onSubmit,
  match,
  updateSmartOption,
  filters,
  contactsCount,
  hasAutoUpdateFilters,
  setStep,
}) => (
  <Formik
    innerRef={formRef}
    initialValues={formDefaultValues}
    validationSchema={formSchema}
    enableReinitialize
    validateOnChange={false}
    validateOnBlur={false}
    onSubmit={onSubmit}
  >
    <Box
      as={Form}
      width="100%"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Field
        autoFocus
        component={FormInput}
        name="name"
        label="Name"
        labelExtra="Required"
        sx={{
          fontSize: '14px',
        }}
      />
      <Field
        component={FormTextarea}
        name="description"
        label="Description"
        helpText="Add your list's purpose for your team and those it’s shared with."
        sx={{
          fontSize: '14px',
        }}
      />
      {isEdit && (
        <Flex justifyContent="flex-start">
          <Button type="submit" loading={isUpdatingList} size="small">
            Update
          </Button>
        </Flex>
      )}
      <Box
        sx={{
          width: '100%',
          my: '24px',
          height: '1px',
          borderBottom: '1px solid',
          borderColor: 'border.subtle',
        }}
      />
      <SmartOptions
        match={match}
        updateSmartOption={updateSmartOption}
        onEnableMatch={(type) => {
          setStep(type === 'matches' ? 'contacts:matches' : 'contacts:autoUpdate');
        }}
        filters={filters}
        contactsCount={contactsCount}
        isEdit={isEdit}
        hasAutoUpdateFilters={hasAutoUpdateFilters}
      />
      {!isEdit && (
        <Box
          sx={{
            width: '100%',
            mt: '24px',
            height: '0px',
            borderBottom: '1px solid',
            borderColor: 'border.subtle',
          }}
        />
      )}
      {!isEdit && (
        <Flex justifyContent="flex-start">
          <Button type="submit" loading={isSubmitting} size="small">
            Create list
          </Button>
        </Flex>
      )}
    </Box>
  </Formik>
);

ContactsCreate.propTypes = {
  formRef: PropTypes.object,
  formDefaultValues: PropTypes.object,
  formSchema: PropTypes.object,
  isEdit: PropTypes.bool,
  isUpdatingList: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.object,
  updateSmartOption: PropTypes.func,
  filters: PropTypes.object,
  contactsCount: PropTypes.number,
  hasAutoUpdateFilters: PropTypes.bool,
  setStep: PropTypes.func,
};

ContactsCreate.defaultProps = {
  formRef: null,
  formDefaultValues: {},
  formSchema: {},
  isEdit: false,
  isUpdatingList: false,
  isSubmitting: false,
  match: null,
  updateSmartOption: null,
  filters: null,
  contactsCount: 0,
  hasAutoUpdateFilters: false,
  setStep: null,
};

export default ContactsCreate;
