import React from 'react';
import { FormInput, FormSectionTitle, Button, Checkbox, FormTextEditor, Message, Loader } from '@getro/rombo';
import { FastField, Formik, Form, Field } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { useOrganizationIndustries } from 'pages/list/hooks/useOrganizationIndustries';
import { useOrganizationSize } from 'pages/list/hooks/useOrganizationSize';
import { useOrganizationStage } from 'pages/list/hooks/useOrganizationStage';
import { usePrivateList } from './hooks/usePrivateList';
import { ReduxMultiFormSelect } from './components/ReduxMultiFormSelect';
import { CompanyDetailsField } from './components/companyDetailsField';

export const AiMatchSettings = ({ match: updatedMatch, onMatchSelected, matchId, setSmartOption }) => {
  const { locations, formSchema, error, initialValues, onSubmit, loadingMatch } = usePrivateList({
    onMatchSelected,
    updatedMatch,
    matchId,
    isSettings: true,
  });

  if (loadingMatch) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <Box>
      <Box as="p" fontSize="14px" lineHeight="20px" mb="16px">
        Enter the details to fit your ideal candidate. This will influence AI matches but won’t alter your job board
        profile.
      </Box>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange
        validationSchema={formSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Flex flexDirection="column" sx={{ gap: '24px' }}>
              <Flex flexDirection="column" sx={{ gap: '24px' }}>
                <FormSectionTitle>1. Company details</FormSectionTitle>
                <Box>
                  <Box>
                    <FastField
                      disabled
                      component={FormInput}
                      name="organizationName"
                      label="Company name"
                      labelExtra="Required"
                    />
                    <Flex alignItems="center" mt="24px" sx={{ gap: '16px' }}>
                      <Box flex="1">
                        <CompanyDetailsField
                          name="organizationSize"
                          label="Company size"
                          placeholder="Select"
                          hook={useOrganizationSize}
                          value={values.organizationSize}
                        />
                      </Box>
                      <Box flex="1">
                        <CompanyDetailsField
                          name="organizationStage"
                          label="Company stage"
                          placeholder="Select"
                          hook={useOrganizationStage}
                          value={values.organizationStage}
                        />
                      </Box>
                    </Flex>
                    <Flex alignItems="center" mt="24px" sx={{ gap: '16px' }}>
                      <Box flex="1">
                        <CompanyDetailsField
                          name="organizationIndustryTagIds"
                          label="Company industries"
                          placeholder="Select"
                          type="search"
                          hook={useOrganizationIndustries}
                          value={values.organizationIndustryTagIds}
                        />
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Flex>
              <Flex flexDirection="column" sx={{ gap: '24px' }}>
                <FormSectionTitle>2. Job details</FormSectionTitle>
                <FastField
                  component={FormInput}
                  name="title"
                  placeholder="Ex: Senior Account Manager"
                  label="Title"
                  labelExtra="Required"
                />
                <FastField
                  component={FormTextEditor}
                  name="description"
                  placeholder="Enter the position details"
                  label="Job description or skills"
                  helpText="Enter the job description or skills in any format. Our AI will handle the details, and the information will not be shown on your job board."
                />
                <Field
                  component={ReduxMultiFormSelect}
                  name="locations"
                  label="Location"
                  labelExtra="Required"
                  loading={locations.loading}
                  options={locations.options}
                  onInputChange={locations.search}
                  orientation="horizontal"
                  placeholder="Start typing..."
                />
                <Checkbox
                  label="Remote position"
                  name="remote"
                  value={values.remote}
                  checked={values.remote}
                  onChange={() => setFieldValue('remote', !values.remote)}
                />
              </Flex>

              {error && (
                <Message type="error" title="Invalid website format">
                  There was an unexpected error, please try again or contact us.
                </Message>
              )}
              <Flex justifyContent="flex-start" pb="40px">
                <Button type="submit">Update</Button>
                <Button variant="tertiary" type="submit" onClick={() => setSmartOption(null)}>
                  Cancel
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

AiMatchSettings.propTypes = {
  match: PropTypes.object,
  onMatchSelected: PropTypes.func.isRequired,
  matchId: PropTypes.string,
  setSmartOption: PropTypes.func.isRequired,
};

AiMatchSettings.defaultProps = {
  match: null,
  matchId: null,
};
