import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';

export const LiveViewModeSwitcher = ({ view, views }) => (
  <Flex sx={{ position: 'sticky' }}>
    <Flex
      sx={{
        backgroundColor: 'white',
        padding: '4px',
        cursor: 'pointer',
        width: 'min-content',
        borderRadius: '4px',
        m: '0px auto',
        gap: '8px',
        '& > div': {
          padding: '4px 12px',
          ':hover': {
            backgroundColor: 'neutral.100',
            svg: {
              color: 'neutral.900',
            },
          },
        },
      }}
    >
      {views.map((v) => (
        <Flex
          sx={{
            borderRadius: '4px',
          }}
          backgroundColor={view === v.name ? 'neutral.100' : ''}
          key={v.name}
          onClick={v.onClick}
        >
          <Box as={v.icon} size="16px" sx={{ color: view === v.name ? 'neutral.900' : 'neutral.400' }} />
        </Flex>
      ))}
    </Flex>
  </Flex>
);

LiveViewModeSwitcher.propTypes = {
  views: PropTypes.array.isRequired,
  view: PropTypes.string.isRequired,
};

export default LiveViewModeSwitcher;
