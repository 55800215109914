import React from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';

import { SettingsLayout } from '../../components/layout/Settings';
import General from './general';
import Admins from './admins';
import PlansAndBilling from './plansAndBilling';
import { DataExport } from './dataExport';
import JobBoard from './jobBoard';
import { JobBoardFilters } from './jobBoard/filters';
import { JobBoardPrivacyPolicy } from './jobBoard/privacyPolicy';
import { JobBoardRules } from './jobBoard/jobRules';
import { JobBoardTalentNetwork } from './jobBoard/talentNetwork';
import { AccountProfile } from './account/profile';
import { AccountNotifications } from './account/notifications';
import { MyAccount } from './account/myAccount';

export const SettingsPage = () => {
  const { path } = useRouteMatch();

  return (
    <SettingsLayout>
      <Switch>
        <Route path={`${path}general`} exact>
          <General />
        </Route>
        <Route path={`${path}admins`} exact>
          <Admins />
        </Route>
        <Route path={`${path}plans-and-billing`} exact>
          <PlansAndBilling />
        </Route>
        <Route path={`${path}data-export`} exact>
          <DataExport />
        </Route>
        <Route path={`${path}job-board-appearance`} exact>
          <JobBoard />
        </Route>
        <Route path={`${path}job-board-filters`} exact>
          <JobBoardFilters />
        </Route>
        <Route path={`${path}job-board-privacy-policy`} exact>
          <JobBoardPrivacyPolicy />
        </Route>
        <Route path={`${path}job-board-rules`} exact>
          <JobBoardRules />
        </Route>
        <Route path={`${path}job-board-talent-network`} exact>
          <JobBoardTalentNetwork />
        </Route>
        <Route path={`${path}account-my-profile`} exact>
          <AccountProfile />
        </Route>
        <Route path={`${path}account-notifications`} exact>
          <AccountNotifications />
        </Route>
        <Route path={`${path}account-my-account`} exact>
          <MyAccount />
        </Route>
      </Switch>
    </SettingsLayout>
  );
};
