import React from 'react';
import { Flex, Text, Image, Box } from 'rebass/styled-components';
import { Link } from 'react-router-dom';
import NetworkSchema from 'schemas/network';
import PropTypes from 'prop-types';
import { trackEvent } from 'helpers/analytics';
import SearchIcon from '../../../assets/icon-search.svg';

export const NoMatches = ({ activeNetwork, onEdit }) => (
  <Flex
    sx={{
      m: '0px auto',
      maxWidth: '440px',
      flexDirection: 'column',
      gap: '16px',
      justifyContent: 'flex-end',
      boxShadow: '0px 1px 4px 0px rgba(27, 36, 50, 0.06), 0px 2px 32px 0px rgba(27, 36, 50, 0.06)',
      borderRadius: '8px',
      p: '32px',
    }}
  >
    <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', height: '64px' }}>
      <Text sx={{ color: 'text.dark', fontSize: '20px', lineHeight: '1.2', fontWeight: '600' }}>
        Whoops! We missed the target
      </Text>
      <Image src={SearchIcon} size="64px" />
    </Flex>
    <Text color="text.main">Please, help us find better matches by: </Text>
    <Box as="ol" sx={{ p: 0, m: 0, display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Box as="li">
        <Text
          as="div"
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => {
            onEdit();
            trackEvent('matches:get_better_matches_cta_click', {
              collectionId: activeNetwork.id,
              origin: 'banner',
              action: 'add_missing_info',
            });
          }}
          color="text.main"
        >
          Refining the job match criteria
        </Text>
      </Box>
      <Box as="li">
        <Text
          onClick={() => {
            trackEvent('matches:get_better_matches_cta_click', {
              collectionId: activeNetwork.id,
              origin: 'banner',
              action: 'add_more_contacts',
            });
          }}
          as={Link}
          to={`/networks/${activeNetwork.slug}/contacts`}
          color="text.main"
        >
          Add more qualified contacts
        </Text>
      </Box>
    </Box>
  </Flex>
);

NoMatches.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
  onEdit: PropTypes.func.isRequired,
};
