import React, { useMemo, useState } from 'react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import OptionsFilter from '../../atoms/OptionsFilter';
import { isBooleanSearch, isInvalidBooleanSearch } from '../../../helpers/booleanValidator';
import { ApiFacet } from './apiFacet';

export const JobTitle = ({ filters, setFilters, sharedList, showListFilters }) => {
  const [isValidSearch, setIsValidSearch] = useState(true);
  const selected = useMemo(
    () =>
      filters.jobTitles.map((item) => ({
        ...item,
        isEditable: isBooleanSearch(item.value),
      })),
    [filters.jobTitles],
  );

  return (
    <ApiFacet
      name="job_titles"
      label="Job title"
      value={selected}
      onChange={(value) => {
        const booleanSearch = selected.find((item) => item.isEditable);
        const addedBooleanSearch = value.length > selected.length && isBooleanSearch(value[value.length - 1].value);

        setFilters({
          jobTitles:
            addedBooleanSearch && booleanSearch ? value.filter((item) => item.value !== booleanSearch.value) : value,
        });
      }}
      notFoundText="No contacts with job title"
      sharedList={sharedList}
      useSearchAsTag
      useShareListFilter={!showListFilters}
      placeholder="Select job title or press enter"
      isValidSearch={(value) => {
        if (isBooleanSearch(value)) {
          const isValid = !isInvalidBooleanSearch(value);
          setIsValidSearch(isValid);

          return false;
        }

        return true;
      }}
      titleMessage={
        <Box sx={{ fontSize: '12px', color: 'text.subtle' }}>
          Learn to use boolean searches{' '}
          <Box
            color="text.subtle"
            as="a"
            href="https://help.getro.com/support/solutions/articles/65000188936-how-to-use-boolean-searches-to-find-contacts"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </Box>
          .
        </Box>
      }
      errorMessage={
        !isValidSearch ? (
          <Box>
            Invalid boolean format.{' '}
            <Box
              color="inherit"
              as="a"
              href="https://help.getro.com/support/solutions/articles/65000188936-how-to-use-boolean-searches-to-find-contacts"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </Box>
            .
          </Box>
        ) : null
      }
    >
      <>
        <OptionsFilter
          placeholder="Current and past titles"
          selected={filters.jobTitlesIncluded}
          onSelect={(value) => setFilters({ jobTitlesIncluded: value })}
          options={[
            {
              label: 'Current and past titles',
              value: 'all',
            },
            {
              label: 'Current title only',
              value: 'current',
            },
          ]}
        />
      </>
    </ApiFacet>
  );
};

JobTitle.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  sharedList: PropTypes.object,
  showListFilters: PropTypes.bool,
};

JobTitle.defaultProps = {
  sharedList: null,
  showListFilters: false,
};
