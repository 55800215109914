import React from 'react';
import { Box } from 'rebass/styled-components';

import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import usePageHook from '../../../hooks/usePageHook';
import LoginInfo from '../../Account/LoginInfo';
import RequestData from '../../Account/RequestData';
import DeleteAccount from '../../Account/DeleteAccount';

const pageTitle = 'Your Getro Account';

export const MyAccount = () => {
  usePageHook(pageTitle);

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader title={pageTitle} description="Manage your Getro account." />
      <Box pt="32px">
        <LoginInfo />
        <RequestData />
        <DeleteAccount />
      </Box>
    </Box>
  );
};
