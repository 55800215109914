import React from 'react';
import { Box } from 'rebass/styled-components';

import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import usePageHook from '../../../hooks/usePageHook';
import JobBoardFiltersComponent from '../components/JobBoardFilters';

const pageTitle = 'Filters on job board';

export const JobBoardFilters = () => {
  usePageHook(pageTitle);

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader
        title={pageTitle}
        description="Customize the filters on your job board to help job seekers discover companies that match their interests."
      />
      <Box pt="32px">
        <JobBoardFiltersComponent />
      </Box>
    </Box>
  );
};
