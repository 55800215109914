import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ChevronDown } from 'lucide-react';
import { useOptionsNavigation } from './hooks/useOptionsNavigation';
import { SelectedTags } from './selectedTags';
import { SelectDropdown } from './selectDropdown';
import { useOptions } from './hooks/useOptions';

const MultiSelectDropDown = ({
  isMulti,
  options: menuOptions,
  selected,
  onSelect,
  onClose,
  onOpen,
  sx,
  trigger,
  showDropDown,
  children,
  loading,
  placeholder,
  bottomText,
  defaultText,
  isOpened,
  disableAddMore,
  disableAddMoreMessage,
  input,
  showSearch,
  onShowSearch,
  onToggle,
  useSearchAsTag,
  searchText,
  errorMessage,
  handleSearch,
}) => {
  const { options, isBoolean } = useOptions({ searchText, useSearchAsTag, loading, isMulti, menuOptions, selected });

  const { currentOption, toggle, ref } = useOptionsNavigation({
    isMulti,
    showDropDown,
    options,
    selected,
    onSelect,
    onClose,
    onOpen,
    hasInput: !!input,
  });

  const hasOptions = options?.length > 0 || (useSearchAsTag && searchText);

  return (
    <Box
      ref={ref}
      sx={{
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      {isMulti && trigger}
      {(isOpened || isMulti) && (
        <Flex sx={{ flexDirection: 'column', gap: '8px', pt: '8px' }}>
          {isMulti && selected?.length > 0 && (
            <SelectedTags
              selected={selected}
              onSelect={onSelect}
              disableAddMore={disableAddMore}
              disableAddMoreMessage={disableAddMoreMessage}
              showSearch={showSearch}
              onShowSearch={onShowSearch}
              onToggle={onToggle}
              isBoolean={isBoolean}
              handleSearch={handleSearch}
            />
          )}
          {isOpened && input}
        </Flex>
      )}
      {isOpened && showDropDown && (hasOptions || (isOpened && showDropDown && loading) || defaultText) && (
        <SelectDropdown
          isBoolean={isBoolean}
          ref={ref}
          options={options}
          currentOption={currentOption}
          showSearch={showSearch}
          toggle={toggle}
          sx={sx}
          defaultText={defaultText}
          bottomText={bottomText}
          loading={loading}
          useSearchAsTag={useSearchAsTag}
          searchText={searchText}
          errorMessage={errorMessage}
        >
          {children}
        </SelectDropdown>
      )}
      {isOpened && !isMulti && (selected || placeholder) && (
        <Flex
          onClick={() => (showDropDown ? onClose() : onOpen())}
          sx={{ color: 'text.subtle', alignItems: 'center', cursor: 'pointer' }}
        >
          <Text as="p" fontSize="14px" lineHeight="16px">
            {selected?.label ?? placeholder}
          </Text>
          <Box as={ChevronDown} size="16px" />
        </Flex>
      )}
    </Box>
  );
};

MultiSelectDropDown.propTypes = {
  isMulti: PropTypes.bool,
  options: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  children: PropTypes.node,
  sx: PropTypes.objectOf(PropTypes.any),
  trigger: PropTypes.node,
  showDropDown: PropTypes.bool,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  bottomText: PropTypes.node,
  defaultText: PropTypes.string,
  input: PropTypes.node,
  isOpened: PropTypes.bool,
  disableAddMore: PropTypes.bool,
  disableAddMoreMessage: PropTypes.string,
  showSearch: PropTypes.bool,
  onShowSearch: PropTypes.func,
  onToggle: PropTypes.func,
  useSearchAsTag: PropTypes.bool,
  searchText: PropTypes.string,
  errorMessage: PropTypes.string,
  handleSearch: PropTypes.func,
};

MultiSelectDropDown.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  children: null,
  sx: {},
  trigger: null,
  showDropDown: false,
  loading: false,
  isMulti: true,
  placeholder: '',
  bottomText: null,
  defaultText: '',
  input: null,
  disableAddMore: false,
  disableAddMoreMessage: '',
  isOpened: false,
  showSearch: true,
  onShowSearch: () => {},
  onToggle: () => {},
  useSearchAsTag: false,
  searchText: '',
  errorMessage: '',
  handleSearch: null,
};

export default MultiSelectDropDown;
