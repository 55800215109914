import React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import pluralize from 'pluralize';
import { GeneratingMatchesLoader } from './generatingMatchesLoader';

export const GeneratingMatches = ({ contactCount }) => (
  <Flex mt="40px" flexDirection="column" alignItems="flex-start" maxWidth="520px" mx="auto" textAlign="center">
    <GeneratingMatchesLoader />
    <Text mt="16px" fontWeight="semibold">
      {contactCount > 0
        ? `Analyzing your ${Number(contactCount).toLocaleString()} ${pluralize('contact', contactCount)}...`
        : 'Analyzing your contacts...'}
    </Text>
    <Text textAlign="left" mb="16px" mt="8px" color="text.subtle">
      Just a few seconds of AI magic to save you hours and reveal hidden gems in your network.{' '}
    </Text>
    <Text textAlign="left" mb="16px" mt="16px" color="text.subtle">
      The matches will show here in a few seconds.
    </Text>
  </Flex>
);

GeneratingMatches.propTypes = {
  contactCount: PropTypes.number.isRequired,
};
