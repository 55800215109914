import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/molecules/Filter';

const EquityFilter = ({ value, onChange, equityOptions, embeddedInModal, onDropDownStateChange, showLabel }) => {
  const selectedOption = useMemo(() => equityOptions.find((i) => i.value === value), [value, equityOptions]);

  return (
    <Filter
      showLabel={showLabel}
      label="Equity"
      placeholder="Equity"
      name="equity"
      value={selectedOption}
      onChange={onChange}
      options={[...equityOptions]}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

EquityFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  equityOptions: PropTypes.func.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
  showLabel: PropTypes.bool,
};

EquityFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
  showLabel: true,
};

export default EquityFilter;
