import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Flex, Box } from 'rebass/styled-components';

import { UploadImage, FormField, ProfilePicture, Loader, Button, Message } from '@getro/rombo';
import { getUserDisplayName } from 'helpers/users';

export const ProfileDescription = ({
  errorMessage,
  filestackApiKey,
  isLoading,
  onUserUpdate,
  userProfile,
  Picker,
  isUpdating,
}) => {
  const formRef = useRef(null);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={userProfile}
      onSubmit={(values) => {
        onUserUpdate(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Flex flexDirection="column">
            <Box width={[1]} mb={[16]}>
              <Flex flexDirection="row">
                <ProfilePicture width="64px" imageUrl={values && values.avatarUrl} name={getUserDisplayName(values)} />
                <Box
                  textAlign="left"
                  alignSelf="center"
                  px={[3]}
                  sx={{ '.UploadImage__button': { color: '#090925!important', fontSize: '14px' } }}
                >
                  <UploadImage
                    Picker={Picker}
                    filestackApiKey={filestackApiKey}
                    imageType="profile"
                    size="sm"
                    onSuccess={({ filesFailed, filesUploaded }) => {
                      if (filesFailed.length < 1) {
                        setFieldValue('avatarUrl', filesUploaded[0].url);
                      }
                    }}
                    onClear={() => setFieldValue('avatarUrl', null)}
                    currentValue={values.avatarUrl}
                  />
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex flexDirection="column">
                <Box mb="16px">
                  <Field type="text" label="Name" name="firstName" placeholder="First name" component={FormField} />
                </Box>
                <Box mb="16px">
                  <Field
                    label="Last name"
                    type="text"
                    className="ProfileMain__info-field"
                    name="lastName"
                    placeholder="Last name"
                    component={FormField}
                  />
                </Box>
              </Flex>
              <Field
                label="LinkedIn profile URL"
                placeholder="https://linkedin.com/in/"
                type="text"
                name="linkedinUrl"
                component={FormField}
              />
            </Box>
            {errorMessage && errorMessage.length && (
              <Box mt="16px" width={[1]} as={Message} type="error">
                {errorMessage}
              </Box>
            )}
            <Box mt="16px">
              <Button loading={isUpdating} type="submit" size="small">
                Save
              </Button>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

ProfileDescription.propTypes = {
  filestackApiKey: PropTypes.string.isRequired,
  onUserUpdate: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  userProfile: PropTypes.object,
  Picker: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

ProfileDescription.defaultProps = {
  isUpdating: false,
  isLoading: false,
  errorMessage: null,
  userProfile: {},
};
