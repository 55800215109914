export const companySizeOptions = [
  { label: '1 - 10', value: 1 },
  { label: '11 - 50', value: 2 },
  { label: '51 - 200', value: 3 },
  { label: '201 - 1,000', value: 4 },
  { label: '1,001 - 5,000', value: 5 },
  { label: '5,000+', value: 6 },
];

export const employmentTypeOptions = [
  { label: 'Full-time', value: 'full_time' },
  { label: 'Part-time', value: 'part_time' },
  { label: 'Contract', value: 'contract' },
  { label: 'Internship', value: 'internship' },
  { label: 'Temporary', value: 'temporary' },
  { label: 'Freelance', value: 'freelance' },
  { label: 'Remote', value: 'remote' },
];

export const locationRadiusOptions = [
  {
    label: 'Within 10 miles (16km)',
    value: 10,
  },
  {
    label: 'Within 25 miles (40km)',
    value: 20,
  },
  {
    label: 'Within 50 miles (80km)',
    value: 50,
  },
  {
    label: 'Within 100 miles (160km)',
    value: 100,
  },
  {
    label: 'Within 200 miles (320km)',
    value: 200,
  },
];

export const jobTitlesIncludedOptions = [
  {
    label: 'Current and past titles',
    value: 'all',
  },
  {
    label: 'Current title only',
    value: 'current',
  },
];

export const companyNameIncludedOptions = [
  {
    label: 'Current and past',
    value: 'all',
  },
  {
    label: 'Current',
    value: 'current',
  },
  {
    label: 'Specific period',
    value: 'specific_period',
  },
];

export const jobTitlesMatchOptions = [
  {
    label: 'Contains',
    value: 'contains',
    description: 'Include job titles that contain selected terms.',
  },
  {
    label: 'Exact only',
    value: 'exact',
    description: 'Include job titles that are exactly the terms.',
  },
];

export const fundOptions = [
  {
    index: 0,
    label: 'Pre-seed',
    value: 'pre_seed',
  },
  {
    index: 1,
    label: 'Seed',
    value: 'seed',
  },
  {
    index: 2,
    label: 'Series A',
    value: 'series_a',
  },
  {
    index: 3,
    label: 'Series B',
    value: 'series_b',
  },
  {
    index: 4,
    label: 'Series C+',
    value: 'series_c',
  },
  {
    index: 5,
    label: 'Private equity',
    value: 'private_equity',
  },
  {
    index: 6,
    label: 'IPO',
    value: 'ipo',
  },
  {
    index: 7,
    label: 'ICO',
    value: 'ico',
  },
];

export const resumeOptions = [
  { label: 'Any', value: 'any' },
  { label: 'Only contacts with resume', value: 'with_resume' },
  { label: 'Only contacts without resume', value: 'without_resume' },
];

export const signUpDayOptions = [
  { label: 'Any', value: 'any' },
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 90 days', value: 90 },
  { label: 'Last year', value: 365 },
];

export const jobSearchStatusesOptions = [
  { label: 'Actively looking', value: 'actively_looking' },
  { label: 'Open to the right opportunity', value: 'casually_browsing' },
  { label: 'Not looking for a change', value: 'not_looking' },
];

export const signupSenioritiesOptions = [
  { label: 'Internship', value: 'internship' },
  { label: 'Entry level', value: 'entry_level' },
  { label: 'Mid-senior level', value: 'mid-senior_level' },
  { label: 'Manager', value: 'manager' },
  { label: 'Director', value: 'director' },
  { label: 'Executive', value: 'executive' },
];

export const signupWorkEnvironmentsOptions = [
  { label: 'Onsite', value: 'onsite' },
  { label: 'Hybrid', value: 'hybrid' },
  { label: 'Remote', value: 'remote' },
];

export const signupInfoOptions = [
  { label: 'Include', value: 'any' },
  { label: "Don't include", value: 'not_completed' },
  { label: 'Only signups', value: 'completed' },
];

export const inPortoOptions = [
  { label: 'Include', value: 'any' },
  { label: "Don't include", value: false },
];

export const defaultFilters = {
  keywords: [],
  jobTitles: [],
  industryTags: [],
  companyStages: [],
  importerIds: [],
  jobTitlesMatch: {
    label: 'Contains',
    value: 'contains',
  },
  jobTitlesIncluded: {
    label: 'Current and past titles',
    value: 'all',
  },
  locationRadius: {
    label: 'Within 10 miles (16km)',
    value: 10,
  },
  sharedListIds: [],
  activePositionsOrganizationStages: [],
  stageTransitions: [],
};

export const blacklistedFiltersForCount = [
  'jobTitlesIncluded',
  'jobTitlesMatch',
  'companyStagesYears',
  'companyNameIncluded',
  'industryTagsYears',
  'locationRadius',
];
