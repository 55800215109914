import React from 'react';
import { Box } from 'rebass/styled-components';

import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import usePageHook from '../../../hooks/usePageHook';
import UserProfile from '../../Account/UserProfile';

const pageTitle = 'Profile';

export const AccountProfile = () => {
  usePageHook(pageTitle);

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader
        title={pageTitle}
        description="This information will be visible to your team and others when you share content. Add a profile picture to add a personal touch and increase engagement."
      />
      <Box pt="32px">
        <UserProfile />
      </Box>
    </Box>
  );
};
