import React from 'react';
import { Box } from 'rebass/styled-components';

import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';

const LoginInfo = () => (
  <Box>
    <Box title="Email" mb={3}>
      <EmailForm />
    </Box>
    <Box title="Password" mb={3}>
      <PasswordForm />
    </Box>
  </Box>
);

export default LoginInfo;
