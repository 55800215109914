import React from 'react';
import { Box } from 'rebass/styled-components';
import { useSelector } from 'react-redux';

import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import { hasJobAlertsEnabledSelector } from '../../../redux/selectors';
import usePageHook from '../../../hooks/usePageHook';
import ExportData from '../components/ExportData';
import ExportJobAlertSubscribers from '../components/ExportJobAlertSubscribers';

const pageTitle = 'Data export';

export const DataExport = () => {
  const hasJobAlerts = useSelector(hasJobAlertsEnabledSelector);

  usePageHook(pageTitle);

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader title={pageTitle} description="Download your network data." />
      <Box pt="32px">
        {hasJobAlerts && <ExportJobAlertSubscribers mb="32px" />}
        <ExportData />
      </Box>
    </Box>
  );
};
