export const createMenuLinks = (url, { isNetworkAdmin, showPlansAndBillings, showAccountNotifications }) => [
  ...(isNetworkAdmin
    ? [
        {
          title: 'Network',
          items: [
            { title: 'General', path: `${url}/general` },
            { title: 'Admins', path: `${url}/admins` },
            ...(showPlansAndBillings ? [{ title: 'Plans and billing', path: `${url}/plans-and-billing` }] : []),
            { title: 'Data export', path: `${url}/data-export` },
          ],
        },
      ]
    : []),
  ...(isNetworkAdmin
    ? [
        {
          title: 'Job board',
          items: [
            { title: 'Appearance', path: `${url}/job-board-appearance` },
            { title: 'Filters', path: `${url}/job-board-filters` },
            { title: 'Privacy policy', path: `${url}/job-board-privacy-policy` },
            { title: 'Job rules', path: `${url}/job-board-rules` },
            { title: 'Talent network', path: `${url}/job-board-talent-network` },
          ],
        },
      ]
    : []),
  {
    title: 'Your account',
    items: [
      { title: 'Profile', path: `${url}/account-my-profile` },
      ...(showAccountNotifications ? [{ title: 'Notifications', path: `${url}/account-notifications` }] : []),
      { title: 'Account', path: `${url}/account-my-account` },
    ],
  },
];
