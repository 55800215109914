import { toast } from 'react-hot-toast';

import { getItem } from '../helpers/store';

export const EXTENSION_ID = 'ibgcbgjnpaedoomkhclbjgnhnkmeplee';
export const EXTENSION_URL = `https://chrome.google.com/webstore/detail/getro/${EXTENSION_ID}`;

export const checkExtensionAvailability = () =>
  new Promise((resolve, reject) => {
    setTimeout(() => reject(), 1000);
    // eslint-disable-next-line no-undef
    if (chrome?.runtime?.sendMessage) {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(EXTENSION_ID, { type: 'VERSION' }, (response) => {
        if (!response) {
          // eslint-disable-next-line no-undef
          reject(chrome.runtime.lastError);
        }

        resolve(true);
      });
    } else {
      reject();
    }
  });

export const launchScraper = (network, user) => {
  // eslint-disable-next-line no-undef
  if (chrome?.runtime?.sendMessage) {
    // eslint-disable-next-line no-undef
    chrome.runtime.sendMessage(
      EXTENSION_ID,
      {
        type: 'SCRAPER_INITIALIZE',
        auth: getItem('authToken'),
        user: {
          avatarUrl: user.avatarUrl,
          email: user.email,
          id: user.id,
          fullName: `${user.firstName} ${user.lastName}`,
        },
        networkId: network.id,
      },
      (response) => {
        if (response?.type === 'SEND_COOKIES_SUCCESS') {
          toast('Contacts successfully imported', { icon: 'success', id: 'export-csv-success' });
        }
      },
    );
  }
};
