import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { PrivateLock } from 'components/atoms/PrivateLock';
import { Message, Button } from '@getro/rombo';
import { Settings2 } from 'lucide-react';
import { trackEvent } from 'helpers/analytics';
import { JobDetailSkeleton } from './skeletons/jobDetail';
import { MatchDetailItem } from './matchDetailItem';

const JobDetail = ({ activeNetwork, jobName, details, missingMatchingDetails, onEdit, isLoading, isPrivate }) => (
  <Flex sx={{ flexDirection: 'column', gap: '24px' }}>
    <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
      <Box sx={{ flexDirection: 'column', gap: '8px' }}>
        {!isLoading && (
          <Flex sx={{ alignItems: 'center', gap: '8px' }}>
            <Box
              sx={{
                color: 'text.dark',
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '140%',
              }}
            >
              Matches for
            </Box>
            {!isLoading && isPrivate && <PrivateLock text="Private job" />}
          </Flex>
        )}
        {isLoading && <JobDetailSkeleton />}
      </Box>
      {!isLoading && jobName && (
        <Text
          sx={{
            color: 'text.dark',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '140%',
          }}
        >
          {jobName}
        </Text>
      )}
    </Flex>
    {!isLoading && missingMatchingDetails && isPrivate && (
      <Message
        sx={{ p: '8px', bg: 'orange.50', gap: '8px', borderRadius: '4px', alignItems: 'flex-start' }}
        type="warning"
      >
        <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
          <Box>Add missing job criteria to improve matches accuracy.</Box>
          <Box>
            <Button
              onClick={() => {
                onEdit();
                trackEvent('matches:get_better_matches_cta_click', {
                  collectionId: activeNetwork.id,
                  origin: 'banner',
                  action: 'job_info_sidebar',
                });
              }}
              icon={Settings2}
              size="small"
              variant="secondary"
            >
              Edit job
            </Button>
          </Box>
        </Flex>
      </Message>
    )}

    {!isLoading && (
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '12px',
          '& > p': {
            fontSize: '14px',
            color: 'text.main',
          },
        }}
      >
        {Object.entries(details)
          .filter(([, { showSidebar }]) => showSidebar)
          .map(([key, { label, value }]) => (
            <MatchDetailItem key={key} label={label} value={value} />
          ))}
      </Flex>
    )}

    {!isLoading && isPrivate && (
      <Button
        sx={{ border: 'none', p: 0, '&:hover': { bg: 'transparent' }, height: 'fit-content' }}
        onClick={onEdit}
        icon={Settings2}
        size="small"
        variant="secondary"
      >
        Edit job
      </Button>
    )}
  </Flex>
);

JobDetail.propTypes = {
  activeNetwork: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  missingMatchingDetails: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onEdit: PropTypes.func,
  jobName: PropTypes.string.isRequired,
};

JobDetail.defaultProps = {
  isLoading: false,
  onEdit: () => {},
};

export default JobDetail;
