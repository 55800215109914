import hexToRgba from 'helpers/hexToRgba';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import { useGetJobBoardSettingsQuery, useUpdateJobBoardSettingsMutation } from 'services/networkV2';
import { useCallback, useEffect, useMemo, useState } from 'react';
import getRgbaAlpha from 'helpers/rgbaGetAlpha';
import { trackEvent, trackPage } from 'helpers/analytics';
import { useGetAdvancedDesignQuery } from 'services/network';
import { useGetNetworkCountsQuery } from 'services/search';
import { useGetNetworkQuery } from 'services/organizations';
import {
  companiesSort,
  heroBackgroundType,
  heroOptions,
  heroTextColor,
  tabOrderOptions,
  tabOrderOptionsDefault,
} from '../options';

const isObjectEqual = (obj1, obj2) =>
  Object.keys(obj1).every((key) => {
    if (obj1[key] === null && obj2[key] === '') {
      return true;
    }
    return obj1[key] === obj2[key];
  });

export const useJobBoardAppearance = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { isLoading, data } = useGetJobBoardSettingsQuery();
  const [update, { isLoading: isUpdating }] = useUpdateJobBoardSettingsMutation();
  const { data: networkData, isFetching: isLoadingNetwork } = useGetNetworkQuery(activeNetwork.id);
  const { data: networkCount, isLoading: isLoadingNetworkCount } = useGetNetworkCountsQuery();
  const network = networkData
    ? { ...networkData, ...(networkCount ?? { companiesCount: 0, jobsCount: 0 }), loaded: true, id: activeNetwork.id }
    : {};
  const { data: advancedDesignSettings, isFetching: isLoadingAdSettings } = useGetAdvancedDesignQuery(
    {
      network,
    },
    {
      skip: isLoadingAdSettings || isLoadingNetwork || !activeNetwork.features.includes('advanced_design'),
    },
  );
  const isLoadingData = isLoading || isLoadingNetwork || isLoadingNetworkCount || isLoadingAdSettings;

  const [formData, setFormData] = useState({});

  const customHeroAndAD = !isLoading && data?.advancedDesign && data?.customHero;
  const noCustomHeroAndAD = !isLoading && data?.advancedDesign && !data?.customHero;
  const isGCOrTN = !isLoading && (data?.talentNetwork || (data?.tnOnboardingV2 && !data.tnHidden));
  const standardDesign = noCustomHeroAndAD || !data?.advancedDesign || !data?.customHero;
  const tabOrderOptionsToUse = isGCOrTN ? tabOrderOptions : tabOrderOptionsDefault;

  const mapValuesToData = useCallback(
    (values) => {
      let updates = {
        tabsOrder: values.tabsOrder.value,
      };

      if (!customHeroAndAD) {
        if (values.placement.value === 'hero') {
          updates.hideNumbersInHero = false;
          updates.numbersInNav = false;
        } else if (values.placement.value === 'navigation_bar') {
          updates.numbersInNav = true;
        } else {
          updates.hideNumbersInHero = true;
          updates.numbersInNav = false;
        }
      } else if (values.placement.value === 'navigation_bar') {
        updates.numbersInNav = true;
      } else {
        updates.numbersInNav = false;
        updates.numbersInNav = false;
      }

      if (!standardDesign) {
        return updates;
      }

      updates = {
        ...updates,
        heroSubtitle: values.heroSubtitle,
        heroTitle: values.heroTitle,
        darkHeroText: values.heroTextColor.value,
        hideLogoInHero: !values.hideLogoInHero,
        hideNameInHero: !values.hideNameInHero,
        claimOrganizationProfileInHero: values.claimOrganizationProfileInHero,
        hideTnCtaInHero: !values.hideTnCtaInHero,
      };

      if (values.heroBackgroundType.value === 'solid') {
        updates.heroColorMask = values.heroColorMask;
        updates.heroImageUrl = null;
      }
      if (values.heroBackgroundType.value === 'image') {
        updates.heroImageUrl = values.heroImageUrl;

        if (values.imageMask) {
          updates.heroColorMask = hexToRgba(values.heroColorMask, Number(values.heroMaskOpacity) / 100);
        } else {
          updates.heroColorMask = null;
        }
      }

      return updates;
    },
    [customHeroAndAD, standardDesign],
  );

  const placement = useMemo(() => {
    if (standardDesign && !data?.hideNumbersInHero && !data?.numbersInNav) {
      return heroOptions[0];
    }
    if (data?.numbersInNav) {
      return heroOptions[1];
    }
    return heroOptions[2];
  }, [data?.hideNumbersInHero, data?.numbersInNav, standardDesign]);

  const initialValues = useMemo(() => {
    const isHeroMaskRgb = data?.heroColorMask?.length && data?.heroColorMask[0] !== '#';
    return data
      ? {
          tabsOrder: tabOrderOptionsToUse.find((option) => option.value === data?.tabsOrder) || tabOrderOptionsToUse[0],
          placement,
          companiesSort: data?.sortOrganizationsActiveJobs ? companiesSort[1] : companiesSort[0],
          heroTitle: data?.heroTitle,
          heroSubtitle: data?.heroSubtitle,
          heroTextColor: data?.darkHeroText ? heroTextColor[1] : heroTextColor[0],
          hideLogoInHero: !data?.hideLogoInHero,
          hideNameInHero: !data?.hideLogoInHero,
          claimOrganizationProfileInHero: data?.claimOrganizationProfileInHero,
          hideTnCtaInHero: !data?.hideTnCtaInHero,
          heroBackgroundType: data?.heroImageUrl ? heroBackgroundType[1] : heroBackgroundType[0],
          imageMask: Boolean(data?.heroColorMask),
          heroMaskOpacity: isHeroMaskRgb ? (getRgbaAlpha(data?.heroColorMask) ?? 1) * 100 : 80,
          heroImageUrl: data?.heroImageUrl,
          heroColorMask: data?.heroColorMask ?? '',
        }
      : null;
  }, [data, placement, tabOrderOptionsToUse]);

  useEffect(() => {
    if (!isLoadingData && data) {
      setFormData(data);
    }
  }, [data, standardDesign, isLoadingData]);

  const onFormValuesChange = (values) => {
    setFormData({
      ...(data ?? {}),
      ...mapValuesToData(values, standardDesign),
    });
  };

  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';
    trackPage();

    return () => {
      document.querySelector('body').style.overflow = 'visible';
    };
  }, []);

  const handleSubmit = async (values) => {
    const updates = mapValuesToData(values, standardDesign);

    await update(updates);
    trackEvent('network_settings:edit_job_board_appearance_settings', {
      collectionId: activeNetwork.id,
    });

    return null;
  };

  return {
    handleSubmit,
    customHeroAndAD,
    noCustomHeroAndAD,
    standardDesign,
    tabOrderOptionsToUse,
    network,
    slug: activeNetwork.slug,
    url: activeNetwork.url,
    initialValues,
    placement,
    isLoading: isLoading || isLoadingNetwork || isLoadingNetworkCount || isLoadingAdSettings,
    update,
    isUpdating,
    heroOptions: standardDesign ? heroOptions : heroOptions.slice(1),
    isGCOrTN,
    formData,
    onFormValuesChange,
    tnSettings: networkData?.tnSettings,
    dataHasChanged: !isObjectEqual(formData, data),
    showHeroInNav: formData?.numbersInNav,
    advancedDesignSettings,
    ...(data || {}),
  };
};
