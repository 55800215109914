import { Button } from '@getro/rombo';
import { Plus } from 'lucide-react';
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Image, Text, Box } from 'rebass/styled-components';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import NetworkSchema from 'schemas/network';
import { trackEvent } from 'helpers/analytics';
import EmptyState from '../../../assets/icon-hand-empty.svg';

export const LessContacts = ({ activeNetwork, matchesCount, contactsCount, limit }) => (
  <Flex
    sx={{
      m: '0px auto',
      maxWidth: '440px',
      flexDirection: 'column',
      gap: '16px',
      justifyContent: 'flex-end',
      boxShadow: '0px 1px 4px 0px rgba(27, 36, 50, 0.06), 0px 2px 32px 0px rgba(27, 36, 50, 0.06)',
      borderRadius: '8px',
      p: '32px',
      mb: '70px',
    }}
  >
    <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', height: '64px' }}>
      <Text sx={{ color: 'text.dark', fontSize: '20px', lineHeight: '1.2', fontWeight: '600' }}>
        {!matchesCount ? 'No matches found' : `We found only ${matchesCount} ${pluralize('match', matchesCount)}`}
      </Text>
      <Image src={EmptyState} size="64px" />
    </Flex>
    <Text>Add more qualified contacts to find more matches for this job.</Text>
    <Text>We recommend starting with ~10,000 contacts and aiming for over 20,000 to get the best match results.</Text>
    <Flex sx={{ gap: '8px', py: '16px', justifyContent: 'space-between' }}>
      <Box sx={{ position: 'relative', width: '180px' }}>
        <Box width="100%" height="16px" bg="neutral.100" sx={{ borderRadius: '9px' }} />
        <Flex
          width="70%"
          height="16px"
          sx={{ borderRadius: '9px', position: 'absolute', top: 0, left: 0, zIndex: 2, overflow: 'hidden' }}
        >
          <Box
            bg="purple.400"
            width={`${100 - (limit / contactsCount) * 100}%`}
            flexGrow="1"
            mr="2px"
            sx={{ borderRadius: '9px' }}
          />
        </Flex>
      </Box>
      <Text sx={{ fontSize: '14px', lineHeight: '16px', fontWeight: '500' }}>
        {Intl.NumberFormat().format(contactsCount)} / {Intl.NumberFormat().format(limit)} contacts
      </Text>
    </Flex>
    <Flex
      sx={{
        gap: '8px',
        flexDirection: 'column',
        '& a > div': { justifyContent: 'center', display: 'flex', width: '100%' },
      }}
    >
      <Button
        onClick={() => {
          trackEvent('matches:get_better_matches_cta_click', {
            collectionId: activeNetwork.id,
            origin: 'banner',
            action: 'sync_linkedin',
          });
        }}
        as={Link}
        to={`/networks/${activeNetwork.slug}/linkedin/sync`}
        variant="secondary"
        icon={Plus}
      >
        Sync your LinkedIn
      </Button>
      <Button
        onClick={() => {
          trackEvent('matches:get_better_matches_cta_click', {
            collectionId: activeNetwork.id,
            origin: 'banner',
            action: 'add_more_contacts',
          });
        }}
        as={Link}
        to={`/networks/${activeNetwork.slug}/contacts`}
        variant="secondary"
        icon={Plus}
      >
        Add more contacts
      </Button>
      <Button
        as={Link}
        to={`/networks/${activeNetwork.slug}/settings/admins?invite-admin=true`}
        variant="secondary"
        icon={Plus}
        onClick={() => {
          trackEvent('matches:get_better_matches_cta_click', {
            collectionId: activeNetwork.id,
            origin: 'banner',
            action: 'invite_team',
          });
        }}
      >
        Invite team members
      </Button>
    </Flex>
  </Flex>
);

LessContacts.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
  contactsCount: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  matchesCount: PropTypes.number.isRequired,
};
