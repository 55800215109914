import React, { useEffect } from 'react';
import { Box, Link, Text } from 'rebass/styled-components';

import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import { JobRules } from '../../../components/molecules/jobRules';
import useJobRules from '../../../hooks/jobRules/useJobRules';
import usePageHook from '../../../hooks/usePageHook';

const pageTitle = 'Job rules';

export const JobBoardRules = () => {
  const { jobRules, isJobRulesLoading, loadJobRulesRequest } = useJobRules();

  usePageHook(pageTitle);

  useEffect(() => {
    loadJobRulesRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader
        title={pageTitle}
        description={
          <Text>
            These rules help you control which jobs are shown on your network.{' '}
            <Link
              variant="link"
              target="_blank"
              href="https://help.getro.com/support/solutions/articles/65000176573-curating-your-job-board-with-job-rules"
              color="text.main"
            >
              Learn more
            </Link>
            .
          </Text>
        }
      />
      <Box pt="32px">
        <JobRules
          isLoading={isJobRulesLoading}
          titles={jobRules.titles}
          keywords={jobRules.keywords}
          locations={jobRules.locations}
        />
      </Box>
    </Box>
  );
};
