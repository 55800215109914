import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Download } from 'lucide-react';

import { Message, AsyncButton } from '@getro/rombo';

import { post } from 'api';
import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';
import { Box, Flex, Text } from 'rebass/styled-components';
import { GenericError } from '../../../components/molecules/genericError';
import { userSelector } from '../../../redux/selectors';

const RequestData = () => {
  const user = useSelector(userSelector);
  const { email } = user;

  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const cancelTokenSource = useRef(axios.CancelToken.source());

  const handleSubmit = async () => {
    setSubmitting(true);
    setSuccess(false);
    setError(false);
    try {
      await post('/messages/user-data', {}, { cancelToken: cancelTokenSource.current.token });
      setSubmitting(false);
      setSuccess(true);

      trackEvent('requested_personal_data');
    } catch (e) {
      trackException(e);
      setSubmitting(false);
      setError(true);
    }
  };

  useEffect(
    () => () => {
      cancelTokenSource.current.cancel();
    },
    [],
  );

  return (
    <Flex mt="40px" flexDirection="column">
      <Text fontSize="14px" fontWeight="medium" color="text.dark">
        Your data
      </Text>
      <Text my="16px" fontSize="14px">
        You can request a file with the information that we believe is most relevant and useful to you. You’ll get a
        notification and an email sent to {email} with a link when it’s ready to be downloaded.
      </Text>
      {!success && (
        <AsyncButton size="small" width="fit-content" variant="secondary" loading={submitting} onClick={handleSubmit}>
          <Flex sx={{ columnGap: '10px' }} alignItems="center">
            <Box as={Download} height="16px" width="16px" aria-hidden="true" />
            Request a copy of your data
          </Flex>
        </AsyncButton>
      )}
      {success && (
        <Box
          as={Message}
          mt={3}
          type="success"
          content="We are processing your request, we will send all your data to your email."
        />
      )}
      {error && <Box as={GenericError} mt={3} />}
    </Flex>
  );
};

export default RequestData;
