import React, { useMemo, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Pencil, Plus, X } from 'lucide-react';
import PropTypes from 'prop-types';
import { Input } from '@getro/rombo';
import useOnClickOutside from 'hooks/useClickOutside';
import { isBooleanSearch, isInvalidBooleanSearch } from 'helpers/booleanValidator';
import { FilterTitle } from '../filterTitle';

const KeywordFilter = ({ selected, onEnter, title, onLoseFocus }) => {
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState('');
  const [isInvalidBooleanSearchValue, setIsInvalidBooleanSearchValue] = useState(false);
  const items = useMemo(
    () => selected.map((selectedItem) => ({ value: selectedItem, isEditable: isBooleanSearch(selectedItem ?? '') })),
    [selected],
  );

  const ref = useOnClickOutside(
    () => {
      setShowInput(false);

      if (showInput && onLoseFocus) {
        onLoseFocus(selected.map((item) => item));
      }
    },
    { useEsc: true },
  );

  const onClickAction = (item, edit = false) => {
    onEnter(
      items.filter((selectedItem) => selectedItem.value !== item.value).map((selectedItem) => selectedItem.value),
    );

    if (edit) {
      setValue(item.value);
    }
  };

  return (
    <Flex
      ref={ref}
      sx={{
        input: {
          p: '8px',
          fontSize: '14px',
        },
        flexDirection: 'column',
      }}
    >
      <FilterTitle
        title={title}
        showClear={selected.length > 0}
        showToggle={!showInput && !selected.length > 0}
        clearFilter={() => onEnter([])}
        onToggle={() => setShowInput(!showInput)}
        data-testid="keyword-filter-title-trigger"
      />

      {((selected && selected.length > 0) || showInput) && (
        <Box pt="8px">
          {selected && selected.length > 0 && (
            <Flex sx={{ gap: '8px', flexWrap: 'wrap', mb: showInput ? '8px' : '0px' }}>
              <Flex sx={{ alignItems: 'center', gap: '4px', flexWrap: 'wrap', '& > div': { margin: '0' } }}>
                {items.map((item) => (
                  <Flex
                    sx={{
                      bg: item.isEditable ? 'purple.100' : 'neutral.0',
                      borderRadius: '99px',
                      p: '4px 12px',
                      border: '1px solid',
                      borderColor: 'border.subtle',
                      gap: '4px',
                      height: '24px',
                    }}
                    data-testid={`tag-${item.value}`}
                  >
                    <Text
                      sx={{
                        fontSize: '14px',
                        color: 'text.main',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        lineHeight: '1',
                      }}
                    >
                      {item.value}
                    </Text>
                    <Flex
                      sx={{
                        gap: '4px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '16px',
                        minWidth: item.isEditable ? '36px' : '16px',
                      }}
                    >
                      <Box
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickAction(item, false);
                        }}
                        cursor="pointer"
                        as={X}
                        size="16px"
                        sx={{ color: 'neutral.400' }}
                        data-exclude-click-outside
                        data-testid={`tag-${item.value}-close`}
                      />
                      {item.isEditable && (
                        <Box
                          cursor="pointer"
                          as={Pencil}
                          size="16px"
                          sx={{ color: 'neutral.400' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onClickAction(item, true);
                          }}
                          data-exclude-click-outside
                        />
                      )}
                    </Flex>
                  </Flex>
                ))}
                {!showInput && (
                  <Flex
                    id="keyword-filter-trigger"
                    sx={{
                      minWidth: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowInput(!showInput)}
                  >
                    <Box sx={{ color: 'neutral.300' }} size="16px" as={Plus} />
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
          {showInput && (
            <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
              <Input
                value={value}
                autoFocus
                data-testid="keyword-filter-input"
                onChange={({ target }) => setValue(target.value)}
                onKeyDown={({ target, key }) => {
                  if (key === 'Enter' && target.value.trim()) {
                    const isBooleanSearchValue = isBooleanSearch(target.value);
                    if (isBooleanSearchValue && isInvalidBooleanSearch(target.value)) {
                      setIsInvalidBooleanSearchValue(true);
                      return;
                    }

                    const previousBooleanSearchValue = items.find((item) => item.isEditable)?.value;
                    if (previousBooleanSearchValue && isBooleanSearchValue) {
                      onEnter([...selected.filter((item) => item !== previousBooleanSearchValue), target.value.trim()]);
                      setValue('');
                      return;
                    }

                    onEnter(Array.from(new Set([...selected, target.value.trim()])));
                    setValue('');
                  }
                  setIsInvalidBooleanSearchValue(false);
                }}
                sx={{
                  height: '32px',
                  outline: isInvalidBooleanSearchValue ? 'none' : 'initial',
                  borderColor: isInvalidBooleanSearchValue ? 'red.500' : 'neutral.400',
                }}
                placeholder="Type and hit enter"
              />
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
};

KeywordFilter.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
  onEnter: PropTypes.func,
  title: PropTypes.string,
  onLoseFocus: PropTypes.func,
};

KeywordFilter.defaultProps = {
  selected: [],
  onEnter: () => {},
  title: 'Keyword',
  onLoseFocus: null,
};

export default KeywordFilter;
