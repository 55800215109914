export default function rgbaToHex(rgba) {
  // Match both traditional and modern RGB/RGBA formats
  const values = rgba.match(/^rgba?\s*\(\s*(\d+)\s*,?\s*(\d+)\s*,?\s*(\d+)\s*(?:,?\s*\/?\s*([\d.]+))?\s*\)$/i);

  if (!values || values.length < 4) {
    return '';
  }

  const toHex = (value) => `0${Math.round(Number(value)).toString(16)}`.slice(-2);

  return `#${toHex(values[1])}${toHex(values[2])}${toHex(values[3])}`;
}

export const isRgb = (rgba) => rgba.startsWith('rgb');
