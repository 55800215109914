import React, { useMemo } from 'react';
import { ProfilePicture } from '@getro/rombo';
import moment from 'moment';
import { Text, Flex, Box } from 'rebass/styled-components';
import { displayCompensationPeriod } from 'helpers/compensation';
import { getAuthor, getExpirationDate } from '../jobHelpers';
import strings from '../../../../strings';
import JobStatus from '../../../atoms/JobStatus';
import JobVisibility from '../../../atoms/JobVisibility';
import JobFeatured from '../jobFeatured';
import { CreateListItem } from '../createListItem';

export const useTableRows = ({ jobs, onShowJobRulesClick, createMatches, isCreatingList, onListClicked }) =>
  useMemo(
    () =>
      jobs.map((j) => {
        const job = j.attributes;
        const { name: companyName } = job.organization;
        const { id: authorId } = job.author || {};
        const { selected } = j;

        return {
          original: j,
          id: job.id,
          slug: job.slug,
          authorId,
          selected,
          title: (
            <Flex width="280px" maxWidth="280px" alignItems="flex-start" justifyContent="flex-start">
              <Flex flexDirection="column">
                <Text color="text.main" fontWeight="500" fontSize={[1]}>
                  {job.title}
                </Text>
              </Flex>
            </Flex>
          ),
          company: (
            <Flex alignItems="flex-start" sx={{ gap: '8px' }} color="text.main">
              <Box width="24px" height="24px">
                <ProfilePicture variant="square" imageUrl={job.organization.logoUrl} title={companyName} />
              </Box>
              <Text color="text.main" fontSize="14px">
                {companyName}
              </Text>
            </Flex>
          ),
          source: (
            <Text fontSize={[1]} color="text.main">
              {strings.jobs.job_sources_for_admin_portal[job.source]}
            </Text>
          ),
          status: (
            <Flex fontSize={[1]} alignItems="flex-start">
              <JobStatus job={job} />
            </Flex>
          ),
          visibility: (
            <Flex fontSize={[1]}>
              <JobVisibility visibility={job.visibility} onShowJobRulesClick={onShowJobRulesClick} />
            </Flex>
          ),
          featured: (
            <Flex fontSize={[1]}>
              <JobFeatured isFeatured={job.featured === 'featured'} />
            </Flex>
          ),
          list: (
            <Flex minWidth="280px" fontSize={[1]} sx={{ gap: '8px' }}>
              <CreateListItem
                onListClicked={onListClicked}
                job={{
                  id: j.id,
                  ...j.attributes,
                }}
                createMatches={createMatches}
                isCreatingList={isCreatingList}
              />
            </Flex>
          ),
          applicants: (
            <Flex justifyContent="flex-end" fontSize={[1]}>
              <Text pr={3} color="text">
                {typeof job.jobApplicationsCount === 'number' ? job.jobApplicationsCount : ' -'}
              </Text>
            </Flex>
          ),
          createdAt: (
            <Flex width={[1]} flexDirection="column">
              <Text fontSize={[1]} color="text.main">
                {moment(job.createdAt).format('D MMM YYYY')}
              </Text>
              <Text fontSize={[1]} color="text.subtle">
                {getAuthor(job)}
              </Text>
            </Flex>
          ),
          expirationDate: (
            <Flex width={[1]}>
              <Text fontSize={[1]} color="text.main">
                {getExpirationDate(job)}
              </Text>
            </Flex>
          ),
          location: (
            <Text fontSize={[1]} color="text.main">
              {job.locations && job.locations.length ? job.locations?.join(', ') : '-'}
            </Text>
          ),
          compensation: (
            <Text fontSize={[1]} color="text.main">
              {job.compensationCurrency &&
              job.compensationAmountMinCents &&
              job.compensationAmountMaxCents &&
              job.compensationPeriod
                ? displayCompensationPeriod({
                    currency: job.compensationCurrency,
                    amountMinCents: job.compensationAmountMinCents,
                    amountMaxCents: job.compensationAmountMaxCents,
                    offersEquity: job.compensationOffersEquity,
                    period: job.compensationPeriod,
                  })
                : '-'}
            </Text>
          ),
        };
      }),
    [jobs, onShowJobRulesClick, onListClicked, createMatches, isCreatingList],
  );
