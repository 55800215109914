import React, { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { ChevronRight, ListRestart, Stars } from 'lucide-react';
import PropTypes from 'prop-types';
import { AutoUpdateModal } from './autoUpdateModal';
import { PreviewAiMatches } from './previewAiMatches';

export const SmartOptions = ({
  onEnableMatch,
  match,
  updateSmartOption,
  contactsCount,
  isEdit,
  hasAutoUpdateFilters,
}) => {
  const [autoUpdateAction, setAutoUpdateAction] = useState();
  const handleUpdateSmartOption = (args) => {
    if (args.action === 'disable' && isEdit) {
      setAutoUpdateAction('disable');
      return;
    }

    updateSmartOption(args);
  };
  const smartOptions = [
    {
      key: 'matches',
      title: 'AI matches for open roles',
      description:
        'Let our AI do the lifting: select a job and get ongoing suggestions with the best matches in your contacts.',
      icon: Stars,
      onClick: () => onEnableMatch('matches'),
      show: Boolean(match),
      onEdit: () =>
        updateSmartOption({
          type: 'ai-match-settings',
          matchType: match?.job?.id ? 'job' : 'private',
          action: 'edit',
          match,
        }),
      onDisableMatch: () =>
        updateSmartOption({
          type: 'ai-match-settings',
          matchType: match.job.id ? 'job' : 'private',
          action: 'disable',
        }),
    },
    {
      key: 'autoUpdate',
      title: 'Auto-update',
      description: (
        <Box>
          Auto-update Required OFF Automatically add and removes contacts according to your filters daily, while
          preserving manual edits. Learn more. Enable{' '}
        </Box>
      ),
      icon: ListRestart,
      onClick: () => {
        if (contactsCount > 0) {
          setAutoUpdateAction('enable');
          return;
        }

        onEnableMatch('autoUpdate');
      },
      show: hasAutoUpdateFilters,
      onEdit: () => handleUpdateSmartOption({ type: 'autoUpdate', action: 'edit' }),
      onDisableMatch: () => handleUpdateSmartOption({ type: 'autoUpdate', action: 'disable' }),
    },
  ];

  return (
    <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
      {smartOptions.map((option) => (
        <Flex
          key={option.title}
          data-testid={option.key}
          sx={{
            gap: '8px',
            color: 'text.main',
            fontSize: '14px',
            width: '100%',
          }}
        >
          <Box
            as={option.icon}
            size="24px"
            sx={{
              color: !option.show ? 'neutral.300' : 'purple.400',
            }}
          />
          <Flex sx={{ flexDirection: 'column', gap: '16px', flex: 1 }}>
            <Flex
              sx={{
                gap: '4px',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Flex sx={{ gap: '4px', flexDirection: 'column', maxWidth: '430px' }}>
                <Flex sx={{ alignItems: 'center', gap: '8px' }}>
                  <Box color="text.dark" fontWeight="500">
                    {option.title}
                  </Box>
                  <Flex
                    sx={{
                      backgroundColor: !option.show ? 'neutral.50' : 'green.600',
                      borderRadius: '100px',
                      p: '4px 12px',
                      color: !option.show ? 'neutral.700' : 'white',
                      textTransform: 'uppercase',
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '15px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      height: '20px',
                    }}
                  >
                    {!option.show ? 'off' : 'on'}
                  </Flex>
                </Flex>
                <Box>{option.description}</Box>
                {option.key === 'matches' && option.show && <PreviewAiMatches match={match} />}
              </Flex>
              {option.show && (
                <Flex sx={{ gap: '16px', color: 'text.main' }}>
                  <Flex
                    data-testid={`${option.key}-disable`}
                    onClick={option.onDisableMatch}
                    sx={{ gap: '8px', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Box>Disable</Box>
                  </Flex>
                  <Flex
                    data-testid={`${option.key}-edit`}
                    onClick={option.onEdit}
                    sx={{ gap: '8px', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Box>Edit</Box>
                    <Box as={ChevronRight} size="16px" />
                  </Flex>
                </Flex>
              )}
              {!option.show && (
                <Flex
                  data-testid={`${option.key}-enable`}
                  onClick={option.onClick}
                  sx={{ color: 'text.main', gap: '8px', alignItems: 'center', cursor: 'pointer' }}
                >
                  <Box>Enable</Box>
                  <Box as={ChevronRight} size="16px" />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      ))}
      {autoUpdateAction && (
        <AutoUpdateModal
          action={autoUpdateAction}
          onCancel={() => setAutoUpdateAction()}
          onEnable={() => {
            if (autoUpdateAction === 'enable') {
              onEnableMatch('autoUpdate');
            } else {
              updateSmartOption({ type: 'autoUpdate', action: 'disable' });
            }
            setAutoUpdateAction();
          }}
        />
      )}
    </Flex>
  );
};

SmartOptions.propTypes = {
  onEnableMatch: PropTypes.func.isRequired,
  match: PropTypes.object,
  updateSmartOption: PropTypes.func.isRequired,
  contactsCount: PropTypes.number.isRequired,
  isEdit: PropTypes.bool.isRequired,
  hasAutoUpdateFilters: PropTypes.bool.isRequired,
};

SmartOptions.defaultProps = {
  match: null,
};
