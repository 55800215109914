import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const JobAppearanceFilterLoader = ({ mode }) => (
  <Flex
    sx={{
      mt: '64px',
      p: '16px',
      borderRadius: '4px',
      div: {
        borderRadius: '8px',
      },
      gap: '16px',
      flexDirection: 'column',
      boxShadow: '0px 1px 0px 0px #0000000F',
    }}
    width="100%"
    backgroundColor="#ffffff"
  >
    {mode !== 'mobile' && (
      <>
        <Box width="100%" height="40px" bg="neutral.50" />
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Flex
            sx={{
              gap: '16px',
              div: {
                bg: 'neutral.50',
              },
            }}
          >
            {[0, 1, 2, 3, 4, 5, 6].slice(mode === 'tablet' ? 2 : 0).map((index) => (
              <Box key={index} width="77px" height="24px" />
            ))}
          </Flex>
          <Box width="170px" height="32px" bg="neutral.50" />
        </Flex>
      </>
    )}

    {mode === 'mobile' && [0, 1, 2, 3].map((index) => <Box key={index} width="100%" height="40px" bg="neutral.50" />)}
  </Flex>
);

JobAppearanceFilterLoader.propTypes = {
  mode: PropTypes.string.isRequired,
};
export default JobAppearanceFilterLoader;
