import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { JobBoardHero } from '@getro/rombo';
import PropTypes from 'prop-types';
import JobAppearanceFilterLoader from './component/jobAppearanceFilterLoader';
import JobAppearanceNavigation from './component/jobAppearanceNavigation';
import JobAppearanceListLoader from './component/jobAppearanceListLoader';
import { useSettingsPreview } from './hooks/useSettingsPreview';
import LiveViewModeSwitcher from './component/liveViewModeSwitcher';
import { IFrame } from './component/iframe';
import { useLiveViewFrame } from './hooks/useLiveViewFrame';
import { CustomHeader } from './component/customHeader';
import { JobAppearancePreviewDragger } from './component/jobAppearancePreviewDragger';

export const SettingsPreview = ({
  network,
  standardDesign,
  customHeroAndAD,
  noCustomHeroAndAD,
  advancedDesignSettings,
  ...props
}) => {
  const { ref, views, view, hasOrganizationsString, hideNetworkInfo, mode } = useSettingsPreview(network);
  const { ref: iframeRef, ready, setRef, container } = useLiveViewFrame(props.customHero, props);

  return (
    <Box
      sx={{
        width: '100%',
        minWidth: '1200px',
        height: '100vh',
        backgroundColor: 'neutral.100',
        overflowX: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px 30px',
        resize: 'horizontal',
        userSelect: 'none',
      }}
    >
      <LiveViewModeSwitcher view={view} views={views} />
      <Box
        className="job-appearance-preview"
        id="job-appearance-preview"
        ref={ref}
        sx={{
          width: '100%',
          height: '1180px',
          m: '0px auto',
          mt: '16px',
          position: 'relative',
          transition: 'width 0.1s linear',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          boxShadow: 'inset 0px 6px 40px 0px #1B243214, 0px 2px 8px 0px #1B24320A',
        }}
      >
        <IFrame ref={iframeRef} ready={ready} setRef={setRef} container={container}>
          <Flex
            sx={{
              flexDirection: 'column',
            }}
          >
            {advancedDesignSettings && props.advancedDesign && container && (
              <CustomHeader
                network={network}
                advancedDesign={advancedDesignSettings}
                customHero={props.customHero}
                iframeRef={iframeRef}
              />
            )}
            <Flex
              sx={{
                flexDirection: 'column',
                bg: 'neutral.20',
                ...(props.advancedDesign
                  ? {}
                  : {
                      '*': {
                        fontFamily:
                          '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"',
                      },
                    }),
              }}
            >
              {!props.customHero && (
                <JobBoardHero
                  network={network}
                  claimCompanyProfileInHero={props.claimOrganizationProfileInHero}
                  darkHeroText={props.darkHeroText}
                  hasAdvancedDesign={props.advancedDesign}
                  organizationsString={hasOrganizationsString ? 'organizations' : 'companies'}
                  orgString={hasOrganizationsString ? 'organization' : 'portfolio company'}
                  claimYourProfileHeroCtaClicked={() => {}}
                  getroAppUrl=""
                  hideNetworkInfo={hideNetworkInfo}
                  isAuthenticated={false}
                  hideLogoInHero={props.hideLogoInHero || props.advancedDesign}
                  hideNameInHero={props.hideNameInHero}
                  hideNumbersInHero={props.hideNumbersInHero || props.numbersInNav}
                  hideTnCtaInHero={network.features.indexOf('hide_tn_cta_in_hero') !== -1}
                  talentNetwork={props.talentNetwork}
                  tnHidden={props.tnHidden}
                  tnOnboardingV2={props.tnOnboardingV2}
                  tnCtaButtonText={props.tnCtaButtonText}
                  subtitle={props.heroSubtitle}
                  heroImageUrl={props.heroImageUrl}
                  tagline={props.heroTitle}
                  tnSettings={props.tnSettings}
                  heroColorMask={props.heroColorMask}
                  slug={network.slug}
                  name={network.name}
                />
              )}
              <Box>
                <JobAppearanceNavigation
                  companiesCount={network.companiesCount}
                  jobsCount={network.jobsCount}
                  tabsOrder={props.tabsOrder}
                  mode={mode}
                  showNumberInNav={props.showHeroInNav}
                  showAlert={!network.features.includes('no_job_alerts')}
                />
                <Box px={mode === 'mobile' ? '16px' : '32px'} maxWidth="1100px" width="100%" m="0px auto">
                  <JobAppearanceFilterLoader mode={mode} />
                  <Flex sx={{ gap: '8px', flexDirection: 'column', mt: '64px' }}>
                    {[1, 2, 3, 4].map((i) => (
                      <JobAppearanceListLoader key={i} mode={mode} />
                    ))}
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </Flex>
        </IFrame>
        <JobAppearancePreviewDragger />
      </Box>
    </Box>
  );
};

SettingsPreview.propTypes = {
  network: PropTypes.object.isRequired,
  tnCtaButtonText: PropTypes.string,
  tnCtaDescriptionText: PropTypes.string,
  tnCtaTitleText: PropTypes.string,
  onTalentNetworkHeroCTAClick: PropTypes.func,
  advancedDesign: PropTypes.bool,
  claimOrganizationProfileInHero: PropTypes.bool.isRequired,
  darkHeroText: PropTypes.bool.isRequired,
  heroColorMask: PropTypes.string,
  heroImageUrl: PropTypes.string,
  heroSubtitle: PropTypes.string.isRequired,
  heroTitle: PropTypes.string.isRequired,
  hideLogoInHero: PropTypes.bool.isRequired,
  hideNameInHero: PropTypes.bool.isRequired,
  hideNumbersInHero: PropTypes.bool.isRequired,
  hideTnCtaInHero: PropTypes.bool.isRequired,
  tabsOrder: PropTypes.string.isRequired,
  talentNetwork: PropTypes.bool.isRequired,
  tnHidden: PropTypes.bool.isRequired,
  tnOnboardingV2: PropTypes.bool.isRequired,
  standardDesign: PropTypes.bool.isRequired,
  customHeroAndAD: PropTypes.bool.isRequired,
  noCustomHeroAndAD: PropTypes.bool.isRequired,
  showHeroInNav: PropTypes.bool.isRequired,
  numbersInNav: PropTypes.bool.isRequired,
  customHero: PropTypes.bool.isRequired,
  tnSettings: PropTypes.object,
  advancedDesignSettings: PropTypes.object,
};

SettingsPreview.defaultProps = {
  tnCtaButtonText: '',
  tnCtaDescriptionText: '',
  tnCtaTitleText: '',
  onTalentNetworkHeroCTAClick: () => {},
  advancedDesign: false,
  tnSettings: {},
  heroColorMask: null,
  heroImageUrl: null,
  advancedDesignSettings: null,
};
