import React from 'react';
import { Box } from 'rebass/styled-components';
import { useSelector } from 'react-redux';
import { useGetJobBoardSettingsQuery, useUpdateJobBoardSettingsMutation } from 'services/networkV2';
import { trackEvent } from 'helpers/analytics';
import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import { activeNetworkSelector } from '../../../redux/selectors';
import usePageHook from '../../../hooks/usePageHook';
import { JobBoardSettings } from '../components/JobBoardSettings';
import { JobBoardAppearancePanel } from '../components/JobBoardAppearancePanel';

const pageTitle = 'Appearance settings for job board';

const JobBoard = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { data, isLoading: loadingSettings } = useGetJobBoardSettingsQuery();
  const [update, { isLoading }] = useUpdateJobBoardSettingsMutation();

  usePageHook(pageTitle);

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader title={pageTitle} description="Easily customize how your job board looks." />
      <Box pt="32px">
        <JobBoardAppearancePanel activeNetwork={activeNetwork} />
        <JobBoardSettings
          domain={activeNetwork.url}
          faviconUrl={data?.faviconUrl ?? ''}
          update={async (updates) =>
            update(updates).then(() => {
              trackEvent('network_settings:edit_job_board_general_settings', {
                collectionId: activeNetwork.id,
              });
            })
          }
          isUpdating={isLoading}
          isLoading={loadingSettings}
        />
      </Box>
    </Box>
  );
};

export default JobBoard;
