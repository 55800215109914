import strings from 'strings';
import { EXPIRATION_OPTIONS } from '../../molecules/RenewJob/constants';

export const CURRENCY_OPTIONS = [
  { value: 'AUD', label: 'AUD', description: 'Australia dollar' },
  { value: 'BRL', label: 'BRL', description: 'Brazil real' },
  { value: 'CAD', label: 'CAD', description: 'Canadian dollar' },
  { value: 'EUR', label: 'EUR', description: 'Euro' },
  { value: 'GBP', label: 'GBP', description: 'Great Britain pound' },
  { value: 'INR', label: 'INR', description: 'Indian rupee' },
  { value: 'JPY', label: 'JPY', description: 'Japan yen' },
  { value: 'MXN', label: 'MXN', description: 'Mexican peso' },
  { value: 'NZD', label: 'NZD', description: 'New Zealand dollar' },
  { value: 'SGD', label: 'SGD', description: 'Singapore dollar' },
  { value: 'USD', label: 'USD', description: 'US dollar' },
];

export const PERIOD_OPTIONS = [
  { value: 'year', label: strings.jobs.compensationPeriod.year },
  { value: 'month', label: strings.jobs.compensationPeriod.month },
  { value: 'hour', label: strings.jobs.compensationPeriod.hour },
];

export const EMPTY_VALUES = {
  applicationMethod: 'on_job_board',
  applicationPath: null,
  compensationCurrency: CURRENCY_OPTIONS.find(({ value }) => value === 'USD'),
  compensationAmountMaxCents: '',
  compensationAmountMinCents: '',
  compensationPeriod: PERIOD_OPTIONS[0],
  compensationPublic: true,
  description: '',
  employmentTypes: [],
  jobFunctions: [],
  locations: [],
  title: '',
  remote: false,
  expirationDate: EXPIRATION_OPTIONS.find(({ value }) => value === 60),
  companyId: null,
  notifiedUsers: '',
};

export const APPLICATION_METHOD_OPTIONS = [
  {
    value: 'on_job_board',
    label: 'On job board',
    description: 'Job seekers apply directly on your job board, you can select who gets applicants via email.',
  },
  {
    value: 'url',
    label: 'External website',
    description: 'Job seekers apply on an external website, applicants can’t be tracked by Getro.',
  },
];
