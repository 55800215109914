import React from 'react';
import { Box, Flex, Button } from 'rebass/styled-components';
import { MoreHorizontal } from 'lucide-react';
import PropTypes from 'prop-types';
import { Card } from 'components/atoms/Card';
import { Dropdown } from 'components/molecules/dropdown';

const TableActionMenu = React.memo(({ row, isLast = true, rowActions }) => {
  if (!isLast || !rowActions || !rowActions.items) return null;

  return (
    <Box
      sx={{
        minWidth: '24px',
        maxHeight: '24px',
        height: '24px',
        justifyContent: 'center',
        cursor: 'pointer',
        alignItems: 'center',
      }}
      tx="dataTable"
      data-testid="row-action"
      display="flex"
    >
      <Dropdown
        autoPlacement
        trigger={
          <Box
            size="16px"
            variant="icon"
            sx={{ stroke: 'text' }}
            strokeWidth="1.5"
            aria-hidden="true"
            as={MoreHorizontal}
          />
        }
      >
        <Card variant="card.default" sx={{ position: 'fixed', width: '200px' }} py={2} px={0}>
          <Flex flexDirection="column" width="200px">
            {rowActions.items.map(({ isDisabled = () => false, Item = Button, onClick = () => {}, ...props }) => (
              <Item
                key={`row_actions_${row.id}`}
                row={row}
                width={1}
                textAlign="left"
                variant="tertiary"
                pl={3}
                {...props}
                disabled={isDisabled(row.original)}
                onClick={() => onClick(row.original)}
              />
            ))}
          </Flex>
        </Card>
      </Dropdown>
    </Box>
  );
});

TableActionMenu.propTypes = {
  row: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
  rowActions: PropTypes.object.isRequired,
};

export default TableActionMenu;
