import React, { useEffect, useMemo, useRef } from 'react';
import { Building2, Settings2 } from 'lucide-react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import { useParams, Link } from 'react-router-dom';
import { useGetSharedListQuery } from 'services/contacts';
import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import MainLayout from '../../components/layout/Main';
import { contactsV2Api } from '../../services/contactsV2';
import { ShareList } from './shareList';
import usePageTitle from '../../hooks/usePageTitle';
import { ActionsDropdown } from './actionsDropdown';
import ContactsLayout from './layout/contactsLayout';
import CompaniesLayout from './layout/companiesLayout';

const ListDetail = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { listId } = useParams();
  const {
    data: { sharedList } = {},
    refetch: refetchSharedList,
    isLoading: isLoadingSharedList,
  } = useGetSharedListQuery({ listId });
  const pollingRef = useRef(null);
  const dispatch = useDispatch();
  const containerProps = sharedList?.kind === 'companies' ? { minHeight: '100vh', pb: '0px' } : {};

  useEffect(() => {
    const cleanup = () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };

    if (sharedList?.refreshInProgress) {
      if (!pollingRef.current) {
        pollingRef.current = setInterval(() => {
          refetchSharedList();
        }, 3000);
      }
    } else if (sharedList && !sharedList.refreshInProgress && pollingRef.current) {
      cleanup();
      dispatch(contactsV2Api.util.invalidateTags([{ type: 'contacts', id: parseInt(listId, 10) }]));
    }

    return () => cleanup();
  }, [sharedList?.refreshInProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  const listName = useMemo(() => {
    const { name } = sharedList || {};
    return name;
  }, [sharedList]);

  usePageTitle(listName);

  return (
    <MainLayout containerProps={containerProps}>
      <MainLayoutHeader
        title={
          <Flex alignItems="center" sx={{ gap: '8px' }}>
            <Text as="span">{sharedList?.name || ''}</Text>
            {sharedList?.kind === 'companies' && (
              <Flex
                alignItems="center"
                bg="neutral.50"
                sx={{ gap: '4px', borderRadius: 'circle', px: '8px', py: '1px' }}
              >
                <Box as={Building2} height="12px" width="12px" aria-hidden="true" />
                <Text fontSize="12px" fontWeight="500" lineHeight="16px">
                  Companies
                </Text>
              </Flex>
            )}
          </Flex>
        }
        actions={[
          ...(sharedList?.kind === 'companies'
            ? [
                <Box key="edit-link" sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Box as={Settings2} width="16px" height="16px" />
                  <Box
                    as={Link}
                    sx={{
                      color: 'text.main',
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '16px',
                      textDecoration: 'none',
                    }}
                    to={`/networks/${activeNetwork.slug}/list/${listId}/contacts/edit`}
                  >
                    List settings
                  </Box>
                </Box>,
              ]
            : [
                <ActionsDropdown key="actions-dropdown" sharedList={sharedList} />,
                <ShareList key="shared-list-menu" sharedList={sharedList} />,
              ]),
        ]}
        breadcrumbs={[
          {
            label: 'Lists',
            to: `/networks/${activeNetwork.slug}/lists`,
          },
        ]}
      />
      {sharedList?.kind === 'people' && (
        <ContactsLayout sharedList={sharedList} activeNetwork={activeNetwork} listId={listId} listName={listName} />
      )}
      {sharedList?.kind === 'companies' && (
        <CompaniesLayout
          sharedList={sharedList}
          activeNetwork={activeNetwork}
          listId={listId}
          isLoadingSharedList={isLoadingSharedList}
        />
      )}
    </MainLayout>
  );
};

export default ListDetail;
