import { AlertTriangle } from 'lucide-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';

export const MatchDetailItem = ({ label, value }) => (
  <Flex sx={{ flexDirection: 'column', gap: '4px' }}>
    <Box as="p" color="text.subtle" letterSpacing="1px" sx={{ textTransform: 'uppercase', fontSize: '12px' }}>
      {label}
    </Box>
    {!value && (
      <Flex sx={{ alignItems: 'center', gap: '8px' }}>
        <Box as={AlertTriangle} size="16px" sx={{ color: '#DE7B02' }} />
        <Box fontSize="14px" lineHeight="20px" color="text.subtle" fontStyle="italic">
          Missing
        </Box>
      </Flex>
    )}
    {value && (
      <Box fontSize="14px" lineHeight={1.4} color="text.main">
        {Array.isArray(value) ? value.join(' · ') : value}
      </Box>
    )}
  </Flex>
);

MatchDetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

MatchDetailItem.defaultProps = {
  value: null,
};
