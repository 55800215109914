import React, { useMemo } from 'react';

import { isBooleanSearch } from 'helpers/booleanValidator';

import { Box } from 'rebass/styled-components';

export const useOptions = ({ searchText, useSearchAsTag, loading, isMulti, menuOptions, selected }) => {
  const filteredOptions = useMemo(
    () =>
      isMulti
        ? menuOptions.filter((option) => !selected?.some?.((selectedOption) => selectedOption.value === option.value))
        : menuOptions,
    [isMulti, menuOptions, selected],
  );

  const isBoolean = isBooleanSearch(searchText);

  const options = useMemo(() => {
    const searchAsTag = {
      label: searchText,
      children: (
        <>
          Press ‘enter’ to add{' '}
          <Box as="span" color="inherit" fontWeight="500">
            {searchText}
          </Box>
        </>
      ),
      value: searchText,
    };

    if (isBoolean && useSearchAsTag) {
      return [searchAsTag];
    }

    if (useSearchAsTag) {
      return [searchAsTag, ...(loading ? [] : filteredOptions)];
    }

    return [...filteredOptions];
  }, [filteredOptions, isBoolean, loading, searchText, useSearchAsTag]);

  return {
    options,
    isBoolean,
  };
};
