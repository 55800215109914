import React, { useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import * as Yup from 'yup';
import { Loader, MessageBlock, FormInput, Button, FormImage } from '@getro/rombo';
import { openChat } from 'helpers/supportChat';
import strings from '../../../../strings';
import { GenericError } from '../../../../components/molecules/genericError';

const formSchema = Yup.object().shape({
  logoUrl: Yup.string().label('Logo URL').url().nullable(),
});

const General = ({ activeNetwork, generalSettings, fetchError, saveError, submitting, onSubmit }) => {
  const formRef = useRef(null);

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  if (fetchError) {
    return <MessageBlock>{strings.genericError}</MessageBlock>;
  }

  if (generalSettings === null) {
    return <Loader />;
  }

  return (
    <>
      <Box pb="32px">
        <Text fontSize="14px" fontWeight="medium" color="text.dark">
          Name
        </Text>
        <Text fontSize="14px" mt="4px" pb="8px">
          To change the network name,{' '}
          <Box
            onClick={() => openChat()}
            as="span"
            sx={{
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            contact us.
          </Box>
        </Text>
        <FormInput value={activeNetwork.name} disabled />
      </Box>
      <Formik
        innerRef={formRef}
        initialValues={generalSettings}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, status, setFieldValue }) => (
          <Form>
            <Flex alignItems="center">
              <Field
                width="64px"
                height="64px"
                label="Logo"
                filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
                name="logoUrl"
                component={FormImage}
                field={{ name: 'logoUrl', value: values.logoUrl }}
                helpText="This is used on different places like your Job Board, Admin Portal, and emails. Upload a 256x256px, .png, .gif, or .jpg file."
                form={{
                  setFieldValue,
                  name: 'logoUrl',
                }}
              />
            </Flex>
            {!!(status?.error || saveError) && <GenericError />}
            <Box mt="24px">
              <Button size="small" type="submit" loading={submitting}>
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

General.propTypes = {
  activeNetwork: PropTypes.object.isRequired,
  fetchError: PropTypes.string,
  generalSettings: PropTypes.shape({
    logoUrl: PropTypes.string,
  }),
  saveError: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

General.defaultProps = {
  fetchError: null,
  generalSettings: null,
};

export default General;
