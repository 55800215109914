import { Trash, ListX, RefreshCcw } from 'lucide-react';
import { useState, useCallback } from 'react';
import toast from 'react-hot-toast';
import { trackEvent } from 'helpers/analytics';
import { useDeleteContactMutation, useRemoveContactFromSharedListMutation } from 'services/contacts';
import strings from 'strings';

export const useContactsMoreActions = ({
  contact,
  queryParams,
  networkId,
  deleteContactFromList,
  listId,
  page,
  fullContactView,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRefreshModal, setOpenRefreshModal] = useState(false);

  const [deleteContact, { isLoading: deleting }] = useDeleteContactMutation();
  const [removeContactFromSharedList, { isLoading: isLoadingRemoveContact }] = useRemoveContactFromSharedListMutation();
  const onDeleteContactFromList = useCallback(() => {
    if (!isLoadingRemoveContact && contact?.id && listId) {
      removeContactFromSharedList({
        list: { id: listId },
        contactId: contact.id,
      });

      trackEvent('contacts:list:remove_contact_single', {
        listId,
        collectionId: networkId,
        origin: fullContactView ? 'full_profile_lists_management' : 'card_more_actions',
        page,
      });

      toast('Contact removed from list', { icon: 'success', id: 'delete-contact-from-list-success' });
    }
  }, [contact?.id, isLoadingRemoveContact, listId, removeContactFromSharedList]); // eslint-disable-line react-hooks/exhaustive-deps

  const options = [
    {
      icon: RefreshCcw,
      text: 'Refresh profile',
      onClick: () => setOpenRefreshModal(true),
      show: true,
    },
    {
      icon: ListX,
      text: 'Exclude from this list',
      onClick: onDeleteContactFromList,
      show: deleteContactFromList && listId,
    },
    {
      text: 'divider',
      show: true,
    },
    {
      icon: Trash,
      text: 'Delete',
      onClick: () => setOpenDeleteModal(true),
      show: true,
    },
  ];

  const handleDelete = async () => {
    const { error } = await deleteContact({
      contactId: contact.id,
      queryParams,
    });

    if (error) {
      toast(strings.genericError, { icon: 'error', id: 'delete-contact-success' });
      return;
    }

    trackEvent('contacts:delete_contacts', {
      collectionId: networkId,
      numberOfDeletedContacts: 1,
      method: 'single_delete',
      origin: queryParams.listId ? 'list' : 'contacts',
      contactId: contact.id,
      enriched: contact.enrichmentStatus === 'enriched' ? 'true' : 'false',
      contactSource: null,
    });

    setOpenDeleteModal(false);
    toast('Contact deleted', { icon: 'success', id: 'delete-contact-success' });
  };

  const eventPage = listId ? 'list' : 'contacts';

  return {
    openDeleteModal,
    closeDeleteModal: () => setOpenDeleteModal(false),
    onDeleteContact: handleDelete,
    options: options.filter((option) => option.show),
    deleting,
    openRefreshModal,
    closeRefreshModal: () => setOpenRefreshModal(false),
    trackRefreshEvent: () => {
      trackEvent('contacts:refresh_profile_click', {
        collectionId: networkId,
        contactId: contact.id,
        linkedinHandle: contact.linkedinHandle,
        page: page === 'hires' ? 'hires' : eventPage,
        informationDaysOld: contact.enrichedProfileAgeInDays,
        contactEnriched: contact.enrichmentStatus === 'enriched',
        experiencesCount: contact.contactWorkExperiences.length,
      });
    },
  };
};
