import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { Share, ExternalLink, Download, Edit2, RefreshCw, Copy, Star, Eye, X } from 'lucide-react';

export const useActionItems = ({
  jobActionsLoading,
  isJobSourcePostedActiveOrExpired,
  getJobById,
  jobActions,
  activeNetwork,
  source,
  isNetworkAdmin,
  setSelectedJob,
}) => {
  const history = useHistory();
  return useMemo(
    () =>
      [
        {
          isDisabled: (e) => {
            const job = getJobById(e);
            return !(job.status === 'active');
          },
          title: 'Share',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Share} />
              Share
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            setSelectedJob(job);
            jobActions.onShareJob(job);
          },
          onlyNetworkAdmin: false,
        },
        {
          isDisabled: (e) => {
            const job = getJobById(e);
            return !(job.status === 'active');
          },
          isLoading: jobActionsLoading.isViewing,
          title: 'View',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={ExternalLink} />
              View
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            jobActions.onViewJob({ job });
          },
          onlyNetworkAdmin: false,
        },
        {
          isLoading: jobActionsLoading.isRequestingApplicationsDownload,
          title: 'Applicants',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Download} />
              Applicants
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            jobActions.onDownloadJobApplications(job);
          },
          isDisabled: (e) => {
            const job = getJobById(e);
            return !job.jobApplicationsCount;
          },
          onlyNetworkAdmin: false,
        },
        {
          Item: Box,
          px: 3,
          children: <Box as="hr" variant="hr" />,
        },

        {
          isDisabled: (e) => {
            const job = getJobById(e);
            return !(job.status === 'active' && job.source === 'posted');
          },
          isLoading: jobActionsLoading.isEditing,
          title: 'Edit',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Edit2} />
              Edit
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            if (source === 'employer_profile') {
              history.push(
                `/networks/${activeNetwork.slug}/companies/${job.organization.id}-${job.organization.slug}/edit-job/${job.id}`,
              );
            } else {
              history.push(`/networks/${activeNetwork.slug}/jobs/edit-job/${job.id}`);
            }
          },
          onlyNetworkAdmin: false,
        },
        {
          isDisabled: isJobSourcePostedActiveOrExpired,
          isLoading: jobActionsLoading.isExtending,
          title: 'Extend',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={RefreshCw} />
              Extend
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            setSelectedJob(job);
            jobActions.onExtendJobRequest(job);
          },
          onlyNetworkAdmin: false,
        },
        {
          title: 'Duplicate',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Copy} />
              Duplicate
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            if (source === 'employer_profile') {
              history.push(
                `/networks/${activeNetwork.slug}/companies/${job.organization.slug}/duplicate-job/${job.id}`,
              );
            } else {
              history.push(`/networks/${activeNetwork.slug}/jobs/duplicate-job/${job.id}`);
            }
          },
          onlyNetworkAdmin: false,
        },
        {
          Item: Box,
          px: 3,
          children: <Box as="hr" variant="hr" />,
        },
        {
          isLoading: jobActionsLoading.isFeaturingJob,
          title: 'Featured status',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Star} />
              Featured status
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            setSelectedJob(job);
            jobActions.onFeatureJobRequest(job);
          },
          isDisabled: (e) => {
            const job = getJobById(e);
            return job.status !== 'active' || job.visibility.includes('hidden');
          },
          onlyNetworkAdmin: true,
        },
        {
          isLoading: jobActionsLoading.isForcingVisibility,
          title: 'Set visibility',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Eye} />
              Set visibility
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            setSelectedJob(job);
            jobActions.onForceJobVisibilityRequest(job);
          },
          isDisabled: (e) => {
            const job = getJobById(e);
            return job.status !== 'active' || !isNetworkAdmin;
          },
          onlyNetworkAdmin: true,
        },
        {
          isLoading: jobActionsLoading.isClosing,
          title: 'Close',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={X} />
              Close
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            setSelectedJob(job);
            jobActions.onCloseJobRequest(job);
          },
          isDisabled: (e) => {
            const job = getJobById(e);
            if (job.source === 'posted') {
              if (job.status === 'expired') {
                return true;
              }
              if (job.status === 'active') {
                return false;
              }
            }
            return true;
          },
          onlyNetworkAdmin: false,
        },
      ].filter(({ onlyNetworkAdmin }) => isNetworkAdmin || !onlyNetworkAdmin),
    [
      jobActionsLoading.isViewing,
      jobActionsLoading.isRequestingApplicationsDownload,
      jobActionsLoading.isEditing,
      jobActionsLoading.isExtending,
      jobActionsLoading.isFeaturingJob,
      jobActionsLoading.isForcingVisibility,
      jobActionsLoading.isClosing,
      isJobSourcePostedActiveOrExpired,
      getJobById,
      setSelectedJob,
      jobActions,
      source,
      history,
      activeNetwork.slug,
      isNetworkAdmin,
    ],
  );
};
