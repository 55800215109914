import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from '@getro/rombo';
import { Text, Flex, Box } from 'rebass/styled-components';
import { ChevronLeft, X } from 'lucide-react';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from '../../../redux/selectors';
import './index.scss';

export const FocusLayout = ({ title, footer, children, backUrl, headerSx, useClose, bodyProps, sideHeader }) => {
  const history = useHistory();
  const activeNetwork = useSelector(activeNetworkSelector);

  return (
    <Flex data-testid="focus_layout" bg="white" flexDirection="column" sx={{ minHeight: '100%' }}>
      <Box
        data-testid="focus_layout_header"
        bg="neutral.0"
        sx={{ borderBottom: 1, borderBottomColor: 'gray.3', position: 'sticky', top: 0, zIndex: 1, ...headerSx }}
      >
        <Flex alignItems="center" sx={{ flexDirection: useClose ? 'row-reverse' : 'row' }}>
          <Box sx={{ borderRight: [0, 1], borderRightColor: ['transparent', 'gray.3'] }}>
            <Button
              to={backUrl}
              as={backUrl ? NavLink : null}
              onClick={() => {
                const isInDomain = document.referrer.indexOf(window.location.host) > -1;
                if (!backUrl && isInDomain) {
                  history.goBack();
                }

                if (!backUrl) {
                  history.push(`/networks/${activeNetwork.slug}`);
                }
              }}
              sx={{ border: 0 }}
              variant="secondary"
              fontSize={4}
              p={3}
              display="flex"
            >
              <Box
                data-item="close-icon"
                height="1em"
                width="1em"
                strokeWidth="1.5"
                aria-hidden="true"
                as={useClose ? X : ChevronLeft}
              />
              <Box variant="srOnly">Back</Box>
            </Button>
          </Box>

          <Flex pl={[0, '24px']} pr="24px" width="100%" justifyContent="space-between">
            <Text color="text.dark" fontSize={3} fontWeight="semibold" py="16px" variant="default" as="h1" flexGrow={1}>
              {title}
            </Text>
            {sideHeader}
          </Flex>
        </Flex>
      </Box>
      <Flex data-testid="focus_layout_body" flexGrow={1}>
        <Box width={[1, '1100px']} m={['auto']} mt={[4, 5]} pb={3} px={[3, 0]} {...bodyProps}>
          {children}
        </Box>
      </Flex>
      {footer && (
        <Flex data-testid="focus_layout_footer" py={3} sx={{ borderTop: 1, borderTopColor: 'gray.3' }}>
          <Box width={[1, '1100px']} m={['auto']} px={[3, 0]}>
            {footer}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

FocusLayout.propTypes = {
  title: PropTypes.string.isRequired,
  footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  backUrl: PropTypes.string,
  headerSx: PropTypes.object,
  useClose: PropTypes.bool,
  bodyProps: PropTypes.object,
  sideHeader: PropTypes.node,
};

FocusLayout.defaultProps = {
  backUrl: null,
  footer: null,
  headerSx: {},
  useClose: false,
  bodyProps: {},
  sideHeader: null,
};
