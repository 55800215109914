import { FormSectionTitle, FormToggle, Loader } from '@getro/rombo';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';
import { openChat } from 'helpers/supportChat';
import { trackEvent } from 'helpers/analytics';
import toast from 'react-hot-toast';
import { PublishChanges } from 'components/atoms/publishChanges';
import { useFilterVisibility } from './hooks/useFilterVisibility';

export const JobBoardFilters = () => {
  const { customFilters, jobBoardFilters, loading, initialValues, updateFilterVisibility, networkId, activeNetwork } =
    useFilterVisibility();

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Formik initialValues={initialValues}>
        <Form>
          <Flex sx={{ gap: '24px', flexDirection: 'column', mb: '32px' }}>
            {jobBoardFilters.map((filter) => (
              <Field
                id={filter.name}
                key={filter.name}
                component={FormToggle}
                name={filter.name}
                label={filter.label}
                helpText={filter.helpText}
                checked={filter.value}
                size="large"
                form={{
                  setFieldValue: async () => {
                    await updateFilterVisibility({
                      name: filter.name,
                      value: !filter.value,
                    });

                    toast(<PublishChanges domain={activeNetwork.url} />, {
                      icon: 'success',
                      id: 'job-board-settings-updated',
                    });
                  },
                }}
              />
            ))}
          </Flex>
          {customFilters.length > 0 && (
            <Box>
              <FormSectionTitle>Custom filters</FormSectionTitle>
              <Flex sx={{ gap: '24px', flexDirection: 'column', mt: '32px' }}>
                {customFilters.map((filter) => (
                  <Field
                    id={filter.name}
                    key={filter.id}
                    component={FormToggle}
                    name={filter.name}
                    label={filter.label}
                    checked={filter.value}
                    helpText={filter.helpText}
                    size="large"
                    form={{
                      setFieldValue: () => {
                        updateFilterVisibility({
                          name: filter.name,
                          value: !filter.value,
                          id: filter.id,
                        });
                      },
                    }}
                  />
                ))}
              </Flex>
            </Box>
          )}
        </Form>
      </Formik>
      <Box mt="32px">
        Want more filters?{' '}
        <Box
          onClick={() => {
            openChat();
            trackEvent('network_settings:add_more_filters_click', {
              collection_id: networkId,
            });
          }}
          as="a"
        >
          Let’s talk!
        </Box>
      </Box>
    </>
  );
};

export default JobBoardFilters;
