import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import CompaniesCreate from 'pages/list/steps/companiesCreate';
import { useUpdateSharedListMutation } from 'services/contacts';
import PropTypes from 'prop-types';
import SideModal from 'components/atoms/sideModal';
import toast from 'react-hot-toast';
import strings from 'strings';

export const EditListCompanies = ({ isLoading, name, domains }) => {
  const [updateSharedList, { isLoading: isUpdatingSharedList }] = useUpdateSharedListMutation();
  const history = useHistory();
  const { listId, slug } = useParams();

  return (
    <SideModal
      isLoading={isLoading}
      headerText="List settings"
      goBack={() => history.push(`/networks/${slug}/list/${listId}/contacts`)}
      onClose={() => history.push(`/networks/${slug}/list/${listId}/contacts`)}
      width="680px"
    >
      <CompaniesCreate
        formDefaultValues={{
          name,
          domains,
        }}
        isEdit
        isUpdatingList={isUpdatingSharedList}
        onSubmit={async (values) => {
          const { error } = await updateSharedList({
            listId,
            name: values.name,
            domains: values.domains,
          });

          if (error) {
            toast(strings.genericError, { icon: 'error', id: 'update-list-companies' });
          }

          if (!error) {
            toast('Changes saved successfully', { icon: 'success', id: 'update-list-companies' });
          }
        }}
      />
    </SideModal>
  );
};

EditListCompanies.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  name: PropTypes.string,
  domains: PropTypes.arrayOf(PropTypes.string),
};

EditListCompanies.defaultProps = {
  name: '',
  domains: [],
};
