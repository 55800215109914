import React, { useState } from 'react';
import { Modal, Button, Checkbox, Tooltip } from '@getro/rombo';
import { useHistory, useParams } from 'react-router-dom';
import { Flex, Text } from 'rebass/styled-components';
import { decamelize } from 'humps';
import { HelpCircle } from 'lucide-react';
import { useExportContactsMutation } from 'services/contacts';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import strings from 'strings';
import { useContactsSearchParam } from 'pages/contactsExtended/hooks/useContactsSearchParam';
import { parseFiltersData } from 'services/contacts/filters';
import { trackEvent } from 'helpers/analytics';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';

export const ExportListCompanies = ({ sharedList }) => {
  const [exportContacts, { isLoading }] = useExportContactsMutation();
  const history = useHistory();
  const { listId, slug } = useParams();
  const [queryParams] = useContactsSearchParam();

  const activeNetwork = useSelector(activeNetworkSelector);
  const [columns, setColumns] = useState([
    {
      id: 'name',
      label: 'Name',
      value: 'name',
      selected: true,
    },
    {
      id: 'domain',
      label: 'Website',
      value: 'domain',
      selected: true,
    },
    {
      id: 'location',
      label: 'Location',
      value: 'location',
      selected: true,
    },
    {
      id: 'size',
      label: 'Size',
      value: 'size',
      selected: true,
    },
    {
      id: 'stage',
      label: 'Fundraising stage',
      value: 'stage',
      selected: true,
    },
    {
      id: 'fundingYear',
      label: 'Funding year',
      value: 'founded',
      selected: true,
    },
    {
      id: 'industry',
      label: 'Industry',
      value: 'industry',
      selected: true,
    },
    {
      id: 'contacts_count',
      label: 'Number of contacts in company',
      value: 'contacts_count',
      selected: true,
      info: 'The number of contacts in your network currently working at the company.',
    },
    {
      id: 'contacts',
      label: 'Contact information',
      value: 'contacts',
      selected: true,
      info: 'For each contact at each company, include details such as name, job title, location, and more.',
    },
    {
      id: 'lists',
      label: 'Lists',
      value: 'lists',
      selected: false,
    },
  ]);

  return (
    <Modal
      onCancel={() => history.push(`/networks/${slug}/list/${listId}/contacts${history.location.search}`)}
      isOpen
      title="Export search results"
      actions={
        <Flex justifyContent="flex-end" alignItems="baseLine">
          <Button
            size="medium"
            loading={isLoading}
            onClick={async () => {
              const { error } = await exportContacts({
                csvFormat: 'aggregated',
                fields: columns.filter((c) => c.selected).map((c) => c.value),
                search_params: {
                  sharedListId: listId,
                  filters: parseFiltersData(queryParams.filters),
                },
                ...(queryParams.sortBy ? { sortBy: decamelize(queryParams.sortBy) } : undefined),
                ...(queryParams.sortDirection ? { sortDirection: queryParams.sortDirection } : undefined),
              });
              if (error) {
                toast(strings.genericError, { icon: 'error', id: 'update-list-companies' });
              }

              if (!error) {
                toast('Export successfully', { icon: 'success', id: 'export-list-companies' });
                history.push(`/networks/${slug}/list/${listId}/contacts${history.location.search}`);
                trackEvent('lists:export_csv', {
                  collection_id: activeNetwork.id,
                  list_slug: sharedList?.slug,
                  total_selected_rows: 0,
                  object_type: 'people',
                });
              }
            }}
          >
            Export
          </Button>
        </Flex>
      }
    >
      <Text sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '1.4', color: 'text.main', mb: '24px' }}>
        Select the information to be included in your export.
      </Text>
      <Flex sx={{ flexDirection: 'column', gap: '8px', label: { width: 'auto' } }}>
        {columns.map((column) => (
          <Flex sx={{ alignItems: 'center', gap: '8px' }}>
            <Checkbox
              containerProps={{ width: '16px', minWidth: '16px' }}
              name="job"
              checked={column.selected}
              label={column.label}
              onChange={() =>
                setColumns(columns.map((c) => (c.id === column.id ? { ...c, selected: !c.selected } : c)))
              }
            />
            {column.info && (
              <Tooltip
                sx={{
                  color: 'neutral.0',
                  width: '100%',
                  minWidth: '360px!important',
                  p: '16px',
                  '.tooltip-arrow:after': { ml: '20px' },
                }}
                position="top-start"
                content={<Text as="p">{column.info}</Text>}
                trigger={<Text as={HelpCircle} size="16px" sx={{ color: 'neutral.400' }} />}
              />
            )}
          </Flex>
        ))}
      </Flex>
    </Modal>
  );
};

ExportListCompanies.propTypes = {
  sharedList: PropTypes.object,
};

ExportListCompanies.defaultProps = {
  sharedList: {},
};

export default ExportListCompanies;
