import React from 'react';
import { Download } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ActionsIcon from 'pages/contactsExtended/bulkActions/actionsIcon';
import { BulkActionsBar } from '../../../components/atoms/BulkActionsBar';
import { trackEvent } from '../../../helpers/analytics';
import { activeNetworkSelector } from '../../../redux/selectors';
import { useDownloadContactsMutation } from '../../../services/contacts';

export const BulkActions = ({ contactIds, queryTotal, selectedRows, filters }) => {
  const [downloadContacts, { isLoading: isLoadingDownloadContacts }] = useDownloadContactsMutation();
  const activeNetwork = useSelector(activeNetworkSelector);

  const createContactData = () => {
    let selectedDataRows = [];
    const excludedDataRows = [];

    if (selectedRows.length > contactIds.length) {
      for (let i = 0; i < queryTotal; i += 1) {
        if (!selectedRows.includes(i)) {
          excludedDataRows.push(contactIds[i]);
        }
      }
    } else {
      selectedDataRows = selectedRows.map((position) => contactIds[position]);
    }

    return {
      selectedDataRows,
      excludedDataRows,
    };
  };

  const onClickExportContacts = async () => {
    if (!isLoadingDownloadContacts) {
      try {
        const { selectedDataRows, excludedDataRows } = createContactData();

        await downloadContacts({ contactIds: selectedDataRows, excludedContactIds: excludedDataRows, filters });

        trackEvent('lists:export_csv', {
          collection_id: activeNetwork.id,
          total_selected_rows: selectedRows.length,
          object_type: 'people',
        });

        toast('We will email your data in a few minutes.', { icon: 'success', id: 'export-csv-success' });
      } catch {
        toast('There was an unexpected error, try again or send us a chat.', {
          icon: 'danger',
          id: 'export-csv-error',
        });
      }
    }
  };

  return (
    <BulkActionsBar
      count={selectedRows.length}
      itemLabel="contact"
      actions={
        <ActionsIcon
          data-testid="export-contacts"
          isLoading={isLoadingDownloadContacts}
          onClick={onClickExportContacts}
          text="Download"
          icon={Download}
        />
      }
    />
  );
};

BulkActions.propTypes = {
  selectedRows: PropTypes.array.isRequired,
  contactIds: PropTypes.array.isRequired,
  queryTotal: PropTypes.number.isRequired,
  filters: PropTypes.object,
};

BulkActions.defaultProps = {
  filters: {},
};
