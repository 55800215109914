import React from 'react';
import { Box } from 'rebass/styled-components';
import { Tooltip } from '@getro/rombo';
import { Plus } from 'lucide-react';
import PropTypes from 'prop-types';

export const AddMoreButton = ({ disableAddMore, disableAddMoreMessage, showSearch, onShowSearch, onToggle }) => {
  if (disableAddMore) {
    return disableAddMoreMessage ? (
      <Tooltip
        content={disableAddMoreMessage}
        trigger={<Box sx={{ cursor: 'pointer', color: 'neutral.300' }} as={Plus} size="16px" />}
      />
    ) : (
      <Box sx={{ cursor: 'pointer', color: 'neutral.300' }} as={Plus} size="16px" />
    );
  }

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        if (showSearch) {
          onShowSearch();
        } else {
          onToggle();
        }
      }}
      sx={{ cursor: 'pointer', color: 'neutral.300' }}
      as={Plus}
      size="16px"
    />
  );
};

AddMoreButton.propTypes = {
  disableAddMore: PropTypes.bool,
  disableAddMoreMessage: PropTypes.string,
  showSearch: PropTypes.bool,
  onShowSearch: PropTypes.func,
  onToggle: PropTypes.func,
};

AddMoreButton.defaultProps = {
  disableAddMore: false,
  disableAddMoreMessage: null,
  showSearch: false,
  onShowSearch: () => {},
  onToggle: () => {},
};
