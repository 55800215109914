import React from 'react';
import { ProfilePicture, Tooltip } from '@getro/rombo';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';

import { ChevronRight, Info } from 'lucide-react';
import { Link } from 'react-router-dom';

export const JobListItem = ({ data, onClick }) => (
  <Flex
    sx={{
      p: '16px',
      mx: '40px',
      cursor: 'pointer',
      border: '1px solid',
      borderColor: 'border.subtle',
      height: 'fit-content',
      flexDirection: 'column',
      gap: '8px',
    }}
    onClick={() => onClick(data)}
  >
    <Flex flex="1" justifyContent="space-between" sx={{ gap: '16px' }}>
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Tooltip
          position="top"
          content={data.job.title}
          trigger={
            <Text
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '300px',
                maxWidth: '300px',
              }}
              fontSize="14px"
              fontWeight="medium"
              color="text.dark"
            >
              {data.job.title}
            </Text>
          }
        />
        <Flex alignItems="center" sx={{ gap: '8px ' }}>
          <Box width="24px" height="24px">
            <ProfilePicture imageUrl={data.organization.logoUrl} name={data.organization.name} variant="square" />
          </Box>
          <Tooltip
            position="top"
            content={data.organization.name}
            trigger={
              <Text
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
                fontSize="12px"
                color="text.subtle"
              >
                {data.organization.name}
              </Text>
            }
          />
        </Flex>
      </Flex>
      <Flex
        sx={{
          color: 'text.main',
          fontWeight: '500',
          lineHeight: '16px',
          fontSize: '14px',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Text fontSize="12px" color="text.subtle">
          Select
        </Text>
        <Box sx={{ color: 'text.subtle' }} size="16px" as={ChevronRight} />
      </Flex>
    </Flex>
    {data?.matches?.length > 0 && (
      <Flex flexDirection="row" alignItems="flex-star" sx={{ gap: '8px' }}>
        <Box as={Info} sx={{ color: 'text.subtle' }} size="16px" />
        <Flex flexDirection="column" sx={{ gap: '8px' }}>
          {data.matches.map((match) => (
            <Text fontSize="14px" color="text.subtle">
              The list{' '}
              <Box
                as={Link}
                to={match.url}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
                rel="noopener noreferrer"
                sx={{ textDecoration: 'underline', color: 'text.subtle' }}
              >
                {match.name} at {data.organization.name}
              </Box>{' '}
              is associated with this job
              {match.matchesCount > 0
                ? `, featuring ${match.matchesCount} ${pluralize('match', match.matchesCount)}`
                : ''}
              .
            </Text>
          ))}
        </Flex>
      </Flex>
    )}
  </Flex>
);

JobListItem.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

JobListItem.defaultProps = {
  onClick: null,
};
