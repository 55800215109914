/* eslint-disable no-console */
import { baseTheme } from '@getro/rombo';
import merge from 'lodash.merge';

const customTheme = {
  colors: {
    red: {
      200: '#f8aab2',
    },
  },
  badge: {
    neutral: {
      variant: 'badge.default',
      fontWeight: 'bold',
      bg: 'neutral.50',
      color: 'neutral.700',
      boxShadow: 'none',
    },
  },
  buttons: {
    default: {
      lineHeight: '22px',
      display: 'inline-flex',
    },
    dropdownItem: {
      variant: 'buttons.secondary',
      borderRadius: 'unset',
      textAlign: 'left',
      border: 0,
      fontWeight: 400,
      ':hover': {
        bg: 'gray.4',
        color: 'text.dark',
      },
      ':disabled': {
        opacity: '.45',
        cursor: 'default',
        pointerEvents: 'none',
        ':hover': {
          bg: 'inherit',
          color: 'inherit',
        },
      },
    },
    cardLink: {
      variant: 'buttons.secondary',
      color: 'text.dark',
      border: 0,
      position: 'static!important',
      p: 0,
      transition: 'unset',
      ':hover': { bg: 'inherit!important' },
      ':focus': {
        outline: 'unset',
      },
      ':active': {
        outline: 'unset',
      },
      ':after': {
        content: "''",
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
    },
    activeUnderline: {
      variant: 'buttons.secondary',
      p: 0,
      py: 2,
      borderRadius: 0,
      border: 'none',
      display: 'block',
      textDecoration: 'none',
      fontSize: 2,
      color: 'text.main',
      borderBottom: '4px solid',
      borderBottomColor: 'transparent',
      fontWeight: 'body',
      px: '0!important',
      ':hover, :focus': {
        color: 'text.dark',
        fontWeight: 'semibold',
        backgroundColor: 'transparent',
      },
      ':active': {
        transform: 'scale(.94)',
      },
      '&.active': {
        color: 'text.dark',
        fontWeight: 'semibold',
        borderBottomColor: '#4000F9',
      },
    },
    sideBarNav: {
      variant: 'buttons.link',
      lineHeight: 1.45,
      fontWeight: 'normal',
      fontSize: 3,
      color: 'text.subtle',
      '&.active': {
        fontWeight: 'semibold',
        color: 'text.dark',
      },
    },
    underlineLink: {
      variant: 'buttons.secondary',
      border: 'none',
      py: 0,
      px: 1,
      mx: 1,
      color: 'link',
      textDecoration: 'underline',
      boxShadow: 'none',
      '&.ladda-button': {
        ':disabled': {
          bg: 'transparent',
        },
      },
      ':hover': {
        bg: 'transparent',
      },
    },
    styleless: {
      position: 'relative',
      borderRadius: 0,
      border: 'none',
      p: 0,
      bg: 'transparent',
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    link: {
      variant: 'buttons.secondary',
      border: 'none',
      color: 'purple.300',
      boxShadow: 'none',
      textDecoration: 'none',
      '&.ladda-button': {
        ':disabled': {
          bg: 'transparent',
        },
      },
    },
    anchorLink: {
      position: 'relative',
      zIndex: 2,
      justifyContent: 'unset',
      textDecoration: 'underline',
      cursor: 'pointer',
      borderRadius: 0,
      border: 'none',
      p: 0,
      bg: 'transparent',
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
    },
  },
  text: {},
  card: {
    link: {
      variant: 'card.default',
      position: 'relative',
      p: [3],
      mb: [3],
      transition: 'box-shadow ease 0.3s',
      ':hover': {
        boxShadow: 'medium',
      },
    },
    section: {
      p: 3,
      borderRadius: 'default',
      bg: 'gray.5',
      color: 'text.main',
    },
    threeUp: {
      variant: 'card.section',
      columnCount: [2, 3],
      columnWidth: ['50%', `${1 / 3}%`],
    },
  },
  breakpoints: ['40em', '62em'],
  variants: {
    listItem: {
      py: 2,
    },
  },
  modal: {
    small: {
      variant: 'modal.default',
    },
    large: {
      variant: 'modal.default',
    },
  },
  modals: {
    largeNoScroll: {
      wrapper: {
        variant: 'modals.large.wrapper',
        overflow: 'visible!important',
      },
      overlay: { variant: 'modals.large.overlay' },
      modal: { variant: 'modals.large.modal' },
      header: { variant: 'modals.large.header' },
      closeButton: { variant: 'modals.large.closeButton' },
      title: { variant: 'modals.large.title' },
      body: {
        variant: 'modals.large.body',
        overflowY: 'visible',
        overflowX: 'visible',
      },
      footer: { variant: 'modals.large.footer' },
    },
  },
  label: {
    mb: 2,
    fontWeight: 'medium',
  },
  navigation: {
    subnav: {
      width: 1,
      borderBottom: 1,
      borderBottomColor: 'gray.3',
      ul: {
        p: 0,
      },
      li: {
        m: 0,
        listStyleType: 'none',
      },
    },
  },
  forms: {
    select: {
      fontSize: 1,
      bg: 'white',
      borderColor: 'neutral.300',
      color: 'neutral.700',
      lineHeight: '22px',
      py: 2,
    },
    search: {
      fontSize: 1,
      py: 2,
      lineHeight: '22px',
      pl: 4,
      borderColor: 'neutral.300',
      backgroundColor: 'white',
      color: 'text.main',
      transition: 'all ease .3s',
      '&::placeholder': {
        color: 'neutral.300',
      },
      '&:focus': {
        outline: 'none !important',
        borderColor: 'neutral.400',
        transition: 'all ease .3s',
      },
      '::-webkit-search-cancel-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  mainMenu: {
    item: {
      textDecoration: 'none',
      color: 'text.main',
      fontWeight: 'medium',
      fontSize: '14px',
      p: '8px',
      lineHeight: '16px',
    },
  },
  contactAvatar: {
    medium: {
      avatarContainer: {
        minWidth: ['64px', '80px'],
      },
      avatarStar: {
        right: '-5px',
        bottom: '-5px',
      },
      highlighted: {
        border: '3px solid',
        borderRadius: '100%',
        borderColor: 'purple.300',
        width: '75px',
        height: '75px',
      },
      container: {
        flexDirection: 'row',
      },
      picture: {
        width: ['64px', '80px'],
        height: ['64px', '80px'],
        border: '0px solid',
        borderColor: 'transparent',
        borderRadius: '100%',
      },
    },
    large: {
      container: {
        flexDirection: 'row',
      },

      avatarContainer: {
        minWidth: ['64px', '104px'],
      },
      avatarStar: {
        right: '4px',
        bottom: '0px',
      },
      picture: {
        width: ['64px', '104px'],
        height: ['64px', '104px'],
      },
      highlighted: {
        width: ['64px', '104px'],
        height: ['64px', '104px'],
      },
    },
  },
  tag: {
    transparent: {
      variant: 'tag.default',
      container: {
        variant: 'tag.accent.container',
        bg: 'transparent',
        border: '1px solid',
        borderRadius: '100px',
        color: 'border.subtle',
        borderColor: 'border.subtle',
      },
      label: {
        lineHeight: '16px',
        fontSize: '13px',
        color: 'text.subtle',
      },
    },
  },
  dataTable: {
    th: {
      ':first-child': {
        pl: '8px',
      },
    },
  },
};

const theme = merge(baseTheme, customTheme);

if (process.env.NODE_ENV === 'development') {
  console.debug('Theme settings\n');
  console.debug(theme);
}

export default theme;
